import { ManualDom } from '@components/manual-dom';
import { generateMediaCard, mediaMiddleware } from '@components/media-card';
import { cardMiddleware, defaultToolbarActions, minidocToolbar, placeholder } from 'minidoc-editor';
import { useEffect, useState } from 'preact/hooks';
import { toolbarDropdown } from '@components/toolbar-dropdown';
import { keyboardEditorToTitle } from 'client/lib/minidoc';
import { useMinidoc } from '@components/minidoc';
import { AutosizeText } from '@components/autosize-text';
import { EditorWrapper } from '@components/minidoc/minidoc-root';
import { useIntl } from 'shared/intl/use-intl';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { useBasicAutosaver } from '@components/autosaver';
import { SelectedDiscussion, State } from './index';
import { router, useRouteParams } from '@components/router';
import { URLS } from 'shared/urls';
import { AsyncForm, FormGroup } from '@components/async-form';
import { rpx } from 'client/lib/rpx-client';
import { genericDiscussionCategoryIds } from 'shared/consts';

const store = rpx.discussions;

interface SaveState {
  id?: UUID;
  prompt?: string;
  description?: string;
}

interface Props {
  initialState?: SaveState;
  initialCategoryId: UUID;
  categories: State['categories'];
  isEdit?: boolean;
  shouldRenderPdfViewer?: boolean;
  onSuccess: (discussion: SelectedDiscussion) => void;
  onCancel?: () => void;
}

export function DiscussionForm({
  initialState,
  categories,
  initialCategoryId,
  shouldRenderPdfViewer,
  isEdit,
  onSuccess,
  onCancel,
}: Props) {
  const { courseId, courseSlug } = useRouteParams();
  const storageKey = `discussion_${courseId}`;

  const intl = useIntl();
  const [state, setState] = useState<SaveState>(() => {
    if (initialState) {
      return initialState;
    }

    const saved = localStorage.getItem(storageKey);
    return saved ? JSON.parse(saved) : { prompt: '', description: '' };
  });

  const editor = useMinidoc({
    doc: state.description || '',
    autoFocus: !!state.prompt,
    middleware: () => [
      placeholder(`Write something...`),
      minidocToolbar([
        ...defaultToolbarActions.filter((x) => x.id !== 'h1' && x.id !== 'h3'),
        toolbarDropdown({
          intl,
          mediaOnly: true,
        }),
      ]),
      cardMiddleware([
        generateMediaCard({
          shouldRenderPdfViewer,
        }),
      ]),
      mediaMiddleware(),
    ],
    onChange(doc) {
      setState((s) => ({ ...s, description: doc }));
    },
  });

  /**
   * Create a new instance of minidoc editor whenever the lesson
   * changes.
   */
  useEffect(() => {
    editor.root.addEventListener('keydown', (e: any) =>
      keyboardEditorToTitle(e, editor, '.js-edit-discussion-title textarea'),
    );
  }, [editor]);

  // Autosave the prompt and description to local storage
  // to prevent unintentional loss of data.
  const autosaver = useBasicAutosaver(state, async (val) => {
    if (!isEdit) {
      localStorage.setItem(storageKey, JSON.stringify(val));
    }
  });

  function focusEditor() {
    (editor.root as any).focus();
  }

  async function submit({ categoryId }: { categoryId: string }) {
    await autosaver.save();
    const discussion = await store.upsertDiscussion({
      id: isEdit ? initialState!.id : undefined,
      courseId,
      categoryId: categoryId === genericDiscussionCategoryIds.nonLessons ? undefined : categoryId,
      prompt: state.prompt || '',
      description: state.description,
    });
    localStorage.removeItem(storageKey);
    onSuccess(discussion);
  }

  return (
    <AsyncForm onSubmit={submit}>
      <div class="flex flex-col md:flex-row md:items-center mt-4 mb-8 gap-4">
        <FormGroup class="w-full" prop="prompt">
          <AutosizeText
            containerClass="js-edit-discussion-title text-2xl font-extrabold font-studentcontent leading-snug w-full"
            focusSelf={!state.prompt}
            maxLength={1024}
            onKeyDown={(e) => {
              if (e.code === 'Enter') {
                e.preventDefault();
                focusEditor();
              } else if (e.code === 'ArrowDown') {
                setTimeout(() => {
                  // This is a hacky approximation of detecting when the
                  // user presses the down arrow on the last line of the
                  // textarea. It's not 100%, but it's better than nothing.
                  const ta = e.target as HTMLTextAreaElement;
                  if (ta.selectionStart === ta.value.length) {
                    focusEditor();
                  }
                });
              }
            }}
            class="border-0 ring-2 ring-transparent focus:ring-2 focus:ring-indigo-600 p-2 hover:ring-indigo-400 rounded dark:text-white"
            placeholder={intl('Type your discussion title here')}
            onInput={(e: any) => setState((s) => ({ ...s, prompt: e.target.value }))}
            value={state.prompt}
          />
        </FormGroup>
        <select
          class="inline-ruz-input"
          name="categoryId"
          // @ts-ignore
          defaultValue={initialCategoryId}
        >
          {categories
            .filter((c) => !c.noNewDiscussions)
            .map((c) => (
              <option key={c.id} value={c.id}>
                {c.title}
              </option>
            ))}
        </select>
      </div>
      <div class="sticky top-0 z-10 bg-white dark:bg-gray-800 border border-gray-100 dark:border-gray-700 text-gray-600 dark:text-gray-200 shadow-md rounded lesson-editor-toolbar flex items-center pl-2">
        <ManualDom el={editor.toolbar.root} />
      </div>
      <EditorWrapper
        editor={editor}
        class="min-h-64 leading-7 mb-8 font-studentcontent text-base w-full py-10 cursor-text text-gray-700"
      />
      <footer class="flex justify-end space-x-4">
        <BtnSecondary
          class="px-8"
          onClick={async () => {
            // Save inputs before navigating away
            await autosaver.save();
            if (onCancel) {
              onCancel();
            } else {
              router.goto(
                URLS.student.discussions({
                  course: {
                    id: courseId,
                    title: courseSlug,
                  },
                  categoryId: initialCategoryId,
                }),
              );
            }
          }}
        >
          {intl('Cancel')}
        </BtnSecondary>
        <BtnPrimary class="px-8">
          {isEdit ? intl('Save Discussion') : intl('Submit Discussion')}
        </BtnPrimary>
      </footer>
    </AsyncForm>
  );
}
