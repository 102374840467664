import { router } from '@components/router';
import { useDocumentTitle } from 'client/utils/use-document-title';
import { useMemo, useState } from 'preact/hooks';
import { toQueryString } from 'shared/urls';
import { LoadedProps } from 'client/lib/loaders';
import { AppRoute } from 'client/lib/app-route/types';
import { rpx } from 'client/lib/rpx-client';
import dayjs from 'dayjs';
import { IcoArrowLeft, IcoArrowRight } from '@components/icons';
import { MeetingSummary } from './meeting-summary';
import { AsyncForm } from '@components/async-form';
import { Field, InputField, eventToState } from './form-helpers';
import { useTryAsyncData } from 'client/lib/hooks';
import { AutosizeText } from '@components/autosize-text';
import { BtnPrimary } from '@components/buttons';
import { ComponentChildren } from 'preact';
import { datesEq, mkdate } from '@components/date-picker';
import { getAvailability } from 'shared/scheduling';
import { Calendar } from './calendar';
import { weekdayFormatter } from './dateutil';
import { TimeSlots } from './time-slots';

type Pane = 'date' | 'confirm';

function parseRouteSchedule(params: Record<string, string>) {
  const { start, end } = params;
  if (!start || !end) {
    return;
  }
  try {
    return {
      start: new Date(start),
      end: new Date(end),
    };
  } catch (err) {
    console.error(err);
  }
}
async function load(route: AppRoute) {
  const { urlPrefix, urlSuffix } = route.params;
  const state = await rpx.ruzcal.getInitialBookingState({
    urlPrefix,
    urlSuffix,
  });
  const schedule = parseRouteSchedule(route.params);

  const attendeeTimeZone =
    route.auth.user?.timezone ||
    Intl.DateTimeFormat().resolvedOptions().timeZone ||
    state.availability.timezone;

  return {
    ...state,
    schedule,
    baseUrl: `/cal/${urlPrefix}/${urlSuffix}`,
    attendeeTimeZone,
    attendeeName: '',
    attendeeEmail: '',
    attendeeNotes: '',
    hour12: route.params.hour12 !== 'false',
  };
}

type Props = LoadedProps<typeof load>;

function paneHref(opts: { pane: Pane; schedule: Props['state']['schedule']; hour12: boolean }) {
  const { pane, schedule, hour12 } = opts;
  return toQueryString(
    {
      start: schedule?.start.toISOString(),
      end: schedule?.end.toISOString(),
      hour12: String(hour12),
      pane: schedule ? pane : 'date',
    },
    '?',
  );
}

function ConfirmBooking(props: { state: Props['state']; setState: Props['setState'] }) {
  const { state } = props;
  const { schedule, eventType, host } = state;

  if (!schedule) {
    return null;
  }

  return (
    <div class="p-2 flex items-center justify-center bg-gray-100 min-h-screen">
      <section class="p-8 bg-white rounded-2xl max-w-full w-3xl an-scale-in flex-col gap-8 grid sm:grid-cols-2">
        <div>
          <a
            class="inline-flex gap-2 items-center mb-4 font-medium"
            href={paneHref({
              pane: 'date',
              schedule,
              hour12: state.hour12,
            })}
          >
            <IcoArrowLeft />
            Back
          </a>
          <MeetingSummary
            host={host}
            name={eventType.name}
            description={eventType.description}
            duration={eventType.duration}
            location={eventType.location}
            locationDetail={eventType.locationDetail}
            timeZone={state.attendeeTimeZone}
            hour12={state.hour12}
            schedule={schedule}
          />
        </div>
        <AsyncForm
          class="flex flex-col gap-4 border-t pt-8 sm:pt-0 sm:border-0 sm:border-l sm:pl-8"
          onSubmit={async () => {
            const result = await rpx.ruzcal.saveEvent({
              end: schedule.end,
              start: schedule.start,
              eventTypeId: eventType.id,
              attendeeName: state.attendeeName,
              attendeeEmail: state.attendeeEmail,
              attendeeNotes: state.attendeeNotes,
            });
            router.goto(`/cal-bookings/${result.id}?token=${result.token}&success=true`);
          }}
        >
          <InputField
            name="attendeeName"
            title="Your name"
            fullWidth
            autoFocus
            value={state.attendeeName}
            onInput={eventToState(props.setState)}
          />
          <InputField
            name="attendeeEmail"
            fullWidth
            title="Email address"
            value={state.attendeeEmail}
            onInput={eventToState(props.setState)}
          />
          <Field name="notes" title="Additional notes">
            <AutosizeText
              name="attendeeNotes"
              class="ruz-input min-h-20 p-2 px-3"
              value={state.attendeeNotes}
              onInput={eventToState(props.setState)}
            />
          </Field>
          <footer class="mt-4">
            <BtnPrimary class="inline-flex text-left gap-2 items-center p-2 px-4 rounded-full text-base">
              Schedule meeting
              <IcoArrowRight class="size-4 shrink-0" />
            </BtnPrimary>
          </footer>
        </AsyncForm>
      </section>
    </div>
  );
}

function BtnHour(props: { onClick(): void; isSelected: boolean; children: ComponentChildren }) {
  return (
    <button
      type="button"
      class={`${
        props.isSelected ? 'bg-gray-200' : ''
      } px-2 rounded hover:bg-gray-100 transition-all`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}

function dateClass(opts: { date: Date; today: Date; isAvailable: boolean; selectedDate?: Date }) {
  const isToday = datesEq(opts.date, opts.today);
  const isActive = datesEq(opts.selectedDate, opts.date);
  const today = isToday && opts.isAvailable ? 'font-bold' : '';
  const active = isActive ? 'bg-indigo-600 text-white' : '';
  const available = opts.isAvailable
    ? 'bg-gray-100 text-black hover:bg-indigo-500 hover:text-white cursor-pointer'
    : 'text-gray-500';
  return `aspect-square flex items-center justify-center transition-all rounded-full ${today} ${
    active || available
  }`;
}

function ScheduleBooking({
  state,
  setState,
}: {
  state: Props['state'];
  setState: Props['setState'];
}) {
  const { eventType, hour12 } = state;
  const [focusedDate, setFocusedDate] = useState(() =>
    mkdate((dt) => dt.setHours(0, 0, 0, 0), state.schedule?.start),
  );
  const focusedMonth = dayjs(focusedDate).format('YYYY-MM-01');
  const selectedDate = state.schedule?.start;
  const bookings = useTryAsyncData(
    () =>
      rpx.ruzcal.getMonthlyBookings({
        month: focusedMonth,
        hostId: eventType.hostId,
      }),
    [focusedMonth],
  );
  const generateAvailability = (date: Date) => {
    return getAvailability({
      availability: state.availability,
      duration: eventType.duration,
      maxPerDay: eventType.maxPerDay || 0,
      minNotice: eventType.minNotice || 0,
      prefixBufferTime: eventType.bufferMinsBefore || 0,
      suffixBufferTime: eventType.bufferMinsAfter || 0,
      attendeeTimeZone: state.attendeeTimeZone,
      date,
      unavailable: bookings.data || [],
    });
  };

  const availability = useMemo(() => {
    const unavailable = bookings.data;
    if (!unavailable || !selectedDate) {
      return [];
    }
    return Array.from(generateAvailability(selectedDate));
  }, [bookings.data, selectedDate]);

  return (
    <div class="p-2 flex items-center justify-center bg-gray-100 min-h-screen an-scale-in">
      <section class="p-8 bg-white rounded-2xl max-w-full w-5xl">
        <div class="grid sm:grid-cols-2 lg:grid-cols-4 sm:gap-6 gap-10">
          <section>
            <MeetingSummary
              host={state.host}
              name={eventType.name}
              description={eventType.description}
              duration={eventType.duration}
              location={eventType.location}
              locationDetail={eventType.locationDetail}
              timeZone={state.attendeeTimeZone}
              hour12={state.hour12}
            />
          </section>
          <section class="lg:col-span-2 border-y sm:border-t-0 py-10 sm:pt-0 lg:p-0 lg:border-0 lg:border-x lg:px-4">
            <Calendar
              onSelect={(dt) => {
                setState((s) => ({ ...s, schedule: { start: dt, end: dt } }));
                document.querySelector('.js-time-header')?.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                });
              }}
              focusedDate={focusedDate}
              setFocusedDate={setFocusedDate}
              isAvailable={(opts) =>
                !bookings.isLoading && !!generateAvailability(opts.date).next().value
              }
              renderDate={(opts) => {
                const className = dateClass({ ...opts, selectedDate });
                return <span class={className}>{opts.date.getDate()}</span>;
              }}
            />
          </section>
          <section
            key={selectedDate?.toDateString()}
            class={`flex flex-col gap-2 an-fade-in-left sm:col-end-3 lg:col-end-auto`}
          >
            <header class="js-time-header flex items-center justify-between gap-4">
              <span class="font-semibold">{weekdayFormatter.format(selectedDate)}</span>
              <span class="flex border gap-1 rounded-md p-1">
                <BtnHour
                  isSelected={!!hour12}
                  onClick={() => setState((s) => ({ ...s, hour12: true }))}
                >
                  12h
                </BtnHour>
                <BtnHour
                  isSelected={!hour12}
                  onClick={() => setState((s) => ({ ...s, hour12: false }))}
                >
                  24h
                </BtnHour>
              </span>
            </header>
            <div class="flex flex-col grow overflow-auto relative ">
              <section class="lg:absolute inset-x-0 top-0">
                {!bookings.isLoading && (
                  <TimeSlots
                    hour12={!!hour12}
                    availability={availability}
                    selected={state.schedule}
                    makeHref={(opts) =>
                      paneHref({ pane: 'confirm', schedule: opts.schedule, hour12 })
                    }
                  />
                )}
              </section>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}

export function Page(props: LoadedProps<typeof load>) {
  const { state, setState } = props;
  const { eventType } = props.state;
  const pane: Pane =
    props.route.params.pane !== 'date' && props.route.params.start ? 'confirm' : 'date';

  useDocumentTitle([eventType.name]);

  if (pane === 'confirm' && state.schedule) {
    // This is a hack to make sure the schedule is set correctly.
    const schedule = parseRouteSchedule(props.route.params);
    state.schedule = schedule;
    return <ConfirmBooking state={state} setState={setState} />;
  }

  return <ScheduleBooking state={state} setState={setState} />;
}

router.add({
  isPublic: true,
  url: 'cal/:urlPrefix/:urlSuffix',
  load,
  render: Page,
});
