import { ComponentChildren, JSX } from 'preact';
import { SalesBlockDefinition } from './types';
import { Dispatch, StateUpdater, useState } from 'preact/hooks';
import { IcoChevronDown, IcoChevronUp, IcoImage, IcoTrash, IcoX } from '@components/icons';
import { PaddingSize, SalesBlockState } from 'server/types';
import { showError } from '@components/app-error';
import { filepicker } from 'client/components/filepicker';
import { BtnPreWarning, BtnSecondary, Button, ButtonProps } from '@components/buttons';
import { scrollToSelectedSection } from './scrollutil';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';
import { autoColor } from 'shared/colors';
import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';

interface CustomColor {
  id: number;
  fgcolor?: string;
  bgcolor?: string;
}

interface Props {
  isFirst: boolean;
  blockState: SalesBlockState<any>;
  definition: SalesBlockDefinition;
  onBlockStateChange: Dispatch<StateUpdater<SalesBlockState<any>>>;
  onDelete(): void;
}

export const defaultBgImg = `${RUZUKU_ASSETS_BASE_URL}/bg-rainbow-gradient.jpg?width=3276`;

export function BtnSettings(props: ButtonProps) {
  return (
    <Button
      {...props}
      class="flex items-center justify-center p-2 px-4 text-center bg-gray-200 text-gray-700 rounded"
    />
  );
}

export function BtnClear(props: ButtonProps) {
  return (
    <Button class="underline text-gray-500" {...props}>
      {props.children || 'Clear'}
    </Button>
  );
}

function ColorPicker({
  fgcolor,
  bgcolor,
  onPick,
  children,
  ...props
}: JSX.LabelHTMLAttributes<HTMLLabelElement> &
  Pick<SalesBlockState, 'fgcolor' | 'bgcolor'> & {
    value?: string;
    onPick(color?: string): void;
  }) {
  return (
    <label {...props}>
      <input
        type="color"
        value={props.value}
        class="absolute top-full left-0 hidden"
        onChange={(e: any) => onPick(e.target.value)}
      />
      <span class="bg-inherit py-2 relative flex items-center justify-center w-full h-full">
        {children || 'Choose'}
      </span>
    </label>
  );
}

export function BtnColor(
  props: JSX.LabelHTMLAttributes<HTMLLabelElement> &
    Pick<SalesBlockState, 'fgcolor' | 'bgcolor'> & {
      value?: string;
      onPick(color?: string): void;
    },
) {
  return (
    <ColorPicker
      {...props}
      class="relative block overflow-hidden text-gray-600 cursor-pointer bg-gray-100 border-opacity-5 border border-black rounded-xl"
      style={{ backgroundColor: props.bgcolor, color: props.fgcolor }}
    />
  );
}

function BtnSkew(props: { skew: boolean; isSelected?: boolean; onClick(): void }) {
  return (
    <Button
      class={`h-16 rounded-xl ring-offset-1 overflow-hidden ${
        props.isSelected ? 'ring-2 ring-blue-600 shadow-lg shadow-gray-400' : ''
      }`}
      onClick={props.onClick}
    >
      <span class={`bg-gray-500 block h-10 w-full ${props.skew ? '-skew-y-6' : ''}`}></span>
      <span>{props.skew ? 'Skewed' : 'None'}</span>
    </Button>
  );
}

function BtnBG(props: {
  img?: string;
  bg?: string;
  fgcolor?: string;
  bgcolor?: string;
  isSelected?: boolean;
  onDelete?(): void;
  onClick(): void;
  children?: ComponentChildren;
  style?: any;
}) {
  return (
    <span
      class="relative flex flex-col"
      style={{
        color: props.fgcolor,
      }}
    >
      <Button
        class={`h-16 rounded-xl bg-cover flex items-center justify-center ring-offset-1 ring-2 shadow-gray-400 ${
          props.bg || (props.img ? '' : 'bg-blue-600 text-white')
        } ${
          props.isSelected
            ? 'ring-blue-600 shadow-lg'
            : 'ring-transparent focus:ring-blue-600 hover:ring-blue-400 hover:shadow-lg'
        }`}
        style={{
          backgroundImage: props.img ? `url('${props.img}')` : '',
          backgroundColor: props.bgcolor,
          color: props.fgcolor,
          ...props.style,
        }}
        onClick={props.onClick}
      >
        {props.children}
      </Button>
      {props.onDelete && (
        <Button
          class={`absolute top-0 right-0 rounded-tr-lg rounded-bl-xl flex flex-col justify-center gap-2 p-2 hover:bg-red-600 hover:text-white`}
          data-tooltip="Remove"
          onClick={props.onDelete}
        >
          <IcoTrash />
        </Button>
      )}
    </span>
  );
}

function BtnBGColor(props: Props & { bgcolor: string }) {
  const fgcolor = autoColor(props.bgcolor);
  const isSelected = props.bgcolor === props.blockState.bgcolor;

  return (
    <BtnBG
      isSelected={isSelected}
      style={{
        backgroundColor: props.bgcolor,
        color: fgcolor,
      }}
      onClick={() => {
        props.onBlockStateChange((s) => ({
          ...s,
          bgcolor: props.bgcolor,
          fgcolor: undefined,
          bgimg: undefined,
        }));
      }}
    >
      Aa
    </BtnBG>
  );
}

function BtnBGImg(props: Props & { bgimg: string; bgcolor?: string }) {
  return (
    <BtnBG
      img={props.bgimg.replace(/width=\d+/, 'width=256')}
      bgcolor={props.bgcolor}
      isSelected={props.blockState.bgimg === props.bgimg}
      onClick={() => {
        props.onBlockStateChange((s) => ({
          ...s,
          bgcolor: props.bgcolor || '#FFF',
          bgimg: props.bgimg,
        }));
      }}
    />
  );
}

function ModalPane(props: {
  title: ComponentChildren;
  children: ComponentChildren;
  defaultClosed?: boolean;
}) {
  return (
    <div class="flex flex-col gap-4 pb-6">
      <h3 class="text-gray-700 font-medium">{props.title}</h3>
      <div>{props.children}</div>
    </div>
  );
}

function BackgroundColorPane(props: Props) {
  const [customColors, setCustomColors] = useState<CustomColor[]>(() => {
    try {
      return JSON.parse(localStorage.customColors || '[]');
    } catch {
      return [];
    }
  });

  useDidUpdateEffect(() => {
    localStorage.customColors = JSON.stringify(customColors);
  }, [customColors]);

  return (
    <>
      <div class="bg-gray-50 rounded-lg p-4 mb-6 shadow">
        <h3 class="font-semibold mb-2">Customize</h3>
        <CustomColorPane
          {...props}
          addToPalette={(color) =>
            setCustomColors((s) => {
              const id = s.reduce((acc, x) => Math.max(acc, x.id), 0) + 1;
              return [{ ...color, id }, ...s];
            })
          }
        />
      </div>
      <div class="grid grid-cols-2 gap-6">
        {customColors.map((c) => (
          <BtnBG
            key={c.id}
            bgcolor={c.bgcolor}
            fgcolor={c.fgcolor}
            isSelected={
              c.bgcolor === props.blockState.bgcolor && c.fgcolor === props.blockState.fgcolor
            }
            onClick={() =>
              props.onBlockStateChange((s) => ({
                ...s,
                fgcolor: c.fgcolor,
                bgcolor: c.bgcolor,
                bgimg: undefined,
              }))
            }
            onDelete={() => setCustomColors((s) => s.filter((x) => x.id !== c.id))}
          >
            Aa
          </BtnBG>
        ))}
        <BtnBGColor bgcolor="#f8fafc" {...props} />
        <BtnBGColor bgcolor="#1E293B" {...props} />
        <BtnBGColor bgcolor="#16A34A" {...props} />
        <BtnBGColor bgcolor="#0284C7" {...props} />
        <BtnBGColor bgcolor="#2563EB" {...props} />
        <BtnBGColor bgcolor="#5F46E5" {...props} />
        <BtnBGColor bgcolor="#6D28D9" {...props} />
        <BtnBGColor bgcolor="#F43F5E" {...props} />
        <BtnBGColor bgcolor="#D97706" {...props} />
        <BtnBGColor bgcolor="#84CC16" {...props} />
      </div>
    </>
  );
}

function CustomColorPane(props: Props & { addToPalette(color: Omit<CustomColor, 'id'>): void }) {
  const { blockState } = props;
  const fgcolor = blockState.fgcolor || autoColor(blockState.bgcolor);

  return (
    <div class="flex flex-col text-sm gap-2">
      <span class="flex justify-between gap-2">
        <ColorPicker
          class="flex items-center cursor-pointer overflow-hidden relative text-ellipsis whitespace-nowrap"
          onPick={(color) => props.onBlockStateChange((s) => ({ ...s, bgcolor: color }))}
        >
          <span
            class="w-6 h-6 shadow rounded-full mr-2"
            style={{ background: blockState.bgcolor }}
          ></span>
          Background ...
        </ColorPicker>
        {blockState.bgcolor && (
          <Button
            class="flex items-center gap-2"
            onClick={() => {
              props.onBlockStateChange((s) => ({
                ...s,
                bgcolor: undefined,
              }));
            }}
          >
            <IcoX />
            <span>Clear</span>
          </Button>
        )}
      </span>

      <span class="flex justify-between">
        <ColorPicker
          class="flex items-center cursor-pointer overflow-hidden relative"
          onPick={(color) => props.onBlockStateChange((s) => ({ ...s, fgcolor: color }))}
        >
          <span class="w-6 h-6 shadow rounded-full mr-2" style={{ background: fgcolor }}></span>
          Text ...
        </ColorPicker>
        {blockState.fgcolor && (
          <Button
            class="flex items-center gap-2"
            onClick={() => {
              props.onBlockStateChange((s) => ({
                ...s,
                fgcolor: undefined,
              }));
            }}
          >
            <IcoX />
            <span>Clear</span>
          </Button>
        )}
      </span>
      <footer class="mt-2 flex flex-col">
        <BtnSecondary onClick={() => props.addToPalette({ fgcolor, bgcolor: blockState.bgcolor })}>
          Add to Palette
        </BtnSecondary>
      </footer>
    </div>
  );
}

function BackgroundImgPane(props: Props) {
  const { blockState } = props;

  async function pickPhoto() {
    try {
      const result = await filepicker({
        accept: 'image/*',
        sources: ['filepicker', 'takephoto'],
      });

      if (result) {
        props.onBlockStateChange((s) => ({
          ...s,
          bgimg: result.publicUrl,
        }));
      }
    } catch (err) {
      showError(err);
    }
  }

  return (
    <div class="grid grid-cols-2 gap-6">
      {blockState.bgimg && (
        <BtnBG
          bg="bg-gray-50 text-gray-500"
          onClick={() => {
            props.onBlockStateChange((s) => ({
              ...s,
              bgimg: undefined,
            }));
          }}
        >
          <IcoX />
          <span class="ml-2">None</span>
        </BtnBG>
      )}
      <BtnBGImg {...props} bgimg={defaultBgImg} />
      <BtnBGImg {...props} bgimg={`${RUZUKU_ASSETS_BASE_URL}/bg-paint.jpg?width=3276`} />
      <BtnBGImg
        {...props}
        bgcolor="#6a00ff"
        bgimg={`${RUZUKU_ASSETS_BASE_URL}/bg-purple-stripes.jpg?width=3276`}
      />
      <BtnBGImg
        {...props}
        bgcolor="#A16207"
        bgimg={`${RUZUKU_ASSETS_BASE_URL}/bg-orange-stripes.jpg?width=3276`}
      />
      <BtnBGImg
        {...props}
        bgcolor="#3B82F6"
        bgimg={`${RUZUKU_ASSETS_BASE_URL}/bg-blue-to-pink.jpg?width=3276`}
      />
      <BtnBG onClick={pickPhoto} bg="bg-gray-100 text-gray-600">
        <IcoImage /> <span class="ml-2">...</span>
      </BtnBG>
    </div>
  );
}

function SkewPane(props: Props) {
  async function setSkew(skewed: boolean) {
    props.onBlockStateChange((s) => ({
      ...s,
      skewed,
    }));
  }

  return (
    <div class="grid grid-cols-2 gap-6">
      <BtnSkew skew={false} onClick={() => setSkew(false)} isSelected={!props.blockState.skewed} />
      <BtnSkew skew onClick={() => setSkew(true)} isSelected={!!props.blockState.skewed} />
    </div>
  );
}

function BtnPadding(props: {
  name: string;
  value: PaddingSize;
  currentValue: PaddingSize;
  setValue(size: PaddingSize): void;
  children: ComponentChildren;
}) {
  return (
    <label
      class={`p-1 rounded border text-center text-xs cursor-pointer ${
        props.value === props.currentValue
          ? 'text-white bg-indigo-600 border-indigo-600 hover:bg-indigo-700'
          : 'hover:bg-gray-100'
      }`}
      onClick={() => props.setValue(props.value)}
    >
      <input name={props.name} value={props.value} type="radio" class="sr-only" />
      {props.children}
    </label>
  );
}

function PaddingPicker(props: {
  title: string;
  name: string;
  value: PaddingSize;
  setValue(p: PaddingSize): void;
}) {
  return (
    <div>
      <h3 class="font-medium text-sm mb-2">{props.title}</h3>
      <nav class="grid grid-cols-4 gap-2">
        <BtnPadding
          name={props.name}
          value="sm"
          currentValue={props.value}
          setValue={props.setValue}
        >
          SM
        </BtnPadding>
        <BtnPadding
          name={props.name}
          value="md"
          currentValue={props.value}
          setValue={props.setValue}
        >
          MD
        </BtnPadding>
        <BtnPadding
          name={props.name}
          value="lg"
          currentValue={props.value}
          setValue={props.setValue}
        >
          LG
        </BtnPadding>
        <BtnPadding
          name={props.name}
          value="xl"
          currentValue={props.value}
          setValue={props.setValue}
        >
          XL
        </BtnPadding>
      </nav>
    </div>
  );
}

function PaddingPane(props: Props) {
  return (
    <div class="space-y-4">
      <PaddingPicker
        title="Top"
        name="paddingt"
        value={props.blockState.paddingt || 'lg'}
        setValue={(paddingt) =>
          props.onBlockStateChange((s) => ({
            ...s,
            paddingt,
          }))
        }
      />

      <PaddingPicker
        title="Bottom"
        name="paddingb"
        value={props.blockState.paddingb || 'lg'}
        setValue={(paddingb) =>
          props.onBlockStateChange((s) => ({
            ...s,
            paddingb,
          }))
        }
      />
    </div>
  );
}

function Details(props: {
  title: ComponentChildren;
  children: ComponentChildren;
  defaultOpen?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(!!props.defaultOpen);
  return (
    <details
      class=" border-b border-gray-100"
      open={isOpen}
      onToggle={(e: any) => setIsOpen(e.target.open)}
    >
      <summary class="flex justify-between items-center py-3 cursor-pointer">
        <span class="font-semibold">{props.title}</span>
        {isOpen ? <IcoChevronUp /> : <IcoChevronDown />}
      </summary>
      <div class="py-2">{props.children}</div>
    </details>
  );
}

/**
 * The settings panel for the currently selected / active sales page block.
 */
export function BlockSettings(props: Props) {
  return (
    <div
      class="flex flex-col p-2 pb-28"
      onClick={(e) => e.stopPropagation()}
      onMouseEnter={() => scrollToSelectedSection()}
    >
      <header class="py-4 font-semibold uppercase text-gray-500 text-xs">Section Settings</header>
      <Details title="Layout">
        <ModalPane title="Spacing">
          <PaddingPane {...props} />
        </ModalPane>
        {!props.isFirst && (
          <ModalPane title="Skew">
            <SkewPane {...props} />
          </ModalPane>
        )}
      </Details>

      <Details title="Background Image" defaultOpen={!!props.blockState.bgimg}>
        <ModalPane title="">
          <BackgroundImgPane {...props} />
        </ModalPane>
      </Details>

      <Details title="Background Color" defaultOpen={!!props.blockState.bgcolor}>
        <BackgroundColorPane {...props} />
      </Details>
      <Details title="Actions" defaultOpen>
        <BtnPreWarning onClick={props.onDelete} class="flex gap-2 w-full">
          <IcoTrash />
          <span>Delete Section</span>
        </BtnPreWarning>
      </Details>
    </div>
  );
}
