import { useMemo } from 'preact/hooks';
import { Combobox } from './combobox';
import { h } from 'preact';

function parseTime(s: string, hour12: boolean) {
  const value = s.toLowerCase();
  const ampm = value.includes('a') ? 'am' : 'pm';
  const [h, m] = value.split(/[^0-9]/);
  const hh = parseInt(h, 10) || new Date().getHours();
  const mm = `${parseInt(m, 10) || 0}`.padEnd(2, '0');
  return `${hh}:${mm}${hour12 ? ampm : ''}`;
}

export function TimeCombobox({
  hour12,
  ...props
}: Omit<h.JSX.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  value: string;
  onChange(value: string): void;
  hour12: boolean;
}) {
  const times = useMemo(() => {
    return new Array(24).fill(0).flatMap((_, i) => {
      const hour = hour12 ? i % 12 || 12 : i;
      const ampm = hour12 ? (i >= 12 ? 'pm' : 'am') : '';
      return [`${hour}:00${ampm}`, `${hour}:30${ampm}`];
    });
  }, []);
  return (
    <Combobox
      values={times}
      {...props}
      class={`inline-ruz-input w-24 p-1.5 px-3 ${props.class}`}
      onBlur={(e: any) => {
        props.onChange(parseTime(e.target.value, hour12));
      }}
    />
  );
}
