import { AsyncForm, FormGroup } from '@components/async-form';
import { BtnPrimary } from '@components/buttons';
import { IcoArrowRight, IcoBan, IcoCheck } from '@components/icons';
import { ReadonlyMinidoc } from '@components/minidoc/readonly-minidoc';
import { useRouteParams } from '@components/router';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { JSX } from 'preact';
import { useState, useRef } from 'preact/hooks';
import { AssignmentAnswerRow, AssignmentSubmissionStatus } from 'server/types';
import { showError } from '@components/app-error';
import { showToast } from '@components/toaster';
import { validationError } from 'shared/utils';
import { SlideOver } from '@components/slide-over';
import { cardMiddleware, defaultToolbarActions, minidocToolbar, placeholder } from 'minidoc-editor';
import { useMinidoc } from '@components/minidoc';
import { mediaCard, mediaMiddleware } from '@components/media-card';
import { toolbarDropdown } from '@components/toolbar-dropdown';
import { useIntl } from 'shared/intl/use-intl';
import { ManualDom } from '@components/manual-dom';
import { EditorWrapper } from '@components/minidoc/minidoc-root';
import { useLocalStorageState, useTryAsyncData } from 'client/lib/hooks';
import { DefaultSpinner } from '@components/spinner';
import { UserProfile } from '../guide-course-students/user-profile';
import { showDialog } from '@components/dialog';
import { Note } from '@components/note';

type Feedback = Pick<AssignmentAnswerRow, 'status' | 'feedback'>;

const store = rpx.assessments;

type AssignmentResult = RpxResponse<typeof store.getAssignmentResults>;

interface Props {
  close(): void;
  onSubmit(opts: {
    status: AssignmentSubmissionStatus;
    lessonId: string;
    userId: string;
  }): Promise<unknown>;
}

const makeLocalStorageKey = (props: { lesson: { id: UUID }; user: { id: UUID } }) =>
  `feedback-${props.lesson.id}-${props.user.id}`;

export function AssignmentStatusPill({
  status,
}: {
  status: AssignmentSubmissionStatus | 'not-submitted';
}) {
  return (
    <span
      class={`border capitalize px-1 rounded text-sm ${
        status === 'rejected'
          ? 'border-red-200 bg-red-50 text-red-600'
          : status === 'approved'
          ? 'border-green-200 bg-green-50 text-green-600'
          : 'bg-gray-50'
      }`}
    >
      {status}
    </span>
  );
}

export function AssignmentSlideover(props: Props) {
  const { user, assignment } = useRouteParams();
  const unsaved = useRef(false);
  const { isLoading, data } = useTryAsyncData(
    () =>
      rpx.assessments.getAssignmentResults({
        lessonId: assignment,
        userId: user,
      }),
    [user, assignment],
  );

  return (
    <SlideOver
      close={async () => {
        if (!unsaved.current) {
          return props.close();
        }
        const shouldClose = await showDialog({
          mode: 'info',
          title: 'Close without sending your feedback?',
          children: `Your feedback has not been sent. We've saved it as a draft, and you can come back later to pick up where you left off.`,
          confirmButtonText: `Save as draft`,
          cancelButtonText: `Continue editing`,
        });
        if (shouldClose) {
          props.close();
        }
      }}
    >
      {isLoading && <DefaultSpinner />}
      {!isLoading && data?.user && data.results.length > 0 && (
        <AssignmentResults
          questions={data.results}
          lesson={data.lesson}
          user={data.user}
          onSubmit={(result) =>
            props.onSubmit(result).then(() => {
              unsaved.current = false;
              localStorage.removeItem(makeLocalStorageKey(data));
            })
          }
          onChange={() => (unsaved.current = true)}
        />
      )}
      {!isLoading && !data && <p>No poll results found.</p>}
    </SlideOver>
  );
}

function AssignmentResults(props: {
  user: AssignmentResult['user'];
  questions: AssignmentResult['results'];
  lesson: AssignmentResult['lesson'];
  onSubmit: Props['onSubmit'];
  onChange(): void;
}) {
  const { courseId } = useRouteParams();
  const [feedback, setFeedback] = useLocalStorageState(makeLocalStorageKey(props), () =>
    props.questions.reduce<Record<string, Feedback>>((acc, x) => {
      acc[x.questionId] = { feedback: x.feedback || '', status: x.status || 'pending' };
      return acc;
    }, {}),
  );
  const [status, setStatus] = useState(() => {
    return props.questions.reduce((acc, q) => {
      if (
        acc === 'pending' ||
        q.status === 'pending' ||
        q.status !== feedback[q.questionId].status ||
        q.feedback !== feedback[q.questionId].feedback
      ) {
        return 'pending';
      } else if (acc === 'rejected' || q.status === 'rejected') {
        return 'rejected';
      } else {
        return 'approved';
      }
    }, 'approved' as AssignmentSubmissionStatus);
  });
  const originalFeedback = useRef(feedback);
  const canSubmit = status === 'pending' || feedback !== originalFeedback.current;

  async function onSubmit() {
    const missing = props.questions.filter((x) => feedback[x.questionId].status === 'pending');
    if (missing && missing.length > 0) {
      const errors = missing.map((m) => ({
        field: m.questionId,
        message: 'You need to review this question',
      }));
      throw validationError(...errors);
    }

    const payload = props.questions.map((x) => ({
      questionId: x.questionId,
      feedback: feedback[x.questionId].feedback,
      status: feedback[x.questionId].status,
    }));

    try {
      const newStatus = await store.saveAssignmentReviews({
        courseId,
        lessonId: props.lesson.id,
        userId: props.user.id,
        reviews: payload,
      });
      await props.onSubmit({
        lessonId: props.lesson.id,
        userId: props.user.id,
        status: newStatus,
      });
      showToast({
        type: 'ok',
        title: 'Reviews saved',
        message: 'Your reviews have been saved.',
      });
    } catch (err) {
      showError(err);
    }
  }

  return (
    <section class="flex flex-col gap-6">
      <header class="flex items-center gap-2">
        <UserProfile user={props.user} /> <IcoArrowRight /> <strong>Assignment Results</strong>
      </header>

      <header>
        <span class="opacity-75">{props.lesson.moduleTitle}</span>
        <h3 class="text-lg font-semibold">{props.lesson.title}</h3>
        <AssignmentStatusPill status={status} />
      </header>

      {!canSubmit && (
        <Note>
          You {status === 'approved' ? 'approved' : 'rejected'} this assignment. You can change your
          feedback and re-submit it at any time.
        </Note>
      )}
      <AsyncForm onSubmit={onSubmit}>
        {props.questions.map((question, index) => (
          <SubmissionAnswer
            key={question.questionId}
            answer={question}
            feedback={feedback[question.questionId]}
            studentId={props.user.id}
            index={index}
            onFeedbackChange={(newFeedback) => {
              props.onChange();
              setFeedback((s) => ({
                ...s,
                [question.questionId]: { ...s[question.questionId], ...newFeedback },
              }));
              if (newFeedback.status === 'pending') {
                setStatus('pending');
              }
            }}
          />
        ))}
        {canSubmit && (
          <footer class="flex flex-col sticky bottom-0 bg-white p-2 pt-6 border-t">
            <BtnPrimary>Save & Send feedback</BtnPrimary>
          </footer>
        )}
      </AsyncForm>
    </section>
  );
}

function SubmissionAnswer({
  answer,
  feedback,
  studentId,
  index,
  onFeedbackChange,
}: {
  answer: AssignmentResult['results'][0];
  feedback: Feedback;
  studentId: UUID;
  index: number;
  onFeedbackChange: (feedback: Partial<Feedback>) => void;
}) {
  const intl = useIntl();
  const editor = useMinidoc({
    doc: feedback.feedback || '',
    middleware: () => [
      placeholder('Type your feedback here...'),
      minidocToolbar([
        ...defaultToolbarActions.filter((a) => a.id !== 'h1'),
        toolbarDropdown({
          id: answer.questionId,
          mediaOnly: true,
          intl,
        }),
      ]),
      cardMiddleware([mediaCard]),
      mediaMiddleware({
        isDownloadable: (fileType) => fileType === 'application/pdf',
      }),
    ],
    onChange: (doc) => {
      onFeedbackChange({ feedback: doc });
    },
  });

  if (!editor.root.classList.contains('pb-4')) {
    editor.root.classList.add('pr-2', 'pb-4');
  }

  return (
    <div class="mb-12">
      <p class="text-lg whitespace-pre-line mb-4">
        {index + 1}. {answer.questionContent || 'Question'}
      </p>
      <div class="bg-white rounded-lg border">
        <ReadonlyMinidoc
          class="py-4 px-6"
          content={answer.content}
          id={`${studentId}_${answer.questionId}`}
        />
        <div class="flex flex-col px-4 gap-4 py-6 text-sm border-t bg-gray-50 rounded-b-lg">
          <div class="border gap-6 bg-white rounded-md">
            <header class="flex items-start">
              <ManualDom el={editor.toolbar.root} />
            </header>
            <EditorWrapper class="prose dark:prose-invert pl-4" editor={editor} />
          </div>

          <FormGroup prop={answer.questionId} class="flex flex-col gap-6">
            <div class="flex">
              <ReviewOption
                name={`${answer.questionId}.status`}
                checked={feedback.status === 'rejected'}
                type="rejected"
                onStatusChange={(newStatus) => onFeedbackChange({ status: newStatus })}
              />
              <ReviewOption
                name={`${answer.questionId}.status`}
                checked={feedback.status === 'approved'}
                type="approved"
                onStatusChange={(newStatus) => onFeedbackChange({ status: newStatus })}
              />
            </div>
          </FormGroup>
        </div>
      </div>
    </div>
  );
}

function ReviewOption(
  props: JSX.InputHTMLAttributes<HTMLInputElement> & {
    checked: boolean;
    type: 'approved' | 'rejected';
    onStatusChange: (newStatus: AssignmentSubmissionStatus) => void;
  },
) {
  const { checked, type, onStatusChange, ...inputProps } = props;
  const isRejectButton = type === 'rejected';
  const bgColor = isRejectButton ? 'bg-red-600' : 'bg-green-600';
  const textColor = isRejectButton ? 'text-red-600' : 'text-green-600';
  const Icon = isRejectButton ? IcoBan : IcoCheck;

  return (
    <label
      class={`inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 ${
        checked ? `${bgColor} text-white` : 'text-gray-700 opacity-75'
      }
      ${props.disabled ? 'opacity-75 cursor-not-allowed' : 'hover:opacity-100 cursor-pointer'}
      ${isRejectButton ? 'rounded-l-md' : 'rounded-r-md'}`}
    >
      <input
        type="radio"
        checked={checked}
        value={type}
        class="sr-only"
        aria-labelledby="color-choice-0-label"
        {...inputProps}
        onChange={() => onStatusChange(type)}
      />
      <Icon class={`w-4 h-4 mr-2 ${checked ? 'text-white' : textColor}`} />
      {isRejectButton ? 'Reject' : 'Approve'}
    </label>
  );
}
