import { router } from '@components/router';
import { RuzcalMgmtPage } from './mgmt-page';
import dayjs from 'dayjs';
import { useEffect } from 'preact/hooks';
import { mkdate } from '@components/date-picker';
import { IcoCalendar, IcoMapPin, IcoVideoCamera } from '@components/icons';
import { ListContainer, PageContent, PageHeading, PageSection } from './common';
import { rpx } from 'client/lib/rpx-client';
import { LoadedProps } from 'client/lib/loaders';
import type { EventLocation } from 'server/types/cal-overrides';
import { isNowish } from './dateutil';

type Booking = {
  id: string;
  title: string;
  start: Date;
  end: Date;
  duration: number;
  location: EventLocation;
  attendee: {
    email: string;
    name: string;
    notes: string;
  };
};

async function load() {
  const [{ events }] = await Promise.all([rpx.ruzcal.getMyEvents(), rpx.ruzcal.ensureHost()]);
  const dailyBookings: Array<{ bookings: Booking[] }> = [];
  let prevDate = mkdate((dt) => dt.setHours(dt.getHours() - 48));
  for (const event of events) {
    const booking: Booking = {
      id: event.id,
      start: new Date(event.start),
      end: new Date(event.end),
      duration: event.duration,
      title: event.name,
      attendee: event.attendees[0],
      location: event.location,
    };
    if (prevDate.toDateString() !== booking.start.toDateString()) {
      dailyBookings.push({ bookings: [] });
    }
    prevDate = booking.start;
    dailyBookings[dailyBookings.length - 1].bookings.push(booking);
  }

  return { dailyBookings };
}

type Props = LoadedProps<typeof load>;

function EditLink(props: Booking & { isNow: boolean; isNext: boolean; isPast: boolean }) {
  return (
    <a
      href={`/cal-bookings/${props.id}`}
      class={`relative flex justify-between gap-4 items-start transition-all p-3 rounded-md font-medium ${
        props.isNow
          ? 'bg-green-50 ring-2 ring-green-400 text-green-700 hover:bg-green-100 hover:text-green-800'
          : props.isNext
          ? 'bg-sky-50 text-sky-700 hover:bg-sky-100 hover:text-sky-800 mt-4 ring-1 ring-sky-200'
          : props.isPast
          ? 'opacity-75 text-inherit hover:bg-gray-100 hover:opacity-100'
          : 'text-inherit hover:bg-gray-100'
      }`}
    >
      <span class="flex flex-col sm:flex-row sm:gap-4 items-start">
        <span class="flex items-center gap-2">
          <span class="flex justify-between items-center w-32">
            <span class="font-semibold">{dayjs(props.start).format('h:mma')}</span>
            {' - '}
            <span class="opacity-75">{dayjs(props.end).format('h:mma')}</span>
          </span>
        </span>
        <span>
          <span class="opacity-75">{props.title} with</span> <span>{props.attendee.name}</span>
        </span>
      </span>
      <span class="flex flex-col sm:flex-row items-center sm:gap-4 text-xs">
        {props.isNow && (
          <span class="bg-green-500 text-white text-xs font-semibold px-1.5 rounded rounded-bl-none absolute -top-3 left-0 ring-2 ring-green-500 flex items-center gap-2">
            <span class="relative size-2 bg-green-100 rounded-full inline-flex">
              <span class="absolute inset-0 bg-green-100 rounded-full inline-flex animate-ping"></span>
            </span>
            <span class="relative">Now</span>
          </span>
        )}
        {props.isNext && (
          <span class="bg-sky-500 text-white text-xs font-semibold px-1.5 rounded rounded-bl-none absolute -top-3 left-0 ring-2 ring-sky-500 flex items-center gap-2">
            <span class="relative">Up next</span>
          </span>
        )}
        <span class="md:flex items-center gap-2 hidden">{props.duration} mins</span>
        <span class="flex items-center gap-1 capitalize">
          {props.location === 'external' ? <IcoMapPin /> : <IcoVideoCamera />}
          {props.location}
        </span>
      </span>
    </a>
  );
}

function BookingList(props: { prevDate: Date; bookings: Booking[] }) {
  const now = new Date();
  let prevDate = props.prevDate;
  return (
    <div class="flex flex-col gap-4">
      {props.bookings.map((b) => {
        const isNow = isNowish(b);
        const isPast = !isNow && b.start < now;
        const isNext = !isNow && prevDate < now && b.start > now;
        prevDate = b.start;
        return <EditLink key={b.start} {...b} isNext={isNext} isNow={isNow} isPast={isPast} />;
      })}
    </div>
  );
}

function Page({ state, setState }: Props) {
  useEffect(() => {
    /**
     * Redraw every 60 seconds to shift the live view, etc.
     */
    const interval = 60000;
    let timeout = setTimeout(function tick() {
      setState((s) => s);
      timeout = setTimeout(tick, interval);
    }, interval);
    return () => clearTimeout(timeout);
  }, []);

  const now = new Date();
  const today = mkdate((dt) => dt.setHours(0, 0, 0, 0), now);
  const tomorrow = dayjs(today).add(1, 'day').toDate();
  let prevDate = mkdate((dt) => dt.setHours(dt.getHours() - 48));
  const thisYear = today.getFullYear();

  return (
    <RuzcalMgmtPage title="Bookings" currentPage="bookings">
      <PageContent>
        <PageSection>
          <PageHeading
            title="Bookings"
            subtitle="Manage your upcoming bookings and view booking history."
          />
          <nav class="flex gap-4 font-medium mb-4">
            <button
              type="button"
              class="bg-gray-200 text-black p-1 px-2 hover:shadow transition-all rounded-full"
            >
              Current & upcoming
            </button>
            <button
              type="button"
              class="p-1 px-2 rounded-full hover:bg-gray-200 hover:shadow transition-all"
            >
              Booking history
            </button>
          </nav>
          {state.dailyBookings.length === 0 && (
            <div class="flex items-center gap-4 text-gray-500 bg-white rounded-2xl p-4 shadow">
              <IcoCalendar class="size-8 shrink-0" />
              <p>No bookings found</p>
            </div>
          )}
          {state.dailyBookings.length > 0 && (
            <ListContainer>
              {state.dailyBookings.map((b, i) => {
                const date = b.bookings[0].start;
                const startDay = date.toDateString();
                const isToday = today.toDateString() === startDay;
                const isTomorrow = tomorrow.toDateString() === startDay;
                const prev = prevDate;
                prevDate = date;

                return (
                  <div key={date}>
                    <h2
                      class={`font-semibold text-sky-700 p-2 px-3 uppercase text-xs tracking-wide ${
                        i === 0 ? '' : 'pt-8 border-t-2'
                      }`}
                    >
                      {isToday && 'Today, '}
                      {isTomorrow && 'Tomorrow, '}
                      {dayjs(date).format(
                        `dddd, MMMM D${date.getFullYear() !== thisYear ? ', YYYY' : ''}`,
                      )}
                    </h2>
                    <BookingList bookings={b.bookings} prevDate={prev} />
                  </div>
                );
              })}
            </ListContainer>
          )}
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}

router.add({
  url: 'ruzcal-mgmt',
  authLevel: 'superadmin',
  load,
  render: Page,
});

router.add({
  url: 'ruzcal-mgmt/bookings',
  authLevel: 'superadmin',
  load,
  render: Page,
});
