/**
 * Manage courses view
 */

import { FixedPage, FixedContent } from '@components/fixed-page';
import { router, RouteProps } from '@components/router';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { CreateBundleModal } from './create-course-modal';
import { TopBar } from '@components/top-bar';
import { IcoPlus } from '@components/icons';
import { useFlashError } from 'client/lib/flash-errors';
import { onCourseDeleted } from '@components/course-action-modals';
import { BtnPrimary, BtnSecondary } from '@components/buttons';
import { Data, load, CourseType } from './types';
import { EmptyScreen } from './empty-screen';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { FreeTierBanner } from '@components/guide-course-page/free-tier-banner';
import { CourseList } from './course-list';
import CourseItem from './course-item';
import { useIntl } from 'shared/intl/use-intl';
import { InvalidPaypalBanner } from '@components/guide-course-page/invalid-paypal-banner';
import { ProTierBanner } from '@components/guide-course-page/pro-tier-banner';
import { DownloadCsvButton } from '@components/download-csv-modal';
import { MyCoursesPageWrapper } from './my-courses-page-wrapper';
import { CrossTenantCoursesBanner } from './cross-tenant-courses-banner';
import { CreateCourseModal } from '@components/create-course-modal';

function TenantMigrationNotice() {
  const [isVisible, setIsVisible] = useState(true);
  return (
    <div
      class={`${
        isVisible ? 'block' : 'hidden'
      } shadow-xl rounded-2xl border p-12 max-w-96 mx-auto my-20 relative space-y-4`}
    >
      <h2 class="text-2xl">Hold tight!</h2>
      <p>
        We're moving your courses to the newest version of our hosting platform. You'll see them
        here soon.
      </p>
      <footer class="mt-4" onClick={() => setIsVisible(false)}>
        <BtnSecondary>Dismiss</BtnSecondary>
      </footer>
    </div>
  );
}

function Page(props: RouteProps<Data>) {
  const intl = useIntl();
  const tenant = useCurrentTenant();
  const { courses } = props.state;
  const me = useCurrentUser()!;
  const courseType: CourseType = (props.route.params.show as CourseType) || 'courses';
  const [showNewBundleModal, setShowNewBundleModal] = useState(false);
  const [showNewCourseModal, setShowNewCourseModal] = useState(!!props.route.params.newRibbon);
  const isStudentOnly = me.level === 'student';
  const hideSubnav = useMemo(() => isStudentOnly && !courses.some((x) => x.isBundle), []);

  useEffect(() => {
    return onCourseDeleted(({ courseId }) => {
      props.setState((s) => ({
        ...s,
        courses: s.courses.filter((c) => c.id !== courseId),
      }));
    });
  });

  // Add hook to check for flashed error messages, display error modal if any exist
  useFlashError();

  const crossTenantBanner =
    props.state.crossTenant.length > 0 ? (
      <CrossTenantCoursesBanner tenants={props.data.crossTenant} />
    ) : null;

  return (
    <FixedPage title={intl('My Courses')}>
      <FixedContent class="bg-white">
        <TopBar />
        <ProTierBanner />
        <InvalidPaypalBanner />
        {!isStudentOnly && me.isRestricted && <FreeTierBanner class="mb-8" />}
        {!isStudentOnly && (
          <CreateCourseModal
            isOpen={showNewCourseModal}
            hide={() => setShowNewCourseModal(false)}
          />
        )}
        {tenant.isMigrating && <TenantMigrationNotice />}
        {!courses.length && <section class="w-content mx-auto">{crossTenantBanner}</section>}
        {courses.length === 0 && (
          <EmptyScreen
            title={courseType === 'bundles' ? intl('No Bundles') : intl('No Courses')}
            subtext={
              isStudentOnly
                ? intl('When you register for a course, it will show up here.')
                : intl(`When you create courses, they'll show up here.`)
            }
            onNewCourse={isStudentOnly ? undefined : () => setShowNewCourseModal(true)}
          />
        )}
        {courses.length > 0 && (
          <MyCoursesPageWrapper
            tab={courseType === 'bundles' ? 'bundles' : 'courses'}
            hideSubnav={hideSubnav}
          >
            {crossTenantBanner}
            <CourseList
              primaryActions={
                <div class="flex items-center gap-4">
                  {!isStudentOnly && (
                    <BtnPrimary
                      onClick={() =>
                        courseType === 'bundles'
                          ? setShowNewBundleModal(true)
                          : setShowNewCourseModal(true)
                      }
                      class="pl-4 pr-5 rounded-full gap-2"
                    >
                      <IcoPlus />
                      {courseType === 'bundles' && intl('New bundle')}
                      {courseType === 'courses' &&
                        intl('New {course:string} or product', {
                          course: tenant.terminology.course,
                        })}
                    </BtnPrimary>
                  )}
                </div>
              }
              secondaryActions={
                !isStudentOnly && (
                  <div class="md:inline-flex hidden">
                    <DownloadCsvButton type="guide" />
                  </div>
                )
              }
              courses={courses}
              type={courseType}
              renderItem={({ course, listType, searchTerm, displayDate }) => (
                <CourseItem
                  key={course.id}
                  listType={listType}
                  course={course}
                  highlightTerm={searchTerm}
                  displayDate={displayDate}
                  isArchived={!isStudentOnly && course.isArchived}
                />
              )}
            />
          </MyCoursesPageWrapper>
        )}
        {showNewBundleModal && (
          <CreateBundleModal isProduct={false} onCancel={() => setShowNewBundleModal(false)} />
        )}
      </FixedContent>
    </FixedPage>
  );
}

router.add({ url: 'manage/courses', render: Page, authLevel: 'student', load });
router.add({ url: 'manage', render: Page, authLevel: 'student', load });
router.add({ url: 'courses', render: Page, authLevel: 'student', load });
router.add({ url: 'home', render: Page, authLevel: 'student', load });
router.add({ url: '', render: Page, authLevel: 'student', load });
