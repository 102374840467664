import { showError } from '@components/app-error';
import { BtnSecondary, Button } from '@components/buttons';
import { CourseImage } from '@components/course-image';
import { StandardDialog } from '@components/dialog';
import { Dropdown, MenuItem } from '@components/dropdown';
import {
  IcoBook,
  IcoCheck,
  IcoChevronDown,
  IcoChevronRight,
  IcoFilter,
  IcoRectangleGroup,
  IcoUserGroup,
  IcoX,
} from '@components/icons';
import { showModalForm } from '@components/modal-form';
import { SearchBox } from '@components/search-box';
import { Spinner } from '@components/spinner';
import { RpxResponse, rpx } from 'client/lib/rpx-client';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { useState } from 'preact/hooks';
import { FullCourse as OriginalCourse } from 'server/types';
import { ActivityItem } from './activity-item';
import { router, useRouteParams } from '@components/router';

const FEED_ITEM_TYPES = ['comments', 'signups', 'assignments'] as const;

const store = rpx.guideOverview;
type Feed = RpxResponse<typeof store.getActivitiesFeed>;
type FeedItemTypes = (typeof FEED_ITEM_TYPES)[number];

type Course = Pick<
  OriginalCourse,
  'id' | 'title' | 'isProduct' | 'isBundle' | 'imagePath' | 'status' | 'numStudents'
>;
type Props = {
  courses: Course[];
};

const baseURL = '/overview';

function generateOverviewURL({ type, courseId }: { type?: FeedItemTypes; courseId?: UUID }) {
  const params = new URLSearchParams();
  if (type) {
    params.set('type', type);
  }
  if (courseId) {
    params.set('courseId', courseId);
  }
  return `${baseURL}?${params.toString()}`;
}

function Feed({ type, course }: { type?: FeedItemTypes; course?: Course }) {
  const [feed, setFeed] = useState<Feed>({
    activities: [],
    cursor: '',
    hasMore: false,
  });
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  // Reset the feed when the filters change
  useAsyncEffect(async () => {
    setIsLoading(true);
    const result = await store.getActivitiesFeed({
      courseIds: course ? [course.id] : [],
      type,
      cursor,
    });
    setFeed({
      cursor: result.cursor,
      activities: result.activities,
      hasMore: result.hasMore,
    });
    setCursor(undefined);
    setIsLoading(false);
  }, [type, course]);

  async function loadMore() {
    try {
      setIsLoading(true);
      const result = await store.getActivitiesFeed({
        courseIds: course ? [course.id] : [],
        type,
        cursor,
      });
      setFeed((f) => ({
        cursor: result.cursor,
        activities: [...f.activities, ...result.activities],
        hasMore: result.hasMore,
      }));
      setCursor(result.cursor);
    } catch (e) {
      showError(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div class="flow-root">
      {isLoading && (
        <div class="flex justify-center">
          <Spinner class="border-indigo-400 w-16 h-16 inline-block" />
        </div>
      )}
      {feed.activities.length === 0 && !isLoading && (
        <div class="text-center text-gray-500">No activities found</div>
      )}
      <ul role="list" class="-mb-8">
        {feed.activities.map((item, activityItemIdx) => (
          <li key={item.id}>
            <div class="relative pb-8">
              {activityItemIdx !== feed.activities.length - 1 ? (
                <span
                  aria-hidden="true"
                  class="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200"
                />
              ) : null}
              <ActivityItem item={item} />
            </div>
          </li>
        ))}
      </ul>
      {feed.hasMore && (
        <div class="flex justify-center mt-4 mb-4">
          <BtnSecondary isLoading={isLoading} onClick={loadMore}>
            Load More
          </BtnSecondary>
        </div>
      )}
    </div>
  );
}

export function RecentActivities({ courses }: Props) {
  const params = useRouteParams();
  const selectedType = params.type as FeedItemTypes | undefined;
  const selectedCourseId = params.courseId;
  const selectedCourse = selectedCourseId
    ? courses.find((c) => c.id === selectedCourseId)
    : undefined;

  return (
    <div class="my-8 lg:w-readable-container">
      <h2 class="text-lg font-semibold text-gray-900 mb-4">Recent activities</h2>
      <div class="flex items-center gap-6 mb-8">
        <strong class="inline-flex gap-1 items-center">
          <IcoFilter /> Filters
        </strong>
        <div class="flex gap-2">
          <Dropdown
            triggerClass="bg-indigo-100 px-2 py-1 rounded hover:opacity-75"
            renderMenu={() => (
              <div class="p-2 pb-0 flex flex-col">
                <MenuItem
                  class={`flex justify-between p-2 hover:bg-gray-50 items-center rounded-md capitalize text-gray-700 ${
                    selectedType === undefined ? 'font-bold' : ''
                  }`}
                  href={generateOverviewURL({
                    courseId: selectedCourseId,
                  })}
                >
                  <span>All activities</span>
                  {selectedType === undefined && <IcoCheck class="w-4 h-4 ml-2" />}
                </MenuItem>
                {FEED_ITEM_TYPES.map((type) => {
                  const isSelected = selectedType === type;
                  return (
                    <MenuItem
                      key={type}
                      class={`flex justify-between p-2 hover:bg-gray-50 items-center rounded-md capitalize text-gray-700 ${
                        isSelected ? 'font-bold' : ''
                      }`}
                      href={generateOverviewURL({
                        courseId: selectedCourseId,
                        type,
                      })}
                    >
                      <span>{type}</span>
                      {isSelected && <IcoCheck class="w-4 h-4 ml-2" />}
                    </MenuItem>
                  );
                })}
              </div>
            )}
          >
            <span class="inline-flex items-center gap-2 capitalize">
              <IcoRectangleGroup />
              {selectedType || 'All activities'}
            </span>
          </Dropdown>
          <Button
            class="inline-flex items-center gap-2 bg-indigo-100 px-2 py-1 rounded hover:opacity-75"
            onClick={() => {
              showModalForm(({ resolve }) => (
                <StandardDialog onClose={resolve}>
                  <CoursePicker
                    courses={courses.filter((c) => !!c.numStudents)}
                    selectedCourse={selectedCourse}
                    onPick={(course) => {
                      resolve();
                      router.goto(
                        generateOverviewURL({
                          type: selectedType,
                          courseId: course?.id,
                        }),
                      );
                    }}
                  />
                </StandardDialog>
              ));
            }}
          >
            <IcoBook />
            {selectedCourse ? selectedCourse.title : 'All courses, bundles and products'}
            <IcoChevronDown />
          </Button>
        </div>
      </div>
      <Feed type={selectedType} course={selectedCourse} />
    </div>
  );
}

function CoursePicker({
  courses,
  selectedCourse,
  onPick,
}: {
  courses: Course[];
  selectedCourse?: Course;
  onPick(course?: Course): void;
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const lowerCaseSearchTerm = searchTerm.toLowerCase();
  const filtered = courses.filter((c) => {
    // Exclude selected course from the list
    if (selectedCourse && c.id === selectedCourse.id) {
      return false;
    }
    return c.title.toLowerCase().includes(lowerCaseSearchTerm);
  });

  return (
    <div class="flex flex-col gap-4">
      <SearchBox placeholder="Find a course" value={searchTerm} onTermChange={setSearchTerm} />
      <section class="flex flex-col gap-2">
        {selectedCourse && (
          <CoursePickerItem course={selectedCourse} isSelected onClick={() => onPick(undefined)} />
        )}
        {filtered.map((c) => (
          <CoursePickerItem key={c.id} course={c} isSelected={false} onClick={() => onPick(c)} />
        ))}
      </section>
    </div>
  );
}

function CoursePickerItem({
  course,
  isSelected,
  onClick,
}: {
  course: Course;
  isSelected: boolean;
  onClick: () => void;
}) {
  return (
    <Button
      class={`flex justify-between items-center p-2 text-left overflow-hidden rounded-md ${
        isSelected
          ? 'border-2 bg-indigo-100 hover:bg-indigo-50 border-indigo-300'
          : 'border hover:bg-gray-50'
      }`}
      onClick={onClick}
    >
      <span class="flex items-center gap-5">
        <CourseImage image={course.imagePath} size="size-16" />
        <span>
          <span class="line-clamp-1 grow font-semibold">{course.title}</span>
          <span class="inline-flex items-center text-xs text-gray-500">
            <span class="pr-3 border-r">
              {course.status === 'published' ? 'Signups open' : 'Signups closed'}
            </span>
            <span class="inline-flex items-center pl-3">
              <IcoUserGroup class="w-4 h-4 mr-1 opacity-75" />
              {course.numStudents || 0}
            </span>
          </span>
        </span>
      </span>
      <span>{isSelected ? <IcoX /> : <IcoChevronRight />}</span>
    </Button>
  );
}
