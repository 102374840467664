/**
 * Allow superadmins to edit user tier and lifetime settings.
 */
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useAsyncData } from 'client/lib/hooks';
import { DefaultSpinner } from '@components/spinner';
import { Checkbox } from '@components/checkbox';
import { useState } from 'preact/hooks';
import { showError } from '@components/app-error';
import * as fmt from 'client/lib/fmt';
import { Button } from '@components/buttons';
import { RadioButton } from '@components/radio-button';
import { BillingMethod } from 'server/types';
import { useBasicAutosaver } from '@components/autosaver';
import { PurchasePane } from '../pmts/components/purchase-pane';
import { URLS } from 'shared/urls';
import { StripeLink } from '../pmts/components/stripe-link';
import { IcoExternalLink } from '@components/icons';
import dayjs from 'dayjs';
import { Toggle } from '@components/toggle';

const store = rpx.tierEditor;

const billingMethods: BillingMethod[] = ['system', 'free', 'paypal', 'other'];

function TierForm(props: NonNullable<RpxResponse<typeof store.getTier>>) {
  const [state, setState] = useState(() => ({
    ...props,
    invitationsThrottleUntil: props.invitationsThrottleUntil
      ? dayjs(props.invitationsThrottleUntil).toISOString().split('T')[0]
      : undefined,
  }));

  const autosaver = useBasicAutosaver(
    state,
    async ({
      tier,
      isRestricted,
      isLifetime,
      isComplimentary,
      billingMethod,
      billingNotes,
      isBilledExternally,
      unlimitedInstantCourses,
      instantCourseCredits,
      invitationsThrottleUntil,
    }: typeof state) => {
      try {
        await store.saveTier({
          accountId: props.id,
          userId: props.userId,
          tier,
          isRestricted,
          isLifetime,
          isBilledExternally,
          isComplimentary,
          billingMethod,
          billingNotes,
          unlimitedInstantCourses: !!unlimitedInstantCourses,
          instantCourseCredits,
          invitationsThrottleUntil,
        });
      } catch (err) {
        showError(err);
      }
    },
  );

  return (
    <div class="space-y-6">
      <div class="space-x-6 flex items-center">
        <select
          value={state.tier}
          class="border rounded-md"
          onChange={(e: any) => setState((s) => ({ ...s, tier: e.target.value }))}
        >
          {fmt.tiers.map((t) => (
            <option key={t} value={t}>
              Tier: {fmt.tierName(t)}
            </option>
          ))}
        </select>
        <Checkbox
          checked={state.isRestricted}
          onClick={(e: any) => setState((s) => ({ ...s, isRestricted: e.target.checked }))}
        >
          Is limited
        </Checkbox>
        <Checkbox
          checked={state.isLifetime}
          onClick={(e: any) => setState((s) => ({ ...s, isLifetime: e.target.checked }))}
        >
          Is lifetime
        </Checkbox>
        <Checkbox
          checked={state.isBilledExternally}
          onClick={(e: any) => setState((s) => ({ ...s, isBilledExternally: e.target.checked }))}
        >
          Is billed externally
        </Checkbox>
        <Checkbox
          checked={state.isComplimentary}
          onClick={(e: any) => setState((s) => ({ ...s, isComplimentary: e.target.checked }))}
        >
          Is complimentary
        </Checkbox>
        <Checkbox
          wrapperClass="cursor-not-allowed opacity-60"
          checked={state.isFromMarketingSite}
          disabled
        >
          Is from marketing site
        </Checkbox>
        {autosaver.isDirty && <DefaultSpinner />}
      </div>
      {props.goals && props.goals.length > 0 && (
        <div>
          <strong>Selected goals</strong>: {props.goals?.join(', ')}
        </div>
      )}
      <div class="flex flex-col border rounded-md p-4 space-y-4">
        <label class="inline-flex items-center space-x-3">
          <Toggle
            checked={!!state.invitationsThrottleUntil}
            name="_isThrottled"
            onClick={(e: any) => {
              setState((s) => ({
                ...s,
                invitationsThrottleUntil: e.target.checked
                  ? dayjs().add(33, 'days').toISOString()
                  : '',
              }));
            }}
          />
          <span>Throttle invitations</span>
        </label>
        {state.invitationsThrottleUntil && (
          <label class="inline-flex flex-col max-w-60">
            Throttled until
            <input
              type="date"
              class="ruz-input"
              value={state.invitationsThrottleUntil}
              onChange={(e: any) =>
                setState((s) => ({ ...s, invitationsThrottleUntil: e.target.value }))
              }
            />
          </label>
        )}
      </div>
      <div class="border rounded-md p-4 space-y-4">
        <h3 class="text-lg">Instant Courses</h3>
        <Checkbox
          checked={state.unlimitedInstantCourses}
          onClick={(e: any) =>
            setState((s) => ({ ...s, unlimitedInstantCourses: e.target.checked }))
          }
        >
          Has unlimited instant courses
        </Checkbox>
        {!state.unlimitedInstantCourses && (
          <div class="space-x-6">
            <strong class="w-28">Total Credits</strong>
            <input
              type="number"
              class="inline-ruz-input w-48"
              placeholder="Total Credits"
              value={state.instantCourseCredits}
              onInput={(e: any) =>
                setState((s) => ({ ...s, instantCourseCredits: parseInt(e.target.value, 10) }))
              }
            />
            <span>Credits Used: {state.icCredits?.usage || 0}</span>
            <span>
              Credits remaining: {(state.instantCourseCredits || 0) - (state.icCredits?.usage || 0)}
            </span>
          </div>
        )}
      </div>
      <div class="border rounded-md p-4 space-y-4">
        {state.purchase?.stripeCustomerId && (
          <div class="float-right">
            <StripeLink stripeId={state.purchase.stripeCustomerId}>
              View in Stripe
              <IcoExternalLink class="w-4 h-4 opacity-75 ml-1" />
            </StripeLink>
          </div>
        )}
        <h3 class="text-lg">Billing</h3>
        <div class="space-x-6">
          <strong class="w-28">Billing method</strong>
          {billingMethods.map((m) => (
            <RadioButton
              name="billingMethod"
              value={m}
              key={m}
              checked={state.billingMethod === m}
              wrapperClass={`${
                state.billingMethod === m ? 'bg-indigo-50 border-indigo-200' : 'border-transparent'
              } border p-2 rounded`}
              onClick={() => {
                setState((s) => ({ ...s, billingMethod: m }));
              }}
            >
              {m}
            </RadioButton>
          ))}
        </div>
        <div class="space-x-6 flex">
          <strong class="w-28">Billing note</strong>
          <input
            type="text"
            class="ruz-input"
            placeholder="Brief, billing-related note"
            max="255"
            value={state.billingNotes}
            onInput={(e: any) => setState((s) => ({ ...s, billingNotes: e.target.value }))}
          />
        </div>
        <PurchasePane
          asSeller
          purchase={state.purchase}
          price={state.price}
          coupon={state.coupon}
          priceUrl={
            state.price
              ? URLS.admin.price({ priceId: state.price.id, productId: state.price.productId })
              : undefined
          }
        />
        {state.price && state.instanteCoursePrice && <hr class="my-8" />}
        <PurchasePane
          asSeller
          purchase={state.instantCoursePurchase}
          price={state.instanteCoursePrice}
          coupon={state.instantCourseCoupon}
          priceUrl={
            state.instanteCoursePrice
              ? URLS.admin.price({
                  priceId: state.instanteCoursePrice.id,
                  productId: state.instanteCoursePrice.productId,
                })
              : undefined
          }
        />
      </div>
    </div>
  );
}

export function AccountEditor({ userId }: { userId: UUID }) {
  const { isLoading, data } = useAsyncData(() => store.getTier({ userId }), [userId]);

  if (isLoading) {
    return <DefaultSpinner />;
  }

  if (!data) {
    return (
      <p>
        This user has no Ruzuku account.
        <Button
          class="text-indigo-600 ml-2"
          onClick={() => {
            store.provisionAccount({ userId }).then(() => location.reload());
          }}
        >
          Provision Account
        </Button>
      </p>
    );
  }

  return TierForm(data);
}
