/**
 * This module contains a minidoc mixin that adds media (images, video, etc)
 * support to the document.
 */

import './vidstack-web-component';
import {
  MinidocCardDefinition,
  h,
  Cardable,
  fileDrop,
  MinidocToolbarAction,
  CardRenderOptions,
  onMount,
  MinidocToolbarEditor,
  LinkBehavior,
} from 'minidoc-editor';
import quikpik from 'quikpik';
import { EditorMiddleware } from 'minidoc-editor/dist/types/types';
import { MediaState, mediaUploader, RenderOpts, UploadState } from './media-uploader';
import { getCurrentRange, mediaContextMenu, Minidoc, setCurrentRange } from 'client/lib/minidoc';
import { keyboardNav } from 'client/lib/keyboard-nav';
import { isAudio, isImage, isPDF, isVideo } from 'shared/media';
import { getAspectRatio, getAspectRatioStyle, renderMediaPlayer, setPoster } from './media-player';
import { LiteralIconImg } from '@components/toolbar-dropdown';
import { showVideoPosterModal } from './video-poster-modal';
import { DownloadableFile } from 'server/types';
import { imageContextMenu, setContainerStyle } from './image-context-menu';
import { LiteralCaptionsIcon } from '@components/icons/video-player';
import { showTranscriptEditor } from './transcript-editor';

export type QuikpikOpts = Partial<Parameters<typeof quikpik>[0]>;

interface MediaToolbarProps {
  label?: string;
  accept?: string;
  sources?: QuikpikOpts['sources'];
}

type Files = FileList | File | DownloadableFile;

/**
 * The interface we'll add as an extension to the minidoc editor.
 */
export interface Mediable {
  hideContextMenu: boolean;
  insertMedia(files: Files): void;
}

/**
 * Render the caption editor.
 */
const captionClass =
  'clear-both text-gray-600 dark:text-gray-300 placeholder-gray-400 text-sm text-center mt-2 w-full outline-none border-none bg-transparent';

function captionEditor(opts: CardRenderOptions<MediaState>) {
  const { state } = opts;
  const caption = state.caption || '';
  const focusContainer = () => input.closest<HTMLInputElement>('mini-card')?.focus();
  const input = h<HTMLInputElement>('input', {
    class: `minidoc-caption-input ${
      caption.length === 0 ? 'minidoc-caption-empty ' : ''
    }${captionClass}`,
    value: caption,
    placeholder: 'Add a caption...',
    // We don't want drag / drop when the user is trying to select the caption text.
    draggable: true,
    ondragstart(e: Event) {
      e.preventDefault();
      e.stopPropagation();
    },
    onkeydown(e: any) {
      keyboardNav(e, {
        up: focusContainer,
        down: focusContainer,
      });
    },
    oninput() {
      state.caption = input.value;
      opts.stateChanged(state);
    },
    onbeforeinput(e: Event) {
      // Prevent the editor from stopping edits here
      e.stopPropagation();
    },
    onblur() {
      input.classList.toggle('minidoc-caption-empty', input.value.length === 0);
    },
  });
  return input;
}

/**
 * Render the caption in readonly mode.
 */
function captionViewer(opts: CardRenderOptions<MediaState>) {
  const { state } = opts;
  const caption = state.caption || '';
  if (!caption) {
    return;
  }
  return h(
    'figcaption',
    {
      class: captionClass,
    },
    caption,
  );
}

/**
 * Create the caption input.
 */
function renderCaption(opts: CardRenderOptions<MediaState>) {
  if (opts.editor.readonly) {
    return captionViewer(opts);
  }
  return captionEditor(opts);
}

/**
 * If the upload has failed, this will render an error message and retry/cancel buttons over the upload progress element
 */
function renderUploadFailed(mediaUploaderEl: HTMLElement, retry: () => void) {
  // Restart the attachment upload
  const retryButton = h(
    'button',
    { class: 'btn btn-primary rounded-full', onclick: retry },
    'Retry',
  );

  // Cancel and delete the media card
  const cancelButton = h(
    'button',
    {
      type: 'button',
      class: 'btn border mr-2 rounded-full shadow-none hover:bg-gray-100',
      onclick: () => {
        mediaUploaderEl.closest('.minidoc-card')?.remove();
      },
    },
    'Delete',
  );

  // Error dialog with retry/cancel buttons
  const errorInfoEl = h(
    '.minidoc-upload-error',
    h('header.minidoc-upload-header', h('span.minidoc-upload-name', 'Upload failed')),
    h(
      'div',
      { class: 'relative flex flex-col gap-2' },
      h('span', { class: 'italic text-red-400' }, 'There was a problem uploading your attachment.'),
      h('div', { class: 'flex gap-6' }, retryButton, cancelButton),
    ),
  );

  // Insert error options
  mediaUploaderEl.appendChild(errorInfoEl);
}

/**
 * If we have an uploader that has not completed, this will render the progress bar.
 */
function renderProgress(uploader?: UploadState) {
  const onProgress = uploader && uploader.onProgress;
  if (!onProgress) {
    return;
  }

  const barEl = h<HTMLElement>('.minidoc-progress-bar');
  const percentEl = h('span.minidoc-upload-percent', `0%`);

  // Display an ongoing progress percentage bar
  const el = h(
    '.minidoc-upload-progress',
    h(
      'header.minidoc-upload-header',
      h('span.minidoc-upload-name', 'Uploading ', uploader.file.name),
      percentEl,
    ),
    h('.minidoc-progress-bar-wrapper', barEl),
  );

  const setProgress = (percent: number) => {
    percentEl.textContent = `${Math.round(percent)}%`;
    barEl.style.width = `${percent}%`;

    if (percent === 100) {
      barEl.classList.add('minidoc-progress-done');
      // This gives the user a moment to see it's complete, and is just
      // a little UX polish.
      setTimeout(() => el.remove(), 500);
    }
  };

  onMount(el, () => onProgress(setProgress));

  setProgress(uploader.progress);

  return el;
}

function mediaClickCapture(media: Element, text: string, ...children: any[]) {
  return h(
    '.relative',
    media,
    h(
      '.mini-media-click-capture.absolute.inset-0.z-10',
      text && h('.mini-media-text', text),
      ...children,
    ),
  );
}

function renderPDFViewer(opts: RenderOpts) {
  const { state } = opts;
  const src = state.url;
  const isTmp = src.startsWith('tmp:');
  const baseAttrs = {
    class: `bg-gray-800 w-full rounded aspect-[8/12]`,
  };
  const makeIframe = () => {
    const iframe = h('iframe', {
      ...baseAttrs,
      src: `/pdf.html?pdf=${state.url}`,
    });
    if (opts.readonly) {
      return iframe;
    }

    return mediaClickCapture(
      iframe,
      'Click "Student View" to interact with your PDF.',
      mediaContextMenu({ label: 'PDF' }),
    );
  };

  if (isTmp) {
    const placeholder = h('div', baseAttrs);
    opts.container.addEventListener('mini:uploadcomplete', () => {
      placeholder.replaceWith(makeIframe());
    });
    return placeholder;
  }

  return makeIframe();
}

function displayName(state: MediaState) {
  if (state.name) {
    return state.name;
  }
  const extIndex = state.url?.lastIndexOf('.');
  const extName = `Download ${extIndex > 0 ? state.url.slice(extIndex) : ''} file...`;
  if (!state.type) {
    return extName;
  }
  if (state.type.includes('document')) {
    return `Download document...`;
  }
  if (state.type.includes('image')) {
    return `Download image...`;
  }
  if (state.type.includes('audio')) {
    return `Download audio...`;
  }
  if (state.type.includes('video')) {
    return `Download video...`;
  }
  return extName;
}

function renderFileAttachment(opts: RenderOpts) {
  const { state } = opts;
  const src = state.url;
  const icon = h('span', {
    // hero icon hero-paperclip
    innerHTML: `<svg class="w-5 h-5 mr-2 inline-block align-middle opacity-75" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" /></svg>`,
  });
  const blockStyle =
    '.block.text-indigo-600.bg-gray-100.rounded-lg.px-4.p-2.dark:text-indigo-200.dark:bg-gray-800.hover:bg-gray-200.dark:hover:bg-gray-700';

  const isTmp = src.startsWith('tmp:');

  if (!isTmp && opts.readonly) {
    // Readonly file reference
    const download = state.name || 'true';
    return h(
      `a${blockStyle}`,
      {
        target: '_blank',
        rel: 'noopener noreferrer',
        download,
        href: `${src}?download=${download}`,
      },
      icon,
      displayName(state),
    );
  }

  if (isTmp && opts.readonly) {
    return h(
      `span${blockStyle}`,
      h(
        'span',
        h(
          'span.inline-flex.items-center.justify-center.bg-red-500.p-2.rounded-full.text-white.leading-none.h-6.w-6.opacity-75.mr-2.font-bold.text-base',
          '!',
        ),
      ),
      h('span.text-gray-500', `File unavailable.`),
    );
  }

  // Editable file reference
  return h(
    `span${blockStyle}.flex.items-center`,
    mediaContextMenu({ label: 'File' }),
    icon,
    h('input', {
      class:
        'bg-transparent border-transparent outline-none focus:border-indigo-400 rounded grow px-2 -ml-2 text-indigo-600 underline',
      type: 'text',
      value: state.name,
      placeholder: 'Edit file name',
      oninput: (e: any) => {
        opts.state.name = e.target.value;
        opts.stateChanged(opts.state);
      },
    }),
  );
}

function renderMediaAttachment(opts: RenderOpts) {
  const { state } = opts;
  const playerEl = renderMediaPlayer({
    fileId: state.fileId,
    type: state.type,
    url: state.url,
    poster: state.poster,
    ratio: state.ratio,
    width: state.width,
    hasCaptions: state.hasCaptions,
    hideCaptions: opts.hideCaptions,
    overlay: !opts.readonly ? `Click "Student View" to view your video.` : undefined,
    setProps(f) {
      const newState = Object.assign(state, f(state));
      if (!opts.readonly) {
        opts.stateChanged(newState);
        // Show the transcript button if we have a fileId and captions
        if (state.hasCaptions && state.fileId) {
          playerEl.parentElement
            ?.querySelector('.js-transcript-button')
            ?.classList.remove('hidden');
        }
      }
      return newState;
    },
    tmpUrl: opts.tmpUrl,
  });

  if (opts.readonly) {
    return playerEl;
  }
  // When we're in edit mode, we'll overlay a div across the media player
  // to prevent it from playing when you click to select / drag / etc.
  return mediaClickCapture(
    playerEl,
    '',
    mediaContextMenu(
      { label: state.type.split('/')[0] || 'attachment' },
      h('button.mini-context-action', {
        async onclick() {
          const result = await showTranscriptEditor({
            fileId: state.fileId!,
          });
          // Update the fileId if the saveCaptions endpoint returns a new one
          if (result?.newFileId) {
            state.fileId = result.newFileId;
            const newState = Object.assign(state, {
              fileId: result.newFileId,
              url: `/files/${result.newFileId}`,
            });
            opts.stateChanged(newState);
          }
        },
        type: 'button',
        class: `p-2 px-3 text-sm inline-flex items-center js-transcript-button${
          !!state.fileId && !!state.hasCaptions ? '' : ' hidden'
        }`,
        innerHTML: `${LiteralCaptionsIcon} <span class="ml-2">Modify Transcript</span>`,
      }),
      isVideo(state.type) &&
        h('button.mini-context-action', {
          onclick() {
            const url = opts.tmpUrl || state.url;
            if (!url) {
              return;
            }

            const promise = showVideoPosterModal({
              url,
              type: !state.url ? 'video/mp4' : state.type,
              isPublic: !!opts.isPublic,
            });

            promise.then((result) => {
              if (result) {
                state.poster = result.publicUrl;
                opts.stateChanged(state);
                setPoster({ poster: state.poster, playerEl });
              }
            });
          },
          type: 'button',
          class: 'p-2 px-3 text-sm inline-flex items-center',
          innerHTML: `${LiteralIconImg} <span class="ml-2">Change Poster Image</span>`,
        }),
    ),
  );
}

function renderImageAttachment(opts: RenderOpts & { loadImagesEagerly?: boolean }) {
  const { state } = opts;
  let src = opts.tmpUrl || state.url;

  if (src?.startsWith('/files') && !!opts.cdnWidth) {
    src += `?width=${opts.cdnWidth}`;
  }

  let content = h('img', {
    style: getAspectRatioStyle(state),
    className: 'loading',
    loading: opts.loadImagesEagerly ? undefined : 'lazy',
    src,
    alt: state.name,
    // If we haven't computed the aspect ratio, we do so now, so that
    // when the document renders in the future, we don't have images
    // popping in and pushing the content down.
    onload(e: Event) {
      const img: HTMLImageElement = e.target as any;
      img.classList.remove('loading');
      if (!state.ratio) {
        state.ratio = getAspectRatio(img);
        state.width = img.naturalWidth;
      }
    },
    onerror() {
      content.classList.remove('loading');
    },
  });

  if (opts.readonly) {
    // We'll only wrap in a real "a" tag in readonly mode. The a-tag is a
    // nuisance while editing. If there is no link, we'll make the image
    // open in a new tab.
    const href = state.href || `/view-image?url=${encodeURIComponent(src)}`;
    content = h(`a.flex.items-center.justify-center`, { href, target: '_blank' }, content);
    if (!state.href) {
      content.classList.add('cursor-zoom-in');
    }
  }

  // In edit mode, the link will be a little bubble so we can click it to
  // preview it, but also click the image to select the media card.
  const makeEditLink = (href: string) =>
    h(
      'a.absolute.bottom-1.right-1.px-2.h-8.text-xs.bg-gray-50.text-gray-500.rounded-md.flex.items-center.opacity-75.whitespace-nowrap.text-ellipsis.overflow-hidden.overflow-ellipsis.max-w-1/2',
      { href, target: '_blank', rel: 'noopener', noreferrer: true },
      h('span.mr-2', {
        innerHTML: `
        <svg
          class="h-4 w-4 opacity-75"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
          />
        </svg>
      `,
      }),
      h('span', href),
    );

  setContainerStyle(opts);

  const contentWrapper = h(
    '.relative.flex.items-center.justify-center',
    content,
    !opts.readonly && state.href && makeEditLink(state.href),
  );

  const el = h(
    'figure',
    contentWrapper,
    renderCaption(opts),
    (opts.editor as unknown as Mediable).hideContextMenu ? null : imageContextMenu(opts),
  );

  const behavior: LinkBehavior = {
    getHref() {
      return state.href || '';
    },
    setHref(href) {
      state.href = href;
      opts.stateChanged(state);
      const link = el.querySelector('a');
      if (!href) {
        link?.remove();
      } else {
        const anchor = link || makeEditLink(href);
        anchor.lastElementChild!.textContent = href;
        contentWrapper.append(anchor);
      }
    },
  };

  opts.behavior = behavior;

  return el;
}

function renderError(opts: RenderOpts) {
  const { state } = opts;
  return h(
    'div.text-red-500.py-2.px-4.rounded.bg-gray-50.dark:bg-gray-800',
    (state as any).message || `Invalid media.`,
  );
}

/**
 * Render the core media element.
 */
function renderMediaCard(
  opts: RenderOpts & { loadImagesEagerly?: boolean; shouldRenderPdfViewer?: boolean },
) {
  const { type } = opts.state;

  if (isImage(type)) {
    return renderImageAttachment(opts);
  }

  if (isAudio(type) || isVideo(type)) {
    return renderMediaAttachment(opts);
  }

  if (opts.shouldRenderPdfViewer && isPDF(type)) {
    return renderPDFViewer(opts);
  }

  if (type === 'error') {
    return renderError(opts);
  }

  return renderFileAttachment(opts);
}

/**
 * The minidoc card responsible for rendering media (images, video, audio, PDFs, etc).
 */
export function generateMediaCard(parentOpts?: {
  shouldRenderPdfViewer?: boolean;
  hideCaptions?: boolean;
  loadImagesEagerly?: boolean;
  cdnWidth?: number;
}) {
  const shouldRenderPdfViewer = parentOpts?.shouldRenderPdfViewer ?? false;
  const hideCaptions = parentOpts?.hideCaptions ?? false;
  const loadImagesEagerly = parentOpts?.loadImagesEagerly ?? false;

  const mediaCard: MinidocCardDefinition<MediaState> = {
    type: 'media',

    /**
     * This card will take over any element that matches this selector.
     */
    selector: 'video,audio,figure,img,a[data-state],.media-placeholder',

    /**
     * Derive the media state from the specified element. This allows us
     * to handle pastes from other sources.
     */
    deriveState(el) {
      try {
        // If this is an element that we've produced via minidoc,
        // we'll extract the state from the data-state property and
        // be done with it. If it's being pasted from some external
        // source, we'll do our best to derive the state.
        if (el.dataset.state) {
          return JSON.parse(el.dataset.state);
        }

        switch (el.tagName) {
          case 'MINI-CARD':
            return JSON.parse(el.getAttribute('state')!);
          case 'VIDEO':
          case 'AUDIO': {
            const source = el.querySelector('source');
            return {
              name: 'unknown',
              type: source?.type || el.tagName.toLowerCase() + '/unknown',
              url: source?.src || (el as HTMLVideoElement).src,
              poster: (el as HTMLVideoElement).poster,
            };
          }
          case 'FIGURE': {
            const caption = el.querySelector('figcaption');
            const img = el.querySelector('img');
            const a = el.querySelector('a');
            return {
              name: img?.alt || 'image',
              type: 'image/unknown',
              url: img?.src,
              caption: caption?.textContent,
              href: a?.href,
            };
          }
          case 'IMG': {
            const img = el as HTMLImageElement;
            return {
              name: img.alt || 'image',
              type: 'image/unknown',
              url: img.src,
              caption: img.alt,
            };
          }
          case 'A': {
            const a = el as HTMLAnchorElement;
            return {
              name: a.download,
              type: a.type || 'unknown',
              url: a.href,
            };
          }
          default: {
            console.warn('Invalid derive state element:', el);
            throw new Error('Unrecognized memdia.');
          }
        }
      } catch (err) {
        console.warn('Failed to derive media state:', err);
        return {
          type: 'error',
          message: err.message,
        };
      }
    },

    /**
     * Convert the specified state to HTML.
     */
    serialize({ state, editor }) {
      if (!state) {
        return '';
      }
      let el: HTMLElement;
      const src = state.url;
      if (mediaUploader(editor).getUploadState(state.url)?.isUploading) {
        el = h('.media-placeholder');
      } else if (isImage(state.type)) {
        const img = h('img', { src, alt: state.name });
        el = h(
          'figure',
          state.href ? h('a', { href: state.href }, img) : img,
          state.caption && h('figcaption', state.caption),
        );
      } else if (isVideo(state.type)) {
        el = h('video', { src, preload: 'none', controls: true });
      } else if (isAudio(state.type)) {
        el = h('audio', { src, preload: 'none', controls: true });
      } else {
        el = h('a', { target: '_blank', href: src }, state.name);
      }

      el.dataset.state = JSON.stringify(state);

      return el.outerHTML;
    },

    /**
     * Convert the specified state to a DOM element.
     */
    render(opts: RenderOpts & { loadImagesEagerly?: boolean; shouldRenderPdfViewer?: boolean }) {
      try {
        const uploader = mediaUploader(opts.editor).getUploadState(opts.state.url);
        opts.shouldRenderPdfViewer = shouldRenderPdfViewer;
        opts.hideCaptions = hideCaptions;
        opts.loadImagesEagerly = loadImagesEagerly;
        opts.cdnWidth = parentOpts?.cdnWidth;

        // If there is an uploader, it's got the latest state for our asset.
        // opts.state can be stale in this case, e.g. if the user performed
        // an undo / redo. The only thing we want to keep out of our undo /
        // redo state is the caption, if there is one.
        // If we have an uploader, we want to use its file as our URL, as the
        // asset is not yet available on our servers.
        if (uploader) {
          uploader.mediaState.caption = opts.state.caption;
          Object.assign(opts.state, uploader.mediaState);

          // Replace the media uploader and media card, and hide the error dialog
          // FIXME: retries are still unworking
          const onRetry = () => {
            console.log(`retry upload: ${opts.state.url}`);
            uploader?.retry();
            el.replaceWith(mediaCard.render(opts));
          };
          const url = URL.createObjectURL(uploader.file);
          opts.onRetry = onRetry;
          opts.tmpUrl = url;
          const el = h('.media-card', renderMediaCard(opts), renderProgress(uploader));

          onMount(el, () => {
            el.dispatchEvent(new CustomEvent('mini:media', { bubbles: true }));
            return () => URL.revokeObjectURL(url);
          });

          uploader.promise
            .then(() => {
              // Trigger change handlers when the upload is ready.
              el.dispatchEvent(new CustomEvent('mini:media', { bubbles: true }));
              opts.stateChanged(Object.assign(opts.state, uploader?.mediaState));
              el.dispatchEvent(new CustomEvent('mini:change', { bubbles: true }));
              el.dispatchEvent(
                new CustomEvent('mini:uploadcomplete', {
                  bubbles: true,
                }),
              );
            })
            .catch(() => {
              console.error(`upload media failure: `, opts.state.url);

              // Hide the progress bar element
              (el.querySelector('.minidoc-upload-progress') as HTMLElement)?.classList?.add(
                'hidden',
              );

              // Render upload error
              renderUploadFailed(el, onRetry);
            });

          return el;
        }

        return renderMediaCard(opts);
      } catch (err) {
        console.error(err);
        return renderError(opts);
      }
    },
  };
  return mediaCard;
}

// Default media card
export const mediaCard = generateMediaCard();

/**
 * The minidoc toolbar button responsible for showing the file picker.
 */
export const mediaToolbarAction = (
  props: MediaToolbarProps = {},
): MinidocToolbarAction & { run(t: MinidocToolbarEditor, opts?: QuikpikOpts): void } => {
  const { label = 'Add image, video, etc', accept } = props;

  return {
    id: 'media',
    label,
    html: '<svg fill="currentColor" viewBox="0 0 24 24"><path d="M5 8.5c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5c0 .829-.672 1.5-1.5 1.5s-1.5-.671-1.5-1.5zm9 .5l-2.519 4-2.481-1.96-4 5.96h14l-5-8zm8-4v14h-20v-14h20zm2-2h-24v18h24v-18z"/></svg>',
    run(t: MinidocToolbarEditor, opts: QuikpikOpts = {}) {
      const range = getCurrentRange(t);

      quikpik({
        customProgress: true,
        sources: props.sources || ['filepicker', 'takephoto', 'takeaudio', 'takevideo'],
        accept,
        ...opts,
        upload({ files }) {
          setCurrentRange(range);
          (t as unknown as Mediable).insertMedia(files[0]);

          // This is a hack to work around the fact that quikpik expects
          // this return shape, even though we're managing the upload on
          // our own. This is another quikpik TODO.
          return {
            cancel: () => {},
            promise: Promise.resolve(),
          };
        },
      });
    },
  };
};

interface MediaMiddlewareProps {
  isPublic?: boolean;
  isDownloadable?: (fileType: string) => boolean;
  accept?: string;
  domain?: string;
  hideContextMenu?: boolean;
}

function doesFileHaveUrl(f: Files): f is DownloadableFile {
  return !!(f as DownloadableFile).url;
}

/**
 * The minidoc middleware that adds media card functionality.
 */
export const mediaMiddleware =
  (props: MediaMiddlewareProps = {}): EditorMiddleware<Mediable> =>
  (next, editor) => {
    const { isPublic = false, isDownloadable, domain, accept } = props;
    const result = editor as Minidoc & Cardable & Mediable;

    function insertMedia(files: Files) {
      const file = files instanceof FileList ? files[0] : files;

      // Discard the file if the file type doesn't start with accept parameter
      if (accept && !file.type?.startsWith(`${accept}/`)) {
        return;
      }
      const downloadable = isDownloadable && file.type ? isDownloadable(file.type) : false;

      if (doesFileHaveUrl(file)) {
        const state: MediaState = {
          url: file.url,
          type: file.type || 'application/octet-stream',
          name: file.name,
          downloadable,
        };
        editor.root.focus();
        result.insertCard('media', state);
        return;
      }

      const uploader = mediaUploader(result);
      result.insertCard(
        'media',
        uploader.createUploadState({
          file,
          isPublic,
          downloadable,
          domain,
        }).mediaState,
      );
    }

    result.insertMedia = insertMedia;
    result.hideContextMenu = props.hideContextMenu;

    fileDrop((e) => result.insertMedia(e.files))(next, result);

    return result;
  };
