import { LessonEditor } from './lesson-editor';
import { ModulesNav, ModulesPage } from './modules-nav';
import { router, RouteProps, useRouteParams } from '@components/router';
import {
  getCurrentCourse,
  getCurrentLesson,
  loadState,
  Props,
  saveLesson,
  updateModule,
  load,
  deleteLesson,
  refreshOutline,
} from './actions';
import { reducer } from './reducer';
import { useEffect, useReducer, useState } from 'preact/hooks';
import { LoadingIndicator } from '@components/loading-indicator';
import { scrollToModule } from './scroll-to-module';
import { CopyModal, CopyModalProps } from './copy-modal';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { ModuleForm } from '@components/module-helpers';
import { ManageState } from './types';
import { URLS } from 'shared/urls';
import { useCurrentTenant } from '@components/router/session-context';
import { showToast } from '@components/toaster';
import { emptyLessonTitle } from 'shared/terminology';
import { rpx } from 'client/lib/rpx-client';
import { showError } from '@components/app-error';
import { useLocalStorageState } from 'client/lib/hooks';
import { GuideCoursePage } from '@components/guide-course-page';
import { AppRoute } from 'client/lib/app-route/types';
import { DialogFooter, StandardDialog, showDialog } from '@components/dialog';

const PIN_STORAGE_KEY = 'guidePinSideNav';

function Page(props: Props & { data: ManageState }) {
  const tenant = useCurrentTenant();
  const { terminology } = tenant;
  const [state, dispatch] = useReducer(reducer, props.data);
  const { courseId } = state;
  const course = getCurrentCourse(state);
  const lesson = getCurrentLesson(state);
  const [copying, setCopying] = useState<
    Omit<CopyModalProps, 'refreshOutline' | 'onClose'> | undefined
  >(undefined);
  const [isPinned, setIsPinned] = useLocalStorageState(PIN_STORAGE_KEY, true);
  const [showOutline, setShowOutline] = useState(false); // If the outline is unpinned, this controls whether or not it is visible / hidde

  const module = state.moduleId ? state.modules[state.moduleId] : undefined;
  const closeModuleEditor = () =>
    dispatch({ type: 'editingModule', payload: { moduleId: undefined } });

  const promptForDelete = async (lessonId: UUID) => {
    if (!lesson) {
      return;
    }

    const confirmed = await showDialog({
      mode: 'warn',
      title: `Permanently delete ${terminology.lesson}?`,
      confirmButtonText: `Permanently delete ${terminology.lesson}`,
      children: (
        <>
          Are you sure you want to delete {terminology.lesson} &quot;
          <em class="text-black">{lesson.title || emptyLessonTitle(tenant)}</em>&quot;? This action
          cannot be undone.
        </>
      ),
    });

    if (!confirmed) {
      return;
    }

    // Perform the delete.
    try {
      await deleteLesson(course.id, lesson, dispatch);
      showToast({
        type: 'ok',
        title: `${terminology.lesson} #${lesson.id} deleted`,
        message: `Deleted "${lesson.title}".`,
      });
      // We're deleting the current lesson, so redirect to the nearest sibling
      // lesson, if there is one.
      const lessonIds = course.modules.flatMap((s) => state.modules[s].lessons);
      const index = lessonIds.indexOf(lessonId);
      const nextLesson = lessonIds[index - 1] || lessonIds[index + 1];

      if (nextLesson) {
        router.goto(`/manage/courses/${course.id}/lessons/${nextLesson}`);
      }
    } catch (err) {
      showError(err);
    }
  };

  useEffect(() => {
    if (`${state.courseId}` !== props.courseId || `${state.lessonId}` !== props.lessonId) {
      loadState(props, state, dispatch);
    }
  }, [props.courseId, props.lessonId, state.courseId, state.lessonId]);

  useEffect(() => {
    // When we are editing a module, we'll ensure it's scrolled into view in the nav,
    // but won't bother animating the highlight, as that's distracting.
    state.moduleId && scrollToModule(state.moduleId, { highlight: false });
  }, [state.moduleId]);

  useAsyncEffect(async () => {
    if (!courseId) {
      return;
    }
    try {
      await rpx.courses.setLastOpenedAt({ courseId });
    } catch (err) {
      console.warn('Could not set last_opened_at', err);
    }
  }, [courseId]);

  return (
    <GuideCoursePage
      course={course}
      viewLink={
        lesson &&
        URLS.student.lesson({
          course,
          lesson,
        })
      }
      type="lessons"
    >
      <TutorialModal />
      {!lesson && <ModulesPage state={state} dispatch={dispatch} />}
      {lesson && (
        <div class="guide-page-content flex flex-grow bg-white">
          <ModulesNav
            state={state}
            dispatch={dispatch}
            isPinned={isPinned}
            setIsPinned={setIsPinned}
            showOutline={showOutline}
            setShowOutline={setShowOutline}
          />
          <div class="flex-grow p-4 md:p-8">
            <LessonEditor
              key={lesson.id}
              lesson={lesson}
              course={course}
              onDeleteLesson={(id) => promptForDelete(id)}
              onCopyLesson={(id) => {
                setCopying({ type: 'lesson', item: state.lessons[id] });
              }}
              onSave={(payload) =>
                saveLesson({
                  courseId,
                  dispatch,
                  lesson: payload,
                })
              }
              showOutlineButton={!isPinned}
              onOutlineClick={() => setShowOutline(true)}
            />
          </div>
        </div>
      )}
      {state.loadingLesson && <LoadingIndicator />}
      {copying && (
        <CopyModal
          {...copying}
          refreshOutline={() => refreshOutline(props, dispatch)}
          onClose={() => {
            setCopying(undefined);
          }}
        />
      )}
      {module && (
        <ModuleForm
          key={module.id}
          id={module.id}
          courseId={course.id}
          accessFormat={course.accessFormat}
          isAbsoluteSchedule={!!course.isAbsoluteSchedule}
          title={module.title}
          prices={module.prices}
          isDraft={module.isDraft}
          startOffset={module.startOffset}
          startDate={module.startDate}
          onDelete={() => {
            dispatch({ type: 'deletedModule', payload: { id: module.id } });
          }}
          onUpdate={(opts) => {
            const result = updateModule(state, { id: module.id, ...opts }, dispatch);
            result.then(() => scrollToModule(module.id));
            return result;
          }}
          onClose={closeModuleEditor}
          onCopy={() => setCopying({ type: 'module', item: module })}
        />
      )}
    </GuideCoursePage>
  );
}

function TutorialModal() {
  const { showTutorial } = useRouteParams();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => setIsOpen(showTutorial !== undefined), [showTutorial]);

  if (!isOpen) {
    return null;
  }

  return (
    <StandardDialog contentWidth onClose={() => setIsOpen(false)}>
      <h2 class="text-lg lg:text-3xl font-semibold text-center py-2">
        Welcome to the Ruzuku course editor!
      </h2>
      <h4 class="text-lg text-center py-2">Watch this tutorial to get started...</h4>
      <iframe
        src="https://player.vimeo.com/video/845861404?h=4e222ae5de&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        className="w-full h-48 lg:h-96"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title="Ruzuku course setup"
      ></iframe>
      <DialogFooter hideCancel confirmButtonText="Jump into your course..." />
    </StandardDialog>
  );
}

/**
 * This is a simple translation layer to prevent us
 * from having annoyingly typed props in the Page.
 */
function PageFn(props: RouteProps) {
  return <Page {...(props.route.params as any)} data={props.data} />;
}

/*
 * Fetches the first lesson of a module and redirect to it.
 */
async function loadModuleRoute(route: AppRoute) {
  const firstLesson = await rpx.modules.getFirstLesson({ id: route.params.moduleId });
  const url = firstLesson
    ? URLS.guide.lesson({ courseId: route.params.courseId, lessonId: firstLesson.id })
    : URLS.guide.lessons({ courseId: route.params.courseId });
  router.rewrite(url);
}

const loadLessonRoute = (route: AppRoute) => load(route.params.courseId, route.params.lessonId);

router.add({
  url: 'manage/courses/:courseId/lessons/:lessonId',
  load: loadLessonRoute,
  render: PageFn,
  authLevel: 'guide',
});

router.add({
  url: 'manage/courses/:courseId/lessons',
  load: loadLessonRoute,
  render: PageFn,
  authLevel: 'guide',
});

router.add({
  url: 'manage/courses/:courseId/modules/:moduleId',
  load: loadModuleRoute,
  render: () => {
    return <LoadingIndicator />;
  },
  authLevel: 'guide',
});
