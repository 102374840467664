/**
 * This module contains a UI component for choosing a currency and entering
 * an amount.
 */
import { currencies, currencySymbol } from 'shared/payments/fmt';
import { JSX } from 'preact';
import { Currency } from 'server/types';
import { useState } from 'preact/hooks';
import { autoFocusSelf } from 'client/utils/autofocus';

type Props = JSX.InputHTMLAttributes<HTMLInputElement> & {
  currency?: Currency;
  onCurrencyChange?(currency: Currency): void;
};

const currencyKey = 'defaultCurrency';

const fullCurrencySymbol = (c: Currency) => {
  const symbol = currencySymbol(c);
  if (symbol === c) {
    // This prevents us from displaying CHF CHF in the list for Swiss Franc.
    // Instead, we display just CHF with some leading blank spaces (including
    // a braille blank character https://qwerty.dev/whitespace/) to get the
    // text to mostly align with the other options.
    return ` ⠀${c}`;
  }
  return `${currencySymbol(c)} ${c}`;
};

export function defaultCurrency(currency?: Currency): Currency {
  return currency || (localStorage.getItem(currencyKey) as Currency) || 'USD';
}

export function CurrencyInput({ onCurrencyChange, ...props }: Props) {
  const [currency, setCurrency] = useState(() => defaultCurrency(props.currency));
  const value = onCurrencyChange ? props.currency || currency : currency;
  return (
    <div class="inline-flex">
      <select
        value={value}
        name="currency"
        class="inline-ruz-input pr-6 cursor-pointer rounded-r-none rounded-l -mr-px focus:z-10"
        onChange={(e: any) => {
          const newCurrency = e.target.value;
          localStorage.setItem(currencyKey, newCurrency);
          setCurrency(newCurrency);
          onCurrencyChange?.(newCurrency);
        }}
      >
        {currencies.map((c) => (
          <option key={c} value={c}>
            {fullCurrencySymbol(c)}
          </option>
        ))}
      </select>
      <input
        type="number"
        name="price"
        // This is to make float numbers valid
        step="0.01"
        ref={props.autoFocus ? autoFocusSelf : undefined}
        class="inline-ruz-input flex-grow rounded-l-none appearance-none rounded-r w-28"
        {...props}
      />
    </div>
  );
}
