import { Button } from '@components/buttons';
import { StandardDialog } from '@components/dialog';
import { IcoCheckBadge, IcoPhoneOutgoing, IcoPlay } from '@components/icons';
import { showModalForm } from '@components/modal-form';
import { showUpgradeToProModal } from '@components/upgrade-to-pro-modal';
import { ComponentChildren } from 'preact';

function Card({
  title,
  icon,
  href,
  onClick,
}: {
  title: string;
  icon: ComponentChildren;
  href?: string;
  onClick?: () => void;
}) {
  return (
    <Button
      class="px-3 py-4 gap-2 items-center flex border rounded-lg  xl:rounded-lg cursor-pointer group hover:bg-violet-50 bg-white shadow-sm text-gray-700"
      href={href}
      onClick={onClick}
    >
      {icon}
      <p class="font-medium text-xs">{title}</p>
    </Button>
  );
}

export function Resources() {
  return (
    <div class="bg-gradient-to-b from-gray-100 to-indigo-50 p-4 sm:p-6 rounded-2xl">
      <p class="text-black text-md xl:text-lg font-bold mb-4 sm:mb-6">Resources</p>
      <div class="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <Card
          title="Get started with Ruzuku"
          href="https://support.ruzuku.com/article/837-getting-started-with-ruzuku-guide-version"
          icon={<IcoPlay class="size-6 text-indigo-600" />}
        />
        <Card
          title="5 Steps to Your Online Course"
          href="https://courses.ruzuku.com/courses/5-steps-to-your-online-course--88ae8e35-6b17-465d-98eb-f65fb56c3252/salespage"
          icon={<span class="text-lg text-sky-600">🚀</span>}
        />
        <Card
          title="Schedule a 1:1 Call"
          icon={<IcoPhoneOutgoing class="size-6 text-red-600" />}
          onClick={() => {
            showModalForm(({ resolve }) => {
              return (
                <StandardDialog title="Schedule a 1:1 Call" contentWidth onClose={resolve}>
                  <iframe
                    src="https://ruzuku.appointlet.com/s/7360/peter-smiley"
                    width="640"
                    height="1024"
                    frameBorder="0"
                  ></iframe>
                </StandardDialog>
              );
            });
          }}
        />
        <Card
          title="Ruzuku Pro Plan"
          icon={<IcoCheckBadge class="size-6 text-green-600" />}
          onClick={() => showUpgradeToProModal()}
        />
      </div>
    </div>
  );
}
