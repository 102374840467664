import { DateRange } from 'shared/scheduling';
import { time12Formatter, time24Formatter } from './dateutil';
import { Button } from '@components/buttons';

export function TimeSlots({
  hour12,
  availability,
  selected,
  makeHref,
}: {
  hour12: boolean;
  selected?: DateRange;
  availability: DateRange[];
  makeHref(opts: { schedule: DateRange }): string;
}) {
  const fmt = hour12 ? time12Formatter : time24Formatter;
  return (
    <section class="flex flex-col gap-2">
      {!availability.length && <p>No times available.</p>}
      {availability.map((x) => {
        return (
          <Button
            key={x.start}
            href={makeHref({ schedule: x })}
            class={`text-center p-2 rounded hover:bg-indigo-600 hover:text-white border border-gray-200 transition-all ${
              x.start.getTime() === selected?.start.getTime()
                ? 'bg-indigo-600 border-indigo-600 text-white'
                : 'text-inherit'
            }`}
          >
            {fmt.format(x.start)}
          </Button>
        );
      })}
    </section>
  );
}
