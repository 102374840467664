import { router } from '@components/router';
import { GuideRootPage, loadRootGuidePage } from '@components/guide-root-page';
import { GuideStats } from './guide-stats';
import { RecentActivities } from './activities-feed';
import { LoadedProps } from 'client/lib/loaders';
import { AppRoute } from 'client/lib/app-route/types';
import { rpx } from 'client/lib/rpx-client';

type Props = LoadedProps<typeof load>;

function Page(props: Props) {
  return (
    <GuideRootPage data={props.data} activeTab="overview">
      <GuideStats data={props.data.stats} />
      <RecentActivities courses={props.data.all} />
    </GuideRootPage>
  );
}

async function load(route: AppRoute) {
  const [data, stats] = await Promise.all([
    loadRootGuidePage(route),
    rpx.guideOverview.getGuideStats(),
  ]);
  return {
    ...data,
    stats,
  };
}

router.add({
  url: '/overview',
  render: Page,
  load,
  authLevel: 'guide',
});
