import { RouteProps, router } from '@components/router';
import { AppRoute } from 'client/lib/app-route/types';
import { rpx } from 'client/lib/rpx-client';
import { PageTitle } from '@components/headings';
import { GuideProductPage } from '@components/guide-course-page';
import { FormGroup } from '@components/async-form';
import { NewSignupsNotificationSettings } from '../guide-course-settings/notification-settings';
import { useMemo } from 'preact/hooks';
import { serialAsync } from 'client/utils/serial-async';
import { showError } from '@components/app-error';
import { debounce } from 'client/utils/debounce';
import { CourseProfileFieldsForm } from '@components/manage-course-profile-fields';

async function load(route: AppRoute) {
  const { courseId } = route.params;
  const [product, settings] = await Promise.all([
    rpx.courses.getGuideCourse({ id: courseId }),
    rpx.courseSettings.getNotificationsSettings({ courseId }),
  ]);
  return {
    product,
    subscription: settings.enrollmentEmailSubscription,
  };
}

function Page(props: RouteProps<Awaited<ReturnType<typeof load>>>) {
  const { product, subscription } = props.state;

  const saveSubscription = useMemo(
    () =>
      serialAsync(async (subscription: typeof props.state.subscription) => {
        try {
          props.setState((s) => ({ ...s, subscription }));
          await rpx.courseSettings.setNotificationSettings({
            subscription,
            courseId: product.id,
            sendOrientationEmail: false,
            receiveAssessmentEmails: false,
          });
        } catch (err) {
          showError(err);
        }
      }),
    [],
  );

  const saveMaxMemberships = useMemo(
    () =>
      debounce(
        serialAsync(async (maxMemberships: number) => {
          try {
            await rpx.courseSettings.setMaxMemberships({
              courseId: product.id,
              maxMemberships,
            });
          } catch (err) {
            showError(err);
          }
        }),
      ),
    [],
  );

  const setMaxMemberships = (maxMemberships: string) => {
    const value = maxMemberships === '' ? 0 : parseInt(maxMemberships, 10);
    if (!isNaN(value)) {
      props.setState((s) => ({ ...s, product: { ...s.product, maxMemberships: value } }));
      saveMaxMemberships(value);
    }
  };

  return (
    <GuideProductPage page="settings" product={product}>
      <section class="px-4 p-8 md:p-20 md:px-8 flex flex-col gap-4 w-4xl max-w-full mx-auto divide-y">
        <PageTitle>Settings</PageTitle>
        <FormGroup prop="maxMemberships" class="py-6">
          <label class="grid sm:grid-cols-2 gap-8">
            <span class="flex flex-col">
              <strong>Limit</strong>
              <span class="text-gray-500">
                Limit the number of purchases. Leave blank to allow unlimited purchases.
              </span>
            </span>
            <span class="flex items-center">
              <input
                class="inline-ruz-input w-full"
                type="text"
                name="maxMemberships"
                placeholder="Unlimited"
                value={product.maxMemberships}
                onInput={(e: any) => setMaxMemberships(e.target.value)}
              />
            </span>
          </label>
        </FormGroup>
        <FormGroup prop="maxMemberships" class="pt-6">
          <div class="grid sm:grid-cols-2 gap-8">
            <span class="flex flex-col">
              <strong>New signup notifications</strong>
              <span class="text-gray-500">Get notified when someone signs up.</span>
            </span>
            <section class="flex flex-col gap-2">
              <NewSignupsNotificationSettings
                subscription={subscription}
                setSubscription={saveSubscription}
              />
            </section>
          </div>
        </FormGroup>

        <FormGroup prop="maxMemberships" class="pt-6">
          <div class="grid sm:grid-cols-2 gap-8">
            <span class="flex flex-col">
              <strong>Profile fields</strong>
              <span class="text-gray-500">
                Select profile fields would you like your customers to fill out.
              </span>
            </span>
            <section class="border rounded-md p-4 flex flex-col gap-4">
              <strong>Available fields</strong>
              <CourseProfileFieldsForm courseId={product.id} />
            </section>
          </div>
        </FormGroup>
      </section>
    </GuideProductPage>
  );
}

router.add({
  url: 'manage/products/:courseId/settings',
  load,
  render: Page,
  authLevel: 'guide',
});
