import { Draggable, DraggableProvider, reorderItems } from '@components/draggable';
import {
  IcoCheck,
  IcoCheckCircle,
  IcoDuplicate,
  IcoGripperHorizontal,
  IcoTrash,
} from '@components/icons';
import { Toggle } from '@components/toggle';
import { ChoiceItem } from './choice-item';
import { useState } from 'preact/hooks';
import { AssessmentQuestion } from './index';
import { generateUUID } from 'shared/utils';
import { QuestionEditor } from '../assessment-question';
import { BtnPrimary, Button } from '@components/buttons';
import { Attachments } from '@components/attachments';
import { FileRec } from 'server/types';

interface Props {
  question: AssessmentQuestion;
  isQuiz?: boolean;
  canDelete: boolean;
  onChange: (newQuestion: AssessmentQuestion) => void;
  onDelete: () => void;
  onDuplicate: () => void;
}

/**
 * createChoice creates a new question choice for the specified question.
 */
export function createChoice({
  questionId,
  content,
  isCorrect,
  isPlaceholder,
}: {
  questionId: UUID;
  content: string;
  isCorrect?: boolean;
  isPlaceholder?: boolean;
}) {
  return {
    id: generateUUID(),
    content,
    questionId,
    isPlaceholder,
    isCorrect: !!isCorrect,
  };
}

function FeedbackButton({
  title,
  value,
  file,
  placeholder,
  onChange,
}: {
  title: string;
  value?: string;
  file?: FileRec;
  placeholder: string;
  onChange: (question: Partial<AssessmentQuestion>) => void;
}) {
  return (
    <div class="bg-gray-50 rounded p-4 mt-6">
      <span class="font-bold">{title}</span>
      <QuestionEditor
        question={{
          content: value || '',
          file,
        }}
        placeholder={placeholder}
        focusSelf={false}
        onChange={onChange}
      />
    </div>
  );
}

function ChooseQuizAnswers({
  question,
  toggleChoice,
  onChange,
  onDone,
}: {
  question: AssessmentQuestion;
  onChange(question: Partial<AssessmentQuestion>): void;
  toggleChoice: (choiceId: UUID) => void;
  onDone: () => void;
}) {
  const activeChoices = question.choices.filter((c) => !c.deleted);

  return (
    <div class="p-1 rounded mb-4 px-6 border-indigo-600">
      <div class="absolute w-full flex justify-center text-gray-400 cursor-move" draggable>
        <IcoGripperHorizontal class="w-5 h-5" />
      </div>
      <div class="mt-6 mb-4 px-2">
        <div class="flex items-center">
          <span class="p-2 pr-16 w-full">{question.content}</span>
          <div class="flex items-center text-xs">
            <input
              class="mr-2 inline-ruz-input w-14 appearance-none text-center"
              type="number"
              value={question.points || 0}
              min="0"
              onInput={(e: any) => onChange({ points: parseInt(e.target.value, 10) })}
            />{' '}
            points
          </div>
        </div>
        {question.file && (
          <div class="bg-gray-50 rounded p-2 relative">
            <Attachments attachments={[question.file]} />
          </div>
        )}
      </div>
      <div class="px-2">
        <div class="flex items-center ml-1 mb-4">
          <IcoCheckCircle class="w-6 h-6 opacity-75 mr-2" /> <span>Choose correct answers</span>
        </div>
        {activeChoices.map((choice) => {
          // Do not render the placeholder choice
          if (choice.isPlaceholder) {
            return null;
          }
          const selected = choice.isCorrect;

          return (
            <div
              key={choice.id}
              class={`flex flex-col justify-center mb-2 p-0.5 cursor-pointer rounded relative ${
                selected ? 'bg-green-100' : ''
              }`}
              onClick={() => toggleChoice(choice.id)}
            >
              <div class="flex items-center py-1">
                <input
                  type={question.isMultipleChoice ? 'checkbox' : 'radio'}
                  class="ml-1 mr-2 h-5 w-5 border-gray-500"
                  checked={selected}
                  tabIndex={-1}
                />
                <span class="px-3 text-gray-700 font-normal sm:text-sm">{choice.content}</span>
              </div>
              {choice.file && (
                <div class="flex justify-center items-center h-60 w-60 max-h-60 mt-4 ml-8 bg-gray-50 rounded p-2 relative">
                  <Attachments attachments={[choice.file]} />
                </div>
              )}
              {selected && (
                <div class="flex items-center absolute inset-y-0 right-4">
                  <IcoCheck class="w-6 h-6 text-green-900" />
                </div>
              )}
            </div>
          );
        })}
        <FeedbackButton
          value={question.correctFeedback}
          file={question.correctFeedbackFile}
          title="Feedback for correct answers"
          placeholder="Add feedback for correct answers (optional)"
          onChange={(updates) => {
            // Update object contains either `content` or `file`,
            // so we only want to update the correct field.
            if (updates.content !== undefined) {
              onChange({
                correctFeedback: updates.content,
              });
            } else {
              onChange({
                correctFeedbackFile: updates.file,
              });
            }
          }}
        />
        <FeedbackButton
          value={question.incorrectFeedback}
          file={question.incorrectFeedbackFile}
          title="Feedback for incorrect answers"
          placeholder="Add feedback for incorrect answers (optional)"
          onChange={(updates) => {
            if (updates.content !== undefined) {
              onChange({
                incorrectFeedback: updates.content,
              });
            } else {
              onChange({
                incorrectFeedbackFile: updates.file,
              });
            }
          }}
        />
      </div>
      <footer class="flex justify-end border-t mt-4 p-4">
        <BtnPrimary onClick={onDone}>Done</BtnPrimary>
      </footer>
    </div>
  );
}

export function QuestionItem({
  question,
  isQuiz,
  canDelete,
  onChange,
  onDelete,
  onDuplicate,
}: Props) {
  const { choices } = question;
  const activeChoices = choices.filter((c) => !c.deleted);
  const [focusedChoice, setFocusedChoice] = useState<UUID | undefined>(undefined);
  const [isAnswerMode, setIsAnswerMode] = useState(false);

  function updateQuestion(newQuestion: Partial<AssessmentQuestion>) {
    onChange({
      ...question,
      ...newQuestion,
    });
  }

  function toggleChoice(id: UUID) {
    const newChoices = question.choices.map((x) => {
      let isCorrect = x.isCorrect;
      if (x.id === id) {
        isCorrect = !x.isCorrect;
      } else if (!question.isMultipleChoice) {
        // If question is not multiple choice,
        // then mark all other choices as incorrect
        isCorrect = false;
      }

      return {
        ...x,
        isCorrect,
      };
    });
    onChange({ ...question, choices: newChoices });
  }

  function addNewChoice(index?: number) {
    const newItem = createChoice({
      questionId: question.id,
      content: `Option ${index || 0}`,
    });
    const newChoices = index
      ? [
          // part of the array before the specified index
          ...choices.slice(0, index),
          // inserted item
          newItem,
          // part of the array after the specified index
          ...choices.slice(index),
        ]
      : [...choices, newItem];

    setFocusedChoice(newItem.id);
    updateQuestion({
      choices: newChoices,
    });
  }

  if (isAnswerMode) {
    return (
      <ChooseQuizAnswers
        question={question}
        toggleChoice={toggleChoice}
        onChange={updateQuestion}
        onDone={() => setIsAnswerMode(false)}
      />
    );
  }

  return (
    <div class="border p-1 rounded-md mb-4 px-6 shadow-md">
      <div class="absolute w-full flex justify-center text-gray-400 cursor-move" draggable>
        <IcoGripperHorizontal class="w-5 h-5" />
      </div>
      <QuestionEditor question={question} onChange={(updates) => updateQuestion(updates)} />
      <div class="-ml-4">
        <DraggableProvider
          canHandleDrop={(_, table) => table === `question_${question.id}_choices`}
          onDragComplete={() => {}}
          onTargetChange={(dragState) =>
            onChange({
              ...question,
              choices: reorderItems(choices, dragState),
            })
          }
        >
          {activeChoices.map((choice, index) => {
            const { isPlaceholder } = choice;
            return (
              <Draggable
                key={choice.id}
                id={choice.id}
                // Prevent dragging of the placeholder choice
                table={isPlaceholder ? 'do_not_drag' : `question_${question.id}_choices`}
              >
                <ChoiceItem
                  choice={choice}
                  type={question.isMultipleChoice ? 'checkbox' : 'radio'}
                  isQuiz={isQuiz}
                  autoFocus={choice.id === focusedChoice}
                  draggable={!isPlaceholder}
                  // Prevent deleting if there are less than 1 none placeholder choice
                  canDelete={!isPlaceholder && activeChoices.length > 2}
                  placeholder={isPlaceholder ? 'Add option' : ''}
                  toggleChoice={isPlaceholder ? undefined : toggleChoice}
                  onChange={(updates) => {
                    const newChoices = choices.map((x) =>
                      x.id === choice.id ? { ...x, ...updates, isPlaceholder: false } : x,
                    );
                    // The user has edited the trailing option, which is
                    // means we need to add a new trailing option so we
                    // ensure there's always an empty trailing option.
                    if (isPlaceholder) {
                      newChoices.push(
                        createChoice({
                          questionId: question.id,
                          content: '',
                          isPlaceholder: true,
                        }),
                      );
                    }
                    updateQuestion({
                      choices: newChoices,
                    });
                  }}
                  onDelete={() => {
                    // Focus on the choice that comes before the deleted one
                    setFocusedChoice(choices[index - 1]?.id);
                    const newChoices = choices.map((x) =>
                      x.id === choice.id ? { ...x, deleted: true } : x,
                    );
                    updateQuestion({
                      choices: newChoices,
                    });
                  }}
                  onEnter={() => {
                    addNewChoice(index + 1);
                  }}
                />
              </Draggable>
            );
          })}
        </DraggableProvider>
      </div>
      <footer class="flex justify-between border-t mt-4 pt-4 pb-2">
        <div class="flex items-center text-indigo-500">
          {isQuiz && (
            <Button
              class="inline-flex items-center hover:bg-indigo-100 p-2 rounded"
              onClick={() => setIsAnswerMode(true)}
            >
              <IcoCheckCircle class="w-5 h-5 mr-2" />
              Choose Answers ({question.points || 0} points)
            </Button>
          )}
        </div>
        <div class="flex items-center divide-x-2 space-x-6">
          <div class="flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-6">
            {!isQuiz && (
              <label>
                <Toggle
                  checked={question.isRequired}
                  onClick={() => {
                    updateQuestion({
                      isRequired: !question.isRequired,
                    });
                  }}
                />
                <span class="ml-2 text-xs">Required</span>
              </label>
            )}
            <label>
              <Toggle
                checked={question.isMultipleChoice}
                onClick={() => {
                  updateQuestion({
                    isMultipleChoice: !question.isMultipleChoice,
                  });
                }}
              />
              <span class="ml-2 text-xs">Allow Multiple Answers</span>
            </label>
          </div>
          <div class="pl-4">
            <Button
              class="p-2 mr-2 text-gray-500 hover:bg-indigo-500 hover:text-white focus:ring-2 focus:ring-indigo-500 outline-none rounded"
              onClick={onDuplicate}
              title="Duplicate"
            >
              <IcoDuplicate />
            </Button>
            <Button
              class="p-2 -mr-2 text-gray-500 hover:bg-red-500 hover:text-white focus:ring-2 focus:ring-red-500 outline-none rounded"
              disabled={!canDelete}
              onClick={onDelete}
              title="Delete"
            >
              <IcoTrash />
            </Button>
          </div>
        </div>
      </footer>
    </div>
  );
}
