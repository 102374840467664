import { RouteProps, router } from '@components/router';
import { useCurrentUser } from 'client/lib/auth';
import { AvailableBlock, Props as AvailableProps } from './availability-summary';
import { RuzcalMgmtPage } from './mgmt-page';
import { ListAddAction, ListContainer, PageContent, PageHeading, PageSection } from './common';
import { rpx } from 'client/lib/rpx-client';
import dayjs from 'dayjs';

async function load() {
  const [availability, overrides] = await Promise.all([
    rpx.ruzcal.getAvailabilityList(),
    rpx.ruzcal.getOverridesList(),
  ]);
  return { availability, overrides };
}

type Data = Awaited<ReturnType<typeof load>>;

function EditAvailabilityLink(props: AvailableProps & { id: string }) {
  return (
    <a
      href={`/ruzcal-mgmt/availability/${props.id}`}
      class="block p-3 hover:bg-gray-100 transition-all text-inherit rounded-md"
    >
      <AvailableBlock {...props} />
    </a>
  );
}

function Page(props: RouteProps<Data>) {
  const user = useCurrentUser()!;
  const { availability, overrides } = props.state;
  return (
    <RuzcalMgmtPage title="Availability" currentPage="availability">
      <PageContent>
        <PageSection>
          <PageHeading
            title="Manage your availability"
            subtitle="Define blocks of time when you're available for booking."
            action={
              <ListAddAction href="/ruzcal-mgmt/availability/new" content="Add availability" />
            }
          />
          {availability.length > 0 && (
            <ListContainer>
              {availability.map((x) => (
                <EditAvailabilityLink
                  key={x.id}
                  id={x.id}
                  label={x.name}
                  isDefault={x.isDefault}
                  renderTimeZone={user.timezone}
                  scheduleTimeZone={user.timezone}
                  timeslots={x.timeslots}
                />
              ))}
            </ListContainer>
          )}
        </PageSection>
        <PageSection>
          <PageHeading
            title="Date overrides"
            subtitle="Remove specific dates and times from your public availability."
            action={<ListAddAction href="/ruzcal-mgmt/overrides/new" content="Add override" />}
          />
          {overrides.length > 0 && (
            <ListContainer>
              {overrides.map((x) => (
                <a
                  key={x.id}
                  href={`/ruzcal-mgmt/overrides/${x.id}`}
                  class="block p-3 hover:bg-gray-100 transition-all text-inherit rounded-md"
                >
                  <span>{dayjs(x.start).tz(user.timezone).format('MMM DD, YYYY hh:mma')}</span>
                  {' - '}
                  <span>{dayjs(x.end).tz(user.timezone).format('MMM DD, YYYY hh:mma')}</span>
                </a>
              ))}
            </ListContainer>
          )}
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}

router.add({
  url: 'ruzcal-mgmt/overrides',
  authLevel: 'superadmin',
  load,
  render: Page,
});

router.add({
  url: 'ruzcal-mgmt/availability',
  authLevel: 'superadmin',
  load,
  render: Page,
});
