import { UserProfileIcon } from '@components/avatars';
import {
  IcoArrowRight,
  IcoCalendar,
  IcoDocument,
  IcoMapPin,
  IcoVideoCamera,
} from '@components/icons';
import { router } from '@components/router';
import { AppRoute } from 'client/lib/app-route/types';
import { LoadedProps } from 'client/lib/loaders';
import { rpx } from 'client/lib/rpx-client';
import { useDocumentTitle } from 'client/utils/use-document-title';
import { BulletIco } from './bullet-ico';
import { ScheduleSummary } from './schedule-summary';
import { BtnPrimary } from '@components/buttons';
import { useCurrentUser } from 'client/lib/auth';
import { isNowish } from './dateutil';

async function load(props: AppRoute) {
  const result = await rpx.ruzcal.getEvent({
    eventId: props.params.eventId,
    token: props.params.token,
  });
  return {
    ...result,
    isSuccess: !!props.params.success,
    event: {
      ...result.event,
      start: new Date(result.event.start),
      end: new Date(result.event.end),
    },
  };
}

type Props = LoadedProps<typeof load>;
type State = Props['state'];

function jitsiUrl({
  eventType,
  event,
  attendeeName,
}: {
  eventType: State['eventType'];
  event: State['event'];
  attendeeName: string;
}) {
  return `https://meet.jit.si/${eventType.urlSuffix}-${
    event.id
  }#userInfo.displayName="${encodeURIComponent(attendeeName)}"&config.subject="${encodeURIComponent(
    eventType.name,
  )}"&config.prejoinConfig.enabled=false`;
}

function MeetingUser({
  user,
  isHost,
}: {
  user: { name: string; email?: string };
  isHost?: boolean;
}) {
  return (
    <li class="flex items-center gap-4">
      <UserProfileIcon user={user} size="size-10" />
      <span class="flex flex-col">
        <span>
          {user.name}
          {isHost && (
            <span class="bg-blue-500 text-white px-1 p-0.5 text-xs font-semibold rounded ml-2">
              Host
            </span>
          )}
        </span>
        {user.email && (
          <a class="text-inherit" href={`mailto:${encodeURIComponent(user.email)}`}>
            {user.email}
          </a>
        )}
      </span>
    </li>
  );
}

/**
 * Matches URLs and emails and turns them into links.
 */
function Linkify(props: { text: string }) {
  const regex = /(https:\/\/[^\s]+)|(mailto:[^\s]+)/gi;
  const strs = props.text.split(regex);
  return (
    <>
      {strs.map((s, i) => {
        const isUrl = s?.startsWith('https://') || s?.startsWith('mailto:');
        return (
          <span key={i}>
            {isUrl && (
              <a href={s} target="_blank" rel="noreferrer" class="text-indigo-600 underline">
                {s}
              </a>
            )}
            {!isUrl && s}
          </span>
        );
      })}
    </>
  );
}

export function Page(props: Props) {
  const user = useCurrentUser();
  const { eventType, event, host } = props.state;
  const isHost = user?.id === host.hostId;
  const [attendee] = event.attendees;

  useDocumentTitle(['Meeting scheduled!']);

  return (
    <div class="p-2 flex items-center justify-center bg-gray-100 min-h-screen an-scale-in">
      <section class="p-8 bg-white rounded-2xl max-w-full w-2xl flex flex-col gap-6">
        <header>
          <h1 class="text-2xl font-semibold">
            {props.state.isSuccess ? 'Meeting scheduled!' : 'Meeting details'}
          </h1>
          {props.state.isSuccess && (
            <p class="text-gray-500">
              We sent a calendar invitation and meeting details to <em>{attendee.email}</em>.
            </p>
          )}
          {!props.state.isSuccess && eventType.description && (
            <p class="text-gray-500">{eventType.description}</p>
          )}
        </header>
        <section class="flex flex-col border-y py-6 gap-8">
          <div class="flex flex-col gap-4">
            <h2 class="font-semibold text-lg text-gray-700">{eventType.name}</h2>
            <BulletIco Ico={IcoCalendar} multiline>
              <ScheduleSummary schedule={event} hour12 />
            </BulletIco>
            {eventType.location === 'jitsi' && <BulletIco Ico={IcoVideoCamera}>Jitsi</BulletIco>}
            {attendee.notes && (
              <BulletIco Ico={IcoDocument} multiline>
                <span class="whitespace-pre-wrap">{attendee.notes}</span>
              </BulletIco>
            )}
            {eventType.location === 'external' && eventType.locationDetail && (
              <BulletIco Ico={IcoMapPin} multiline>
                <span class="whitespace-pre-wrap">
                  <Linkify text={eventType.locationDetail.external} />
                </span>
              </BulletIco>
            )}
          </div>
          <ul class="leading-snug flex flex-col gap-6 col-span-3">
            <MeetingUser user={host} isHost />
            <MeetingUser user={attendee} />
          </ul>
          {eventType.location === 'jitsi' && (isHost || isNowish(event)) && (
            <footer>
              <BtnPrimary
                class="rounded-full p-2 px-6 text-base"
                href={jitsiUrl({ eventType, event, attendeeName: user?.name ?? attendee.name })}
              >
                <span class="flex items-center gap-2">
                  {isHost ? `Launch meeting` : `Join meeting`}
                  <IcoArrowRight />
                </span>
              </BtnPrimary>
            </footer>
          )}
        </section>
        <footer>
          You can{' '}
          <a href="/ruzcal" class="text-indigo-600 underline">
            reschedule
          </a>{' '}
          or{' '}
          <button type="button" class="text-indigo-600 underline">
            cancel
          </button>{' '}
          any time before the meeting starts.
        </footer>
      </section>
    </div>
  );
}

router.add({
  isPublic: true,
  url: 'cal-bookings/:eventId',
  load,
  render: Page,
});
