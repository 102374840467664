import { showError } from '@components/app-error';
import { AsyncForm, FormGroup } from '@components/async-form';
import { BtnPrimary, Button } from '@components/buttons';
import { HeadingSecondary } from '@components/headings';
import { PreviewModal } from '@components/messages';
import { useCurrentTenant, useCurrentUser } from '@components/router/session-context';
import { DefaultSpinner } from '@components/spinner';
import { showToast } from '@components/toaster';
import { Toggle } from '@components/toggle';
import { rpx } from 'client/lib/rpx-client';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { JSX } from 'preact';
import { useState } from 'preact/hooks';
import { CourseEnrollmentEmailSubscription, Email, FullCourse } from 'server/types';

const store = rpx.courseSettings;

function Radio({
  title,
  desc,
  example,
  ...props
}: { title: string; desc: string; example?: string } & JSX.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <label
      class={`p-4 pr-8 border flex items-start rounded-md text-sm cursor-pointer ${
        props.checked ? 'ring-1 ring-indigo-600 border-indigo-600' : ''
      }`}
    >
      <input
        type="radio"
        name="accessFormat"
        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mr-4 mt-1"
        {...props}
      />
      <span class="flex flex-col">
        <span class="font-bold">{title}</span>
        <span class="text-gray-500">{desc}</span>
      </span>
    </label>
  );
}

export function NewSignupsNotificationSettings(props: {
  subscription: CourseEnrollmentEmailSubscription;
  setSubscription(x: CourseEnrollmentEmailSubscription): void;
}) {
  return (
    <>
      <Radio
        title="Daily"
        desc="Emails sent once per day, as a summary"
        checked={props.subscription === 'daily'}
        value="daily"
        onClick={() => props.setSubscription('daily')}
      />
      <Radio
        title="Immediate"
        desc="Emails sent immediately when someone signups"
        checked={props.subscription === 'immediate'}
        value="immediate"
        onClick={() => props.setSubscription('immediate')}
      />
      <Radio
        title="None"
        desc="No emails will be sent for new signups"
        checked={props.subscription === 'off'}
        value="off"
        onClick={() => props.setSubscription('off')}
      />
    </>
  );
}

/**
 * showCourseTitleModal displays a modal for renaming the course.
 */
export function NotificationSettings({ course }: { course: Pick<FullCourse, 'id' | 'title'> }) {
  const tenant = useCurrentTenant();
  const user = useCurrentUser();
  const [subscription, setSubscription] = useState<CourseEnrollmentEmailSubscription | undefined>();
  const [sendOrientationEmail, setSendOrientationEmail] = useState(true);
  const [receiveAssessmentEmails, setReceiveAssessmentEmails] = useState(true);
  const [previewEmail, setPreviewEmail] = useState<Email | undefined>(undefined);
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  useAsyncEffect(async () => {
    const result = await store.getNotificationsSettings({ courseId: course.id });
    setSubscription(result.enrollmentEmailSubscription);
    setSendOrientationEmail(result.sendOrientationEmail);
    setReceiveAssessmentEmails(result.receiveAssessmentNotifications);
  }, [course.id]);

  async function changeSubscription() {
    // this must be in 'daily' | 'immediate' | 'off';
    if (!subscription) {
      return;
    }

    await store.setNotificationSettings({
      courseId: course.id,
      subscription,
      sendOrientationEmail,
      receiveAssessmentEmails,
    });
    showToast({
      title: 'Success',
      message: `Notification settings for ${course.title} are saved.`,
      type: 'ok',
    });
  }

  async function displayWelcomePreview() {
    setIsSendingEmail(true);
    try {
      const email = await store.getWelcomeEmailPreview({ courseId: course.id });
      setPreviewEmail(email);
    } catch (err) {
      showError(err);
    } finally {
      setIsSendingEmail(false);
    }
  }

  async function sendWelcomePreview() {
    setIsSendingEmail(true);
    try {
      await store.sendWelcomeEmailPreview({ courseId: course.id });
      showToast({
        type: 'ok',
        title: 'Preview sent',
        message: `Preview email sent successfully.`,
      });
      setPreviewEmail(undefined);
    } catch (err) {
      showError(err);
    } finally {
      setIsSendingEmail(false);
    }
  }

  if (!subscription) {
    return <DefaultSpinner />;
  }

  return (
    <AsyncForm class="w-full flex flex-col gap-6" onSubmit={changeSubscription}>
      {previewEmail && (
        <PreviewModal
          email={previewEmail}
          recipientsText={user?.email || ''}
          isSending={isSendingEmail}
          onCancel={() => setPreviewEmail(undefined)}
          onSend={sendWelcomePreview}
        />
      )}
      <HeadingSecondary>Notification Settings</HeadingSecondary>

      {tenant.isCore && (
        <div class="flex pb-6 border-b">
          <Toggle
            class="mt-1"
            name="displayPeople"
            checked={sendOrientationEmail}
            onClick={() => setSendOrientationEmail(!sendOrientationEmail)}
          />
          <span class="flex flex-col ml-4">
            <span class="text-gray-900 dark:text-gray-200">
              Send orientation email to new students
              <Button
                class="underline ml-2 text-indigo-500"
                type="button"
                onClick={displayWelcomePreview}
              >
                Preview email
              </Button>
            </span>
            <span class="text-gray-500 dark:text-gray-400 mt-1">
              We send an email to new students on Ruzuku that help students get oriented on the
              platform.
            </span>
          </span>
        </div>
      )}

      <div class="flex pb-6 border-b">
        <Toggle
          class="mt-1"
          name="displayPeople"
          checked={receiveAssessmentEmails}
          onClick={() => setReceiveAssessmentEmails(!receiveAssessmentEmails)}
        />
        <span class="flex flex-col ml-4">
          <span class="text-gray-900 dark:text-gray-200">
            Receive notifications for quiz and poll responses
          </span>
          <span class="text-gray-500 dark:text-gray-400 mt-1">
            Get an email notification when a student completes a quiz or poll
          </span>
        </span>
      </div>
      <FormGroup class="flex flex-col gap-4" prop="subscription">
        <strong class="text-gray-600">Get emailed about new signups</strong>
        <NewSignupsNotificationSettings
          subscription={subscription}
          setSubscription={setSubscription}
        />
      </FormGroup>
      <BtnPrimary>Save</BtnPrimary>
    </AsyncForm>
  );
}
