/**
 * The root upsells page.
 */
import { router } from '@components/router';
import { LoadedProps } from 'client/lib/loaders';
import { UpsellsPage } from './upsells-page';
import { EmptyPage } from '@components/empty-page';
import { IcoArrowRight, IcoCreditCard, IcoInfo, IcoPlus } from '@components/icons';
import { BtnPrimary, Button } from '@components/buttons';
import { fmtFullDate } from 'shared/dateutil';
import { pluralize } from 'shared/formatting';
import { useCurrentTenant, useCurrentUser } from 'client/lib/auth';
import { rpx } from 'client/lib/rpx-client';
import { MAX_CORE_UPSELLS } from 'shared/consts';
import { partition } from 'shared/utils';
import { showDialog } from '@components/dialog';

async function load() {
  const [upsells, integrations] = await Promise.all([
    rpx.upsells.getMyUpsells(),
    rpx.upsells.getMyProviders(),
  ]);
  return { upsells, integrations };
}

type Props = LoadedProps<typeof load>;
type Upsell = Props['data']['upsells'][0];

const newPageHref = 'upsells/new';

function WarningNote({ upsell }: { upsell: Upsell }) {
  const { terminology } = useCurrentTenant();
  const message = upsell.isArchived
    ? 'Archived & signups closed'
    : !upsell.isEnabled
    ? 'Signups closed'
    : !upsell.numOffers
    ? 'No offers'
    : !upsell.numAppliedPrices
    ? `Not applied to any ${terminology.course} or product`
    : '';
  if (!message) {
    return null;
  }
  const colors = upsell.isArchived
    ? 'bg-gray-50'
    : 'bg-yellow-50 border-yellow-200 text-yellow-700';
  return (
    <span>
      <span
        class={`inline-flex gap-1 items-center border pl-1 pr-2 rounded-md mb-2 text-xs ${colors}`}
      >
        {message}
      </span>
    </span>
  );
}

function UpgradePage() {
  return (
    <UpsellsPage>
      <EmptyPage
        Ico={IcoCreditCard}
        title="Upsells"
        centeredDescription
        description={
          <>
            <p>
              Increase your revenue by promoting special deals to your students during checkout.
            </p>
            <a
              class="flex items-center text-left gap-3 bg-yellow-50 rounded-md mt-4 p-4 text-yellow-700 border border-yellow-200"
              href="/account/billing?coreBilling=true"
            >
              <IcoInfo class="shrink-0 size-6 opacity-75" />
              Upsells are available for Core or Pro accounts.
            </a>
          </>
        }
        actionText="Upgrade to Core or Pro"
        href="/account/billing?coreBilling=true"
      />
    </UpsellsPage>
  );
}

function StripePage() {
  return (
    <UpsellsPage>
      <EmptyPage
        Ico={IcoCreditCard}
        title="Upsells"
        centeredDescription
        description={
          <>
            <p>
              Increase your revenue by promoting special deals to your students during checkout.
            </p>
            <a
              class="flex items-center text-left gap-3 bg-yellow-50 rounded-md mt-4 p-4 text-yellow-700 border border-yellow-200"
              href="/account/integrations"
            >
              <IcoInfo class="shrink-0 size-6 opacity-75" />
              Upsells require a Stripe account.
            </a>
          </>
        }
        actionText="Add a Stripe account"
        href="/account/integrations"
      />
    </UpsellsPage>
  );
}

function EmptyUpsellPage() {
  const { terminology } = useCurrentTenant();
  return (
    <UpsellsPage>
      <EmptyPage
        Ico={IcoCreditCard}
        title="Upsells"
        centeredDescription
        description={
          <>
            Increase your revenue by promoting {terminology.courses}, products, and special deals to
            your students during checkout.
          </>
        }
        actionText="Create a new upsell"
        href={newPageHref}
      />
    </UpsellsPage>
  );
}

function UpsellCards(props: { upsells: Props['state']['upsells'] }) {
  return (
    <>
      {props.upsells.map((upsell) => (
        <Button
          key={upsell.id}
          class="flex flex-col gap-4 p-6 pb-4 border rounded-2xl text-left hover:bg-gray-50 text-inherit"
          href={`/upsells/${upsell.id}`}
        >
          <header class="flex flex-col">
            <WarningNote upsell={upsell} />
            <span class="font-semibold text-xl">{upsell.title}</span>
            <span class="text-gray-600 text-xs">Created {fmtFullDate(upsell.createdAt)}</span>
          </header>
          <span class="flex justify-between items-center border-t pt-4">
            <span class="text-xs flex gap-1 items-center">
              <b>{upsell.numOffers}</b> {pluralize('offer', upsell.numOffers)}
            </span>
            <span class="text-xs flex gap-1 items-center">
              <b>{upsell.numAppliedPrices}</b> applied
            </span>
            <span class="text-xs flex gap-1 items-center">
              <b>{upsell.numSignups}</b> signups
            </span>
          </span>
        </Button>
      ))}
    </>
  );
}

function ManageUpsellsPage({ state }: Props) {
  const user = useCurrentUser();
  const isLimited = user?.tier !== 'pro' && state.upsells.length >= MAX_CORE_UPSELLS;
  const upgradeHref = '/account/billing?coreBilling=true';
  const [archived, unarchived] = partition((x) => !!x.isArchived, state.upsells);

  return (
    <UpsellsPage
      buttons={
        state.upsells.length > 0 && (
          <BtnPrimary
            class="gap-2 pr-4 rounded-full"
            href={isLimited ? undefined : newPageHref}
            onClick={
              isLimited
                ? async () => {
                    const ok = await showDialog({
                      title: 'You have reached the maximum number of upsells',
                      children: `Unlock unlimited upsells by upgrading to Ruzuku Pro.`,
                      mode: 'ok',
                      confirmButtonText: 'Upgrade to Ruzuku Pro',
                    });
                    if (ok) {
                      router.goto(upgradeHref);
                    }
                  }
                : undefined
            }
          >
            <IcoPlus />
            Create a new upsell
          </BtnPrimary>
        )
      }
    >
      <section class="flex flex-col gap-10">
        <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-8 items-start">
          {user?.tier !== 'pro' && (
            <Button
              class="flex flex-col gap-4 p-6 pb-4 border rounded-2xl text-left hover:bg-gray-50 text-inherit"
              href={upgradeHref}
            >
              <header class="flex flex-col">
                <span class="font-semibold text-xl">Upgrade to Pro</span>
              </header>
              <span class="text-gray-600">
                You are currently limited to {MAX_CORE_UPSELLS} upsells. Upgrade to Pro to unlock
                unlimited upsells.
              </span>
              <footer class="text-indigo-600 flex items-center gap-2">
                <span>Upgrade to Ruzuku Pro</span>
                <IcoArrowRight />
              </footer>
            </Button>
          )}
          <UpsellCards upsells={unarchived} />
        </div>
        {!!archived.length && (
          <>
            <h2 class="font-semibold">Archived upsells</h2>
            <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-8 items-start">
              <UpsellCards upsells={archived} />
            </div>
          </>
        )}
      </section>
    </UpsellsPage>
  );
}

function Page(props: Props) {
  const user = useCurrentUser();
  const tenant = useCurrentTenant();
  const canUpsell = !tenant.isCore || user?.tier === 'pro' || user?.tier === 'core';

  if (!canUpsell) {
    return <UpgradePage />;
  }

  if (!props.state.integrations.supportsStripe) {
    return <StripePage />;
  }

  if (!props.state.upsells.length) {
    return <EmptyUpsellPage />;
  }

  return <ManageUpsellsPage {...props} />;
}

router.add({ url: 'upsells', render: Page, authLevel: 'guide', load });
