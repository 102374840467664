import { Button } from '@components/buttons';
import { GuideCoursePage } from '@components/guide-course-page';
import { IcoGripperHorizontal, IcoPencil, IcoPlus, IcoTrash } from '@components/icons';
import { RouteProps, router } from '@components/router';
import { AppRoute } from 'client/lib/app-route/types';
import { rpx } from 'client/lib/rpx-client';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { genericDiscussionCategoryIds } from 'shared/consts';
import { URLS } from 'shared/urls';
import { generateUUID } from 'shared/utils';
import { ColorPicker } from '@components/color-picker';
import { Draggable, DraggableProvider, reorderItems } from '@components/draggable';
import { substateUpdater, useUndoRedo } from 'client/lib/hooks';
import { UndoNotice } from '@components/undo-notice';
import { useBasicAutosaver } from '@components/autosaver';
import { showError } from '@components/app-error';
import { useCurrentTenant } from '@components/router/session-context';
import { HeadingPrimary } from '@components/headings';
import { Toggle } from '@components/toggle';

const store = rpx.discussions;

type Data = Awaited<ReturnType<typeof load>>;
type Category = Data['categories'][0] & {
  deleted?: boolean;
  isNew?: boolean;
};

function randomColor() {
  const hex = Math.floor(Math.random() * 16777215).toString(16);
  return `#${hex}`;
}

function Item({
  category,
  onChange,
  onDelete,
}: {
  category: Category;
  onChange: (update: Partial<Category>) => void;
  onDelete: () => void;
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current && category.isNew) {
      inputRef.current.scrollIntoView();
      inputRef.current.select();
    }
  }, [inputRef.current, category.isNew]);

  return (
    <div class="flex items-center justify-between p-4 border relative">
      <div class="absolute top-2 left-1/2 text-gray-400 cursor-move" draggable>
        <IcoGripperHorizontal class="w-5 h-5" />
      </div>
      <div class="flex gap-2">
        <ColorPicker
          name={category.id}
          color={category.color}
          onPick={(toAccentColor) => onChange({ color: toAccentColor })}
        />
        <input
          class="p-1 text-lg leading-6 cursor-pointer rounded border border-transparent focus:outline-none hover:border-gray-300 focus:border-transparent focus:ring-2 focus:ring-indigo-500 w-full"
          ref={inputRef}
          value={category.title}
          placeholder="Category title"
          onKeyDown={(e: any) => e.code === 'Enter' && e.target.blur()}
          onChange={(e: any) => onChange({ title: e.target.value })}
        />
      </div>
      <div class="flex gap-4">
        <Button onClick={() => inputRef.current?.focus()}>
          <IcoPencil class="w-5 h-5 opacity-75" />
        </Button>
        <Button onClick={onDelete}>
          <IcoTrash class="w-5 h-5 opacity-75" />
        </Button>
      </div>
    </div>
  );
}

function Page(props: RouteProps<Data>) {
  const { course } = props.data;
  const { terminology } = useCurrentTenant();
  const [state, setState] = useState<{
    categories: Category[];
    hideLessonDiscussionsCategory: boolean;
  }>({
    categories: props.data.categories,
    hideLessonDiscussionsCategory: props.data.course.hideLessonDiscussionsCategory,
  });
  const { categories } = state;
  const visibleCategories = categories.filter((c) => !c.deleted);
  const [showUndoNotice, setShowUndoNotice] = useState(false);
  const undoRedo = useUndoRedo(state, setState);

  const setCategories = useMemo(
    () =>
      substateUpdater(
        setState,
        (s) => s.categories,
        (s, categories) => ({ ...s, categories }),
      ),
    [setState],
  );

  function updateCategory(id: UUID, update: Partial<Category>) {
    setCategories((categories) => categories.map((c) => (c.id === id ? { ...c, ...update } : c)));
  }

  function createCategory() {
    const newCategory = {
      id: generateUUID(),
      title: 'Untitled Category',
      color: randomColor(),
      isNew: true,
      numDiscussions: 0,
    };
    setCategories((c) => [...c, newCategory]);
  }

  useBasicAutosaver(state, async (val) => {
    try {
      /*
       * This is sending all the categories to the backend
       * for inserts, updates and deletes.
       * This is sub-optimal but t it works well with the undo/redo system.
       */
      const savedCategories = val.categories.map((category, index) => ({
        id: category.id,
        deleted: category.deleted,
        title: category.title,
        color: category.color,
        seq: index,
      }));
      await store.upsertCategories({
        courseId: course.id,
        categories: savedCategories,
        hideLessonDiscussionsCategory: val.hideLessonDiscussionsCategory,
      });
    } catch (err) {
      showError(err);
    }
  });

  return (
    <GuideCoursePage
      course={course}
      viewLink={URLS.student.discussions({
        course,
        categoryId: genericDiscussionCategoryIds.all,
      })}
      type="discussions"
    >
      <div class="flex flex-col w-full max-w-4xl mx-auto p-8 gap-6">
        <HeadingPrimary title="Manage Discussion Categories" />
        <div class="flex p-4">
          <div class="pt-1 relative">
            <Toggle
              checked={!state.hideLessonDiscussionsCategory}
              onClick={() =>
                setState((s) => ({
                  ...s,
                  hideLessonDiscussionsCategory: !s.hideLessonDiscussionsCategory,
                }))
              }
            />
          </div>
          <span class="flex flex-col ml-6 max-w-lg" id="availability-label">
            <span class="text-md font-medium text-gray-900">
              Show {terminology.Lesson} {terminology.Discussions} category
            </span>
            <span class="text-md text-gray-500">
              Toggle this setting to show or hide the "{terminology.Lesson}{' '}
              {terminology.Discussions}" category on your {terminology.Discussions} page.
            </span>
          </span>
        </div>
        <div class="flex flex-col w-full gap-2">
          <DraggableProvider
            canHandleDrop={(_, table) => table === 'discussion-categories'}
            onDragComplete={() => {}}
            onTargetChange={(dragState) => setCategories((s) => reorderItems(s, dragState))}
          >
            {visibleCategories.map((category) => (
              <Draggable key={category.id} id={category.id} table="discussion-categories">
                <Item
                  key={category.id}
                  category={category}
                  onChange={(update) => updateCategory(category.id, update)}
                  onDelete={() => {
                    updateCategory(category.id, { deleted: true });
                    setShowUndoNotice(true);
                  }}
                />
              </Draggable>
            ))}
          </DraggableProvider>
        </div>
        <Button
          class="mt-8 inline-flex items-center justify-center text-indigo-600 outline-none focus:ring-2 focus:ring-indigo-400 rounded"
          onClick={createCategory}
        >
          <IcoPlus class="h-6 w-6 mr-1 opacity-75" />
          Create a new category
        </Button>
      </div>
      <UndoNotice
        displayed={showUndoNotice}
        text="Category deleted."
        onClick={undoRedo.undo}
        hide={() => setShowUndoNotice(false)}
      />
    </GuideCoursePage>
  );
}

async function load(route: AppRoute) {
  const { courseId } = route.params;
  const [course, categoriesData] = await Promise.all([
    rpx.courses.getGuideCourse({ id: courseId }),
    rpx.discussions.getCategories({
      courseId,
    }),
  ]);

  return {
    course,
    categories: categoriesData.categories,
  };
}

router.add({
  url: `manage/courses/:courseId/discussions`,
  load,
  render: Page,
  authLevel: 'guide',
});
