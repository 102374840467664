import { useState } from 'preact/hooks';
import { timeago } from 'shared/dateutil';
import { UserProfileIcon } from '@components/avatars';
import { IcoClock } from '@components/icons';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { URLS } from 'shared/urls';
import { PrimaryOverviewCard } from './overview-card';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { AssignmentStatusPill } from '../guide-course-assessments/assignment-slideover';

type Submission = RpxResponse<typeof rpx.assessments.getAssignmentSubmissions>[0];

interface Props {
  courseId: UUID;
}

export function RecentAssignments({ courseId }: Props) {
  const [submissions, setSubmissions] = useState<Submission[]>([]);

  // Load the first page
  useAsyncEffect(async () => {
    const submissions = await rpx.assessments.getRecentAssignmentSubmissions({
      courseId,
    });
    setSubmissions(submissions);
  }, []);

  if (!submissions.length) {
    return null;
  }

  return (
    <PrimaryOverviewCard
      title="Assignments"
      footerAction={{
        href: URLS.guide.coursePage({
          courseId,
          page: 'assessments',
        }),
      }}
    >
      <div>
        {submissions.map((submission) => (
          <a
            key={`${submission.student.id}-${submission.lessonId}`}
            class="flex p-4 px-6 hover:bg-gray-50 border-t"
            href={URLS.guide.assessmentReview({
              courseId,
              type: 'assignment',
              lessonId: submission.lessonId,
              userId: submission.student.id,
            })}
          >
            <div class="flex items-center space-x-4">
              <div class="flex-shrink-0">
                <UserProfileIcon user={submission.student} size="w-10 h-10" />
              </div>
              <div class="flex-1 min-w-0">
                <p class="text-sm font-medium text-gray-900">
                  <strong>{submission.student.name}</strong> submitted an assignment for{' '}
                  <i>{submission.lessonTitle}</i>.
                </p>
                <dl class="flex space-x-2 text-xs text-gray-500 dark:text-gray-200">
                  <dd class="hidden md:flex items-center space-x-1">
                    <IcoClock class="h-4 w-4" />
                    <span>{timeago(submission.updatedAt)}</span>
                  </dd>
                  <AssignmentStatusPill status={submission.status} />
                </dl>
              </div>
            </div>
          </a>
        ))}
      </div>
    </PrimaryOverviewCard>
  );
}
