import { router } from '@components/router';
import { Subtext } from '@components/async-form';
import { courseLoader, LoadedProps } from 'client/lib/loaders';
import { AppRoute } from 'client/lib/app-route/types';
import { HeadingPrimary } from '@components/headings';
import { GuideCoursePage } from '@components/guide-course-page';
import { CourseProfileFieldsForm } from '@components/manage-course-profile-fields';

async function load(route: AppRoute) {
  const { course } = await courseLoader(route);
  return { course };
}

export function Page({ data: { course } }: LoadedProps<typeof load>) {
  return (
    <GuideCoursePage course={course} type="profilefields">
      <div class="flex flex-col w-full max-w-7xl mx-auto p-8 gap-6">
        <div>
          <HeadingPrimary title="Profile fields" />
          <Subtext>Select custom profile fields that your students can fill out.</Subtext>
        </div>
        <CourseProfileFieldsForm courseId={course.id} />
      </div>
    </GuideCoursePage>
  );
}

router.add({
  load,
  url: 'manage/courses/:courseId/profile-fields',
  render: Page,
  authLevel: 'guide',
});
