import { RouteProps, router } from '@components/router';
import { IcoArrowLeft, IcoCheck, IcoChevronDown, IcoExclamation } from '@components/icons';
import { RuzcalMgmtPage } from './mgmt-page';
import { BtnPrimary, Button } from '@components/buttons';
import { AutosizeText } from '@components/autosize-text';
import { useCurrentUser } from 'client/lib/auth';
import { Toggle } from '@components/toggle';
import { ComponentChildren } from 'preact';
import { PageContent, PageHeading, PageSection } from './common';
import { AsyncForm } from '@components/async-form';
import { rpx } from 'client/lib/rpx-client';
import type { AvailabilityRow, EventTypeRow } from 'server/types/cal-schema';
import { AppRoute } from 'client/lib/app-route/types';
import { Dropdown, MenuItem } from '@components/dropdown';
import { SummarizeAvailableTimes } from './availability-summary';
import { Field, InputField, eventToState } from './form-helpers';
import { useMemo, useState } from 'preact/hooks';
import { serialAsync } from 'client/utils/serial-async';
import { showError } from '@components/app-error';
import { useDebouncedEffect } from 'client/utils/debounce';
import { DefaultSpinner } from '@components/spinner';
import type { LocationDetail } from 'server/types/cal-overrides';

type State = Pick<
  Required<EventTypeRow>,
  'name' | 'description' | 'urlSuffix' | 'isPrivate' | 'availabilityId' | 'location'
> & {
  id?: string;
  maxPerDay: string;
  minNotice: string;
  duration: string;
  bufferMinsBefore: string;
  bufferMinsAfter: string;
  hasDailyLimit: boolean;
  availability: AvailabilityRow[];
  isNew: boolean;
  urlPrefix: string;
  locationDetail?: LocationDetail;
};

async function load(opts: AppRoute): Promise<State> {
  const { id } = opts.params;
  const isNew = id === 'new';
  const [availability, existing, host] = await Promise.all([
    rpx.ruzcal.getAvailabilityList(),
    isNew ? undefined : rpx.ruzcal.getEventType({ id }),
    rpx.ruzcal.getHost({}),
  ]);
  const defaultAvailability = availability.find((a) => a.isDefault) || availability[0];
  return {
    isNew,
    id: existing?.id,
    name: existing?.name ?? 'Quick 1:1',
    description: existing?.description ?? '',
    urlSuffix: existing?.urlSuffix ?? Date.now().toString(32),
    duration: (existing?.duration ?? 30).toString(),
    minNotice: (existing?.minNotice ?? 120).toString(),
    hasDailyLimit: existing ? !!existing.maxPerDay : false,
    maxPerDay: (existing?.maxPerDay ?? 0).toString(),
    isPrivate: existing?.isPrivate ?? true,
    bufferMinsAfter: (existing?.bufferMinsAfter ?? 15).toString(),
    bufferMinsBefore: (existing?.bufferMinsBefore ?? 10).toString(),
    availabilityId: existing?.availabilityId ?? defaultAvailability?.id ?? '',
    urlPrefix: host.urlPrefix,
    availability,
    location: existing?.location ?? 'jitsi',
    locationDetail: existing?.locationDetail,
  };
}

function useURLVerifier(urlSuffix: string, eventTypeId?: string) {
  const [status, setStatus] = useState<'loading' | 'conflict' | 'verified'>('verified');
  const serialLoad = useMemo(
    () =>
      serialAsync(async (urlSuffix: string) => {
        try {
          const result = await rpx.ruzcal.isURLSuffixAvailable({ urlSuffix, eventTypeId });
          await new Promise((r) => setTimeout(r, 1000));
          setStatus(result ? 'verified' : 'conflict');
        } catch (err) {
          showError(err);
        }
      }),
    [],
  );

  useDebouncedEffect(() => {
    setStatus('loading');
    serialLoad(urlSuffix);
  }, [urlSuffix]);
  return {
    status,
  };
}

function FormPanel(props: { title: ComponentChildren; children: ComponentChildren }) {
  return (
    <div class="relative flex flex-col gap-4">
      <h2 class="text-lg border-b-2 pb-4">{props.title}</h2>
      {props.children}
    </div>
  );
}

function AvailabilityItem(props: { availability?: AvailabilityRow; timezone: string }) {
  return (
    <span class="flex flex-col cursor-pointer focus:ring-1 transition-all outline-none">
      <span class="font-semibold">{props.availability?.name ?? 'None selected'}</span>
      {props.availability && (
        <SummarizeAvailableTimes
          timeslots={props.availability.timeslots}
          scheduleTimeZone={props.availability.timezone}
          renderTimeZone={props.timezone}
        />
      )}
    </span>
  );
}

function EditPage(props: RouteProps<State>) {
  const user = useCurrentUser()!;
  const { state, setState } = props;

  const bufferMinsBefore = parseInt(state.bufferMinsBefore, 10);
  const bufferMinsAfter = parseInt(state.bufferMinsAfter, 10);
  const minNotice = parseInt(state.minNotice, 10);
  const duration = parseInt(state.duration, 10);
  const maxPerDay = parseInt(state.maxPerDay, 10);
  const availability = state.availability.find((a) => a.id === state.availabilityId);

  const { status } = useURLVerifier(state.urlSuffix, state.id);

  return (
    <RuzcalMgmtPage title="Edit meeting type" currentPage="meetingtypes">
      <PageContent>
        <PageSection>
          <header>
            <a href="/ruzcal-mgmt/meeting-types" class="inline-flex items-center gap-2">
              <IcoArrowLeft />
              Back to meeting types
            </a>
            <PageHeading title={`Edit ${state.name}`} />
          </header>
          <AsyncForm
            class="flex flex-col gap-14"
            onSubmit={async () => {
              await rpx.ruzcal.saveEventType({
                id: state.id || undefined,
                name: state.name,
                description: state.description,
                urlSuffix: state.urlSuffix,
                duration,
                maxPerDay,
                bufferMinsBefore,
                bufferMinsAfter,
                minNotice,
                isPrivate: state.isPrivate,
                availabilityId: state.availabilityId,
                location: state.location,
                locationDetail: state.locationDetail,
              });
              router.goto('/ruzcal-mgmt/meeting-types');
            }}
          >
            <FormPanel title="Basics">
              <div class="flex flex-col gap-8 max-w-2xl">
                <InputField
                  name="name"
                  title="Title"
                  autoFocus
                  value={state.name}
                  onInput={eventToState(setState)}
                  placeholder="e.g. 1:1"
                  inputWidth="w-96 max-w-full"
                />
                <InputField
                  name="duration"
                  title="Duration"
                  value={state.duration}
                  onInput={eventToState(setState)}
                  suffix="minutes"
                  inputWidth="w-14"
                />
                <Field name="description" title="Description">
                  <AutosizeText
                    name="description"
                    containerClass="bg-white"
                    class="ruz-input min-h-20 p-3 px-4"
                    placeholder="Briefly describe the meeting"
                    value={state.description}
                    onInput={eventToState(setState)}
                  />
                </Field>
                <InputField
                  title={
                    <span class="flex gap-2 items-center">
                      URL
                      {status === 'loading' && <DefaultSpinner />}
                      {status === 'conflict' && (
                        <span class="text-red-600 text-xs flex gap-2 items-center">
                          <IcoExclamation class="size-4" />
                          URL already taken
                        </span>
                      )}
                      {status === 'verified' && (
                        <span class="text-green-600 text-xs">
                          <IcoCheck class="size-4" />
                        </span>
                      )}
                    </span>
                  }
                  name="urlSuffix"
                  prefix={`/${state.urlPrefix}/`}
                  value={state.urlSuffix}
                  onInput={(e: any) => {
                    setState({
                      ...state,
                      urlSuffix: e.target.value.replaceAll(/[^a-zA-Z0-9-]/g, '').toLowerCase(),
                    });
                  }}
                />
              </div>
            </FormPanel>

            <FormPanel title="Availability & location">
              <div class="flex flex-col gap-8 max-w-2xl">
                <Field name="availabilityId" title="Availability">
                  <Dropdown
                    hideDownIcon
                    noPadding
                    fullWidth
                    position="left-0 top-full"
                    renderMenu={() => (
                      <div class="flex flex-col min-w-80">
                        {state.availability.map((a) => (
                          <MenuItem
                            key={a.id}
                            onClick={() => setState((s) => ({ ...s, availabilityId: a.id }))}
                          >
                            <AvailabilityItem availability={a} timezone={user.timezone} />
                          </MenuItem>
                        ))}
                      </div>
                    )}
                  >
                    <span class="border border-gray-300 rounded overflow-hidden inline-flex p-3 px-4 items-center gap-6 ruz-input cursor-pointer focus:ring-1 transition-all outline-none bg-white hover:bg-gray-50 text-left">
                      <AvailabilityItem availability={availability} timezone={user.timezone} />
                      <IcoChevronDown class="size-4 opacity-75 ml-auto" />
                    </span>
                  </Dropdown>
                </Field>
                <Field name="location" title="Location">
                  <span class="flex gap-4 items-center">
                    <select
                      class="inline-ruz-input max-w-content cursor-pointer"
                      name="location"
                      value={state.location}
                      onInput={eventToState(setState)}
                    >
                      <option value="jitsi">Jitsi</option>
                      <option value="external">External</option>
                    </select>
                    {state.location === 'jitsi' && (
                      <span class="text-gray-600">
                        Jitsi is a free, open-source video conferencing platform.
                      </span>
                    )}
                  </span>
                </Field>
                {state.location === 'external' && (
                  <Field name="locationDetail" title="External location">
                    <AutosizeText
                      name="locationDetail"
                      containerClass="bg-white"
                      class="ruz-input min-h-20 p-3 px-4"
                      placeholder="e.g. https://zoom.us/j/1234567890"
                      value={state.locationDetail?.external}
                      onInput={(e: any) =>
                        setState((s) => ({ ...s, locationDetail: { external: e.target.value } }))
                      }
                    />
                  </Field>
                )}
                <div class="flex flex-col gap-1">
                  <span>Reserve time around the meeting</span>
                  <section class="flex gap-6">
                    <InputField
                      name="bufferMinsBefore"
                      inputMode="numeric"
                      value={state.bufferMinsBefore}
                      onInput={eventToState(setState)}
                      inputWidth="w-14"
                      suffix="minutes before"
                    />
                    <InputField
                      name="bufferMinsAfter"
                      inputMode="numeric"
                      value={state.bufferMinsAfter}
                      onInput={eventToState(setState)}
                      inputWidth="w-14"
                      suffix="minutes after"
                    />
                  </section>
                </div>
                <InputField
                  name="minNotice"
                  title="Minimum notice"
                  inputMode="numeric"
                  value={state.minNotice}
                  onInput={eventToState(setState)}
                  inputWidth="w-14"
                  suffix="minutes"
                />
                <section class="flex flex-col gap-4">
                  <label class="flex gap-3 items-center cursor-pointer">
                    <Toggle
                      checked={!state.isPrivate}
                      onClick={() => setState((s) => ({ ...s, isPrivate: !s.isPrivate }))}
                    />
                    <span>Show this meeting type on my public page</span>
                  </label>

                  <label class="flex gap-3 items-center cursor-pointer">
                    <Toggle
                      checked={state.hasDailyLimit}
                      onClick={() =>
                        setState((s) => ({
                          ...s,
                          hasDailyLimit: !s.hasDailyLimit,
                          maxPerDay: s.hasDailyLimit ? '0' : '4',
                        }))
                      }
                    />
                    <span>Limit how many meetings can be booked in a day</span>
                  </label>
                  {state.hasDailyLimit && (
                    <InputField
                      name="maxPerDay"
                      inputMode="numeric"
                      value={state.maxPerDay}
                      onInput={eventToState(setState)}
                      inputWidth="w-14"
                      suffix="meetings"
                    />
                  )}
                </section>
              </div>
            </FormPanel>
            <footer class="flex gap-4 sticky bottom-0 bg-gray-50 py-4 z-10 px-2 -mx-2">
              <BtnPrimary class="px-4">Save meeting type</BtnPrimary>
              <Button
                href="/ruzcal-mgmt/meeting-types"
                class="text-inherit inline-flex items-center justify-center rounded-md hover:bg-gray-100 px-4 transition-all"
              >
                Cancel
              </Button>
            </footer>
          </AsyncForm>
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}

function Page(props: RouteProps<State>) {
  if (props.state.availability.length) {
    return <EditPage {...props} />;
  }

  return (
    <RuzcalMgmtPage title="Edit meeting type" currentPage="meetingtypes">
      <PageContent>
        <PageSection>
          <h1 class="font-semibold text-xl">
            Before you can create a meeting type, you must first define your availability.
          </h1>
          <p>
            Go to <a href="/ruzcal-mgmt/availability/new">the availability page</a> to define your
            availability, then come back here to create a new meeting type.
          </p>
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}

router.add({
  load,
  authLevel: 'superadmin',
  url: 'ruzcal-mgmt/meeting-types/:id',
  render: Page,
});
