/**
 * This file contains the UI for editing a course's coupons.
 */

import { router } from '@components/router';
import { courseCoupons, URLS } from 'shared/urls';
import { rpx } from 'client/lib/rpx-client';
import { AppRoute } from 'client/lib/app-route/types';
import { LoadedProps } from 'client/lib/loaders';
import { GuideCoursePage, GuideProductPage } from '@components/guide-course-page';
import { ManageCouponsPage } from './components/coupons-page';
import { urls as manageStudents } from 'client/pages/guide-course-students/urls';

const store = rpx.coupons;

/**
 * Load the initial page of coupons from the server. If no coupon was requested
 * in the URL, we'll do a rewrite and show the first coupon in the resultset.
 */
async function load(route: AppRoute) {
  const { courseId } = route.params;
  const result = await store.initForCourse({
    courseId,
  });
  if (result.course.level !== 'guide') {
    router.goto(URLS.guide.lessons({ courseId }));
  }
  return result;
}

function PageContent(props: LoadedProps<typeof load>) {
  const { data } = props;
  const courseId = data.course.id;
  const baseUrl = URLS.guide.baseUrl(data.course);
  const couponsUrl = `${baseUrl}/coupons`;

  return (
    <ManageCouponsPage
      {...data}
      courseId={courseId}
      supportsStripe={data.supportsStripe}
      supportsPaypal={data.supportsPaypal}
      isCoreCoupon={false}
      couponUrl={({ couponId }) => `${couponsUrl}/${couponId}`}
      priceUrl={({ priceId }) => `${baseUrl}/prices/${priceId}`}
      checkoutUrl={({ couponId, priceId }) =>
        courseCoupons.checkoutUrl({ courseId, couponId, priceId, isProduct: data.course.isProduct })
      }
      couponsUrl={couponsUrl}
      newUrl={`${couponsUrl}/new`}
      signupUrl={({ userId }) =>
        manageStudents.showStudent({
          userId,
          courseId,
          baseUrl: data.course.isProduct
            ? manageStudents.productsBaseUrl
            : manageStudents.coursesBaseUrl,
        })
      }
    />
  );
}

function Page(props: LoadedProps<typeof load>) {
  if (props.data.course.isProduct) {
    return (
      <GuideProductPage product={props.state.course} page="coupons">
        <PageContent {...props} />
      </GuideProductPage>
    );
  }
  return (
    <GuideCoursePage course={props.state.course} type="coupons">
      <PageContent {...props} />
    </GuideCoursePage>
  );
}

[
  'manage/products/:courseId/coupons',
  'manage/courses/:courseId/coupons',
  'manage/products/:courseId/coupons/:couponId',
  'manage/courses/:courseId/coupons/:couponId',
].forEach((url) =>
  router.add({
    url,
    load,
    render: Page,
    authLevel: 'guide',
  }),
);
