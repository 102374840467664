import { UserProfileIcon } from '@components/avatars';
import {
  IcoCalendar,
  IcoClock,
  IcoGlobe,
  IcoInfo,
  IcoMapPin,
  IcoVideoCamera,
} from '@components/icons';
import { useState } from 'preact/hooks';
import { ScheduleSummary } from './schedule-summary';
import { BulletIco } from './bullet-ico';
import type { EventTypeRow } from 'server/types/cal-schema';
import type { UserProfileRow } from 'server/types';
import { DateRange } from 'shared/scheduling';

type Attendee = Pick<UserProfileRow, 'name' | 'email'>;

type MeetingDetail = Pick<
  EventTypeRow,
  'name' | 'description' | 'duration' | 'locationDetail' | 'location'
> & {
  host: Pick<UserProfileRow, 'name' | 'profilePhotoUrl'>;
  attendee?: Attendee;
  timeZone: string;
  hour12?: boolean;
  schedule?: DateRange;
};

export function MeetingSummary({
  host,
  name,
  description,
  duration,
  location,
  locationDetail,
  timeZone,
  hour12 = true,
  schedule,
}: MeetingDetail) {
  const [expand, setExpand] = useState(false);
  return (
    <div class="flex flex-col gap-4 overflow-auto">
      <div class="flex items-center gap-3">
        <UserProfileIcon user={host} size="size-10" />
        <span class="text-base text-gray-500 text-ellipsis whitespace-nowrap overflow-hidden">
          {host.name}
        </span>
      </div>
      <h1 class="text-lg font-bold text-gray-700">{name}</h1>
      {schedule && (
        <BulletIco Ico={IcoCalendar} multiline>
          <ScheduleSummary schedule={schedule} hour12={hour12} />
        </BulletIco>
      )}
      {!schedule && (
        <BulletIco
          Ico={IcoInfo}
          multiline
          class="cursor-pointer"
          onClick={() => setExpand((x) => !x)}
        >
          <div class={`${expand ? '' : 'line-clamp-2'}`}>{description}</div>
        </BulletIco>
      )}
      <BulletIco Ico={IcoClock}>{duration} min</BulletIco>
      {location === 'external' && locationDetail && (
        <BulletIco Ico={IcoMapPin} multiline>
          <span class="whitespace-pre-wrap">{locationDetail.external}</span>
        </BulletIco>
      )}
      {location === 'jitsi' && <BulletIco Ico={IcoVideoCamera}>Jitsi</BulletIco>}
      {!schedule && <BulletIco Ico={IcoGlobe}>{timeZone}</BulletIco>}
    </div>
  );
}
