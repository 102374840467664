import { RouteProps, router } from '@components/router';
import { AppRoute } from 'client/lib/app-route/types';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { groupBy } from 'shared/utils';
import { moduleAvailableOn } from '@components/module-helpers';
import { getMeetingStatus } from 'shared/meeting-utils';
import { UpcomingEvents, WhatsNew } from '@components/course-overview';
import { GuideCoursePage } from '@components/guide-course-page';
import { CourseStats } from './course-stats';
import { RecentSignups } from './recent-signups';
import { IcoPencil } from '@components/icons';
import { RecentAssignments } from './recent-assignments';
import { RecentQuizSubmissions } from './recent-quiz-submissions';
import { PrimaryOverviewCard } from './overview-card';
import { Module, ModuleCard } from '@components/module-helpers/module-card';
import { URLS } from 'shared/urls';
import { BundleOverview } from './bundle-overview';
import { emptyLessonTitle, emptyModuleTitle } from 'shared/terminology';
import { globalConfig } from 'client/lib/auth';
import { rpx } from 'client/lib/rpx-client';

export type OverviewPageData = Awaited<ReturnType<typeof load>>;
type Props = RouteProps<OverviewPageData>;

function Page(props: Props) {
  const { course, modules, upcomingMeetings, recentSignups } = props.data;
  const { id: courseId } = course;

  useAsyncEffect(async () => {
    if (!courseId) {
      return;
    }
    try {
      await rpx.courses.setLastOpenedAt({ courseId });
    } catch (err) {
      console.warn('Could not set last_opened_at', err);
    }
  }, [courseId]);

  if (course.isBundle) {
    return <BundleOverview course={course} signupsData={recentSignups} />;
  }

  return (
    <GuideCoursePage course={course} type="dashboard">
      <div class="flex flex-col w-full max-w-7xl mx-auto p-8 gap-6">
        <CourseStats course={course} />
        <div class="grid grid-cols-1 xl:grid-cols-2 gap-8">
          <div class="flex-grow">
            <PrimaryOverviewCard
              title="Modules & Lessons"
              headerActions={
                <a class="inline-flex items-center" href={`/manage/courses/${courseId}/lessons`}>
                  <IcoPencil class="w-4 h-4 opacity-75 mr-1" />
                  <span>
                    Edit<span class="hidden md:inline"> Modules & Lessons</span>
                  </span>
                </a>
              }
            >
              {modules.map((module, index) => (
                <ModuleCard key={module.id} module={module} index={index} />
              ))}
            </PrimaryOverviewCard>
          </div>

          <div class="flex flex-col gap-8">
            <RecentSignups
              course={course}
              signups={recentSignups.signups}
              hasMore={recentSignups.hasMore}
            />
            <RecentAssignments courseId={courseId} />
            <RecentQuizSubmissions courseId={courseId} />
            <UpcomingEvents meetings={upcomingMeetings} isGuide Wrapper={PrimaryOverviewCard} />
            <WhatsNew course={course} title="Recent Comments" Wrapper={PrimaryOverviewCard} />
          </div>
        </div>
      </div>
    </GuideCoursePage>
  );
}

async function load(route: AppRoute) {
  const { courseId } = route.params;
  const [course, state, meetings, recentSignups] = await Promise.all([
    rpx.courses.getGuideCourse({ id: courseId }),
    rpx.lessons.getFullLessonState({ courseId }),
    rpx.meetings.getStudentMeetings({ courseId }),
    rpx.courseOverview.getRecentSignups({ courseId, limit: 3 }),
  ]);

  if (course.level !== 'guide') {
    router.goto(URLS.guide.lessons({ courseId }));
  }

  const { completedLessons } = state;

  const moduleLessons = groupBy((x) => x.moduleId, state.lessons);
  const modulesMap = state.modules.reduce((acc, module) => {
    const lessons = (moduleLessons[module.id] || []).map((lesson) => ({
      id: lesson.id,
      title: lesson.title || emptyLessonTitle(globalConfig().tenant),
      isCompleted: completedLessons.includes(lesson.id),
      isPrerequisite: lesson.isPrerequisite,
      assessmentType: lesson.assessmentType,
      hasDiscussion: !!lesson.discussion?.isEnabled,
      hasAssignment: lesson.assessmentType === 'assignment',
      href: URLS.guide.lesson({ courseId: course.id, lessonId: lesson.id }),
    }));
    const stats = lessons.reduce(
      (acc, lesson) => {
        if (lesson.hasDiscussion) {
          acc.discussions++;
        }
        if (lesson.hasAssignment) {
          acc.assignments++;
        }

        return acc;
      },
      {
        discussions: 0,
        assignments: 0,
      },
    );

    acc[module.id] = {
      id: module.id,
      title: module.title || emptyModuleTitle(globalConfig().tenant),
      availableOn: moduleAvailableOn({
        isAbsoluteSchedule: course.isAbsoluteSchedule,
        module,
        accessFormat: course.accessFormat,
        membershipDate: state.membershipDate,
      }),
      lessons,
      stats,
      isDraft: module.isDraft,
    };
    return acc;
  }, {} as Record<UUID, Module>);

  const modules = Object.values(modulesMap);

  return {
    ...state,
    course,
    modules,
    recentSignups,
    upcomingMeetings: meetings.filter((m) => {
      return getMeetingStatus(m) !== 'ended';
    }),
  };
}

router.add({
  url: 'manage/courses/:courseId',
  load,
  render: Page,
  authLevel: 'guide',
});
