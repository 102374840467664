import { RouteProps, router } from '@components/router';
import { RuzcalMgmtPage } from './mgmt-page';
import { PageContent, PageHeading, PageSection } from './common';
import { AsyncForm, FormGroup } from '@components/async-form';
import { BtnPrimary, Button } from '@components/buttons';
import { rpx } from 'client/lib/rpx-client';
import { showToast } from '@components/toaster';
import { useMemo, useState } from 'preact/hooks';
import { useDebouncedEffect } from 'client/utils/debounce';
import { serialAsync } from 'client/utils/serial-async';
import { showError } from '@components/app-error';
import { IcoCheck, IcoExclamation } from '@components/icons';
import { DefaultSpinner } from '@components/spinner';

type State = {
  urlPrefix: string;
};

async function load(): Promise<State> {
  const host = await rpx.ruzcal.ensureHost();
  return {
    urlPrefix: host.urlPrefix,
  };
}

function useURLVerifier(urlPrefix: string) {
  const [status, setStatus] = useState<'loading' | 'conflict' | 'verified'>('verified');
  const serialLoad = useMemo(
    () =>
      serialAsync(async (urlPrefix: string) => {
        try {
          const result = await rpx.ruzcal.isURLPrefixAvailable({ urlPrefix });
          await new Promise((r) => setTimeout(r, 1000));
          setStatus(result ? 'verified' : 'conflict');
        } catch (err) {
          showError(err);
        }
      }),
    [],
  );

  useDebouncedEffect(() => {
    setStatus('loading');
    serialLoad(urlPrefix);
  }, [urlPrefix]);
  return {
    status,
  };
}

function Page(props: RouteProps<State>) {
  const { state, setState } = props;
  const { status } = useURLVerifier(state.urlPrefix);

  return (
    <RuzcalMgmtPage title="Settings" currentPage="other">
      <PageContent>
        <PageSection>
          <PageHeading title="Settings" subtitle="Manage your calendar settings and profile." />
          <AsyncForm
            class="flex flex-col gap-8 max-w-2xl"
            onSubmit={async () => {
              await rpx.ruzcal.saveHost({ urlPrefix: state.urlPrefix });
              showToast({
                title: 'Settings saved',
                type: 'ok',
                message: 'Your settings have been saved.',
              });
            }}
          >
            <FormGroup prop="urlPrefix">
              <label class="flex flex-col gap-1 max-w-80">
                <span class="font-medium flex gap-2 items-center">
                  URL
                  {status === 'loading' && <DefaultSpinner />}
                  {status === 'conflict' && (
                    <span class="text-red-600 text-xs flex gap-2 items-center">
                      <IcoExclamation class="size-4" />
                      URL already taken
                    </span>
                  )}
                  {status === 'verified' && (
                    <span class="text-green-600 text-xs">
                      <IcoCheck class="size-4" />
                    </span>
                  )}
                </span>
                <input
                  type="text"
                  name="urlPrefix"
                  class="inline-ruz-input px-4 p-3 text-sm"
                  value={state.urlPrefix}
                  autofocus
                  onInput={(e: any) =>
                    setState((s) => ({
                      ...s,
                      urlPrefix: e.target.value.replaceAll(/[^a-zA-Z0-9-]/g, '').toLowerCase(),
                    }))
                  }
                />
              </label>
            </FormGroup>
            <footer class="flex gap-4">
              <BtnPrimary class="p-3 px-4">Save settings</BtnPrimary>
              <Button
                href="/ruzcal-mgmt/availability"
                class="text-inherit inline-flex items-center justify-center rounded-md hover:bg-gray-100 px-4 transition-all"
              >
                Cancel
              </Button>
            </footer>
          </AsyncForm>
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}

router.add({
  url: 'ruzcal-mgmt/settings',
  authLevel: 'superadmin',
  load,
  render: Page,
});
