import { showError } from '@components/app-error';
import { BtnPrimary, Button } from '@components/buttons';
import { IcoArrowLeft } from '@components/icons';
import { LoadingIndicator } from '@components/loading-indicator';
import { useConfiguration, useCurrentTenant, useCurrentUser } from 'client/lib/auth';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { ComponentChildren } from 'preact';
import { useRef, useState } from 'preact/hooks';
import { RUZUKU_ASSETS_BASE_URL } from 'shared/consts';
import { useAsyncData } from 'client/lib/hooks';
import { AsyncForm, FormGroup } from '@components/async-form';
import { CoursePrivacySettings, CoursePrivacyState } from '@components/course-privacy-settings';
import { router } from '@components/router';
import { URLS } from 'shared/urls';
import { useDidUpdateEffect } from 'client/utils/use-did-update-effect';
import { useImageUrl } from 'client/utils/cdn';
import { CopyCourseTemplateForm } from '@components/course-action-modals';
import { useBodyScrollLock } from 'client/lib/hooks/use-body-scroll-lock';
import { BaseDialog } from '@components/dialog';

const store = rpx.instantCourses;
export type Template = RpxResponse<typeof store.getInstantCourses>[0];

interface Props {
  initialPane?: Pane;
  isOpen: boolean;
  hide: () => void;
}

type Pane = 'home' | 'course' | 'newcourse' | 'newproduct' | 'newtemplate';
const panes: Pane[] = ['home', 'course', 'newcourse', 'newproduct', 'newtemplate'];

export function CreateCourseModal({ initialPane = 'home', isOpen, hide }: Props) {
  const { terminology } = useCurrentTenant();
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [template, setTemplate] = useState<Template | undefined>();
  const [pane, setPane] = useState<Pane>(initialPane);
  const prevPane = useRef(pane);
  const fadeDirection = panes.indexOf(pane) - panes.indexOf(prevPane.current);
  const fadeClass =
    fadeDirection > 0 ? 'an-fade-in-left' : fadeDirection < 0 ? 'an-fade-in-right' : '';

  useBodyScrollLock(isOpen);

  useDidUpdateEffect(() => {
    prevPane.current = pane;
  }, [pane]);

  useAsyncEffect(async () => {
    if (!isOpen || templates.length > 0) {
      return;
    }

    setIsLoading(true);
    try {
      const templates = await store.getInstantCourses({ isGlobal: true });
      setTemplates(templates);
    } catch (err) {
      showError(err);
    } finally {
      setIsLoading(false);
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <BaseDialog onClose={hide}>
      {isLoading && <LoadingIndicator />}
      <div
        class={`max-w-full w-5xl p-6 sm:p-8 flex flex-col gap-6 overflow-y-auto ${fadeClass}`}
        key={pane}
      >
        <header class="flex justify-between">
          <nav class="flex flex-col items-start gap-2">
            <h2 class="text-xl">
              {pane === 'home' && `Create a new ${terminology.course} or product`}
              {(pane === 'course' || pane === 'newcourse') && `Create a ${terminology.course}`}
              {pane === 'newproduct' && 'Create a product'}
              {pane === 'newtemplate' && `Create a new ${terminology.course} from template...`}
            </h2>
            <Button
              onClick={() =>
                pane === 'home'
                  ? hide()
                  : setPane(pane === 'newcourse' || pane === 'newtemplate' ? 'course' : 'home')
              }
              class="text-indigo-600 inline-flex gap-2 items-center font-semibold"
            >
              <IcoArrowLeft />
              Back
            </Button>
          </nav>
        </header>
        {pane === 'home' && <HomePane setPane={setPane} />}
        {pane === 'course' && (
          <ChooseCourseTypePane
            onTemplateCourse={(template) => {
              setTemplate(template);
              setPane('newtemplate');
            }}
            templates={templates}
            setPane={setPane}
          />
        )}
        {pane === 'newproduct' && <NewCoursePane isProduct />}
        {pane === 'newcourse' && <NewCoursePane isProduct={false} />}
        {pane === 'newtemplate' && template && <NewTemplateCoursePane template={template} />}
      </div>
    </BaseDialog>
  );
}

function HomePane({ setPane }: { setPane(pane: Pane): void }) {
  const { terminology } = useCurrentTenant();
  return (
    <div class="flex flex-col sm:grid sm:grid-cols-2 gap-6">
      <ActionCard
        src={`${RUZUKU_ASSETS_BASE_URL}/new-course.svg`}
        title={`Create a ${terminology.course}...`}
        desc="Engage and support students with discussions, chats, meetings, and assessments."
        onClick={() => setPane('course')}
      />
      <ActionCard
        src={`${RUZUKU_ASSETS_BASE_URL}/new-product.svg`}
        title="Create a product..."
        desc="Sell structured content (such as eBooks and videos) or take payment for a service package."
        onClick={() => setPane('newproduct')}
      />
      <InstantCoursesCard />
    </div>
  );
}

function ChooseCourseTypePane({
  setPane,
  onTemplateCourse,
  templates,
}: {
  templates: Template[];
  onTemplateCourse(template: Template): void;
  setPane(pane: Pane): void;
}) {
  const { terminology } = useCurrentTenant();
  return (
    <div class="flex flex-col gap-6">
      {templates.length > 0 && (
        <section class="flex flex-col gap-6 border-b pb-4 sm:p-8 sm:border sm:rounded-2xl">
          <header>
            <h2 class="text-lg">Templates</h2>
            <p class="text-gray-500">Start with a template and add your own materials...</p>
          </header>
          <nav class="whitespace-nowrap overflow-x-auto -mx-1 p-1 pb-2">
            <div class="flex gap-6 justify-start items-start w-content">
              {templates.map((t) => (
                <CourseTemplate
                  key={t.id}
                  onClick={() => onTemplateCourse(t)}
                  img={t.imagePath}
                  title={t.title}
                />
              ))}
            </div>
          </nav>
        </section>
      )}

      <section class="flex flex-col gap-6 sm:p-8 sm:border rounded-2xl">
        <header>
          <h2 class="text-lg">New course</h2>
          <p class="text-gray-500">
            Or start with a blank {terminology.course} and build it from scratch...
          </p>
        </header>
        <footer>
          <BtnPrimary onClick={() => setPane('newcourse')} class="rounded-full px-4">
            Create a new course
          </BtnPrimary>
        </footer>
      </section>
    </div>
  );
}

function NewCoursePane({ isProduct }: { isProduct: boolean }) {
  const tenant = useCurrentTenant();
  const user = useCurrentUser();
  const { terminology } = tenant;
  const courseLabel = isProduct ? 'product' : terminology.course;
  const [featuresState, setFeaturesState] = useState<CoursePrivacyState>({
    hidePeople: isProduct,
    hideDiscussions: isProduct,
    restrictStudentDiscussions: isProduct,
    chatEnabled: !isProduct,
  });

  async function onSubmit(values: { title: string }) {
    const { courseId, lessonId } = await rpx.courses.createCourse({
      isProduct,
      title: values.title,
      ...featuresState,
    });
    router.goto(URLS.guide.lesson({ isProduct, courseId, lessonId }));
  }

  if (isProduct && tenant.isCore && user?.tier !== 'pro') {
    return (
      <div class="flex flex-col gap-4">
        <section class="flex flex-col gap-4">
          <h2 class="font-semibold">Upgrade to continue</h2>
          <p>
            Offer eBooks, services, downloadable content, and other non-course products with Ruzuku
            Pro. Upgrade to Ruzuku Pro to start creating products now.
          </p>

          <div
            style="padding:62.46% 0 0 0;position:relative;"
            class="border rounded-lg overflow-hidden mb-4"
          >
            <iframe
              src="https://player.vimeo.com/video/1006725403?h=83196e9838&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              style="position:absolute;top:0;left:0;width:100%;height:100%;"
              title="Ruzuku products (September 2024)"
            ></iframe>
          </div>
          <footer class="flex items-center gap-4">
            <BtnPrimary href="/account/billing?coreBilling=true" class="rounded-full px-4">
              Upgrade to Pro
            </BtnPrimary>
            <a href="https://ruzuku.appointlet.com/s/ruzuku-pro-consultation-with-peter/peter-smiley">
              Or book a consultation to learn more
            </a>
          </footer>
        </section>
      </div>
    );
  }

  return (
    <AsyncForm onSubmit={onSubmit} class="flex flex-col gap-8">
      <section class="flex flex-col gap-2">
        <label>Enter the title of your new {courseLabel}.</label>
        <FormGroup prop="title" class="w-full">
          <input
            type="text"
            placeholder={`${isProduct ? 'Product' : terminology.Course} title`}
            autoFocus
            name="title"
            class="ruz-input"
          />
        </FormGroup>
      </section>
      {!isProduct && <CoursePrivacySettings state={featuresState} setState={setFeaturesState} />}
      <footer>
        <BtnPrimary class="rounded-full px-4">Create {courseLabel}</BtnPrimary>
      </footer>
    </AsyncForm>
  );
}

function NewTemplateCoursePane({ template }: { template: Template }) {
  const { terminology } = useCurrentTenant();
  const [isProcessing, setIsProcessing] = useState(false);
  return (
    <CopyCourseTemplateForm
      onIsProcessing={setIsProcessing}
      course={{ id: template.id, title: template.title }}
      footer={
        <footer class="mt-8">
          <BtnPrimary class="rounded-full px-4" isLoading={isProcessing}>
            Create {terminology.course}
          </BtnPrimary>
        </footer>
      }
    />
  );
}

function ActionCard(props: {
  src?: string;
  title: ComponentChildren;
  desc: ComponentChildren;
  onClick(): void;
}) {
  return (
    <button
      onClick={props.onClick}
      class="flex flex-col border rounded-2xl overflow-hidden hover:border-indigo-400 hover:ring-2 hover:ring-indigo-400"
    >
      <span class="flex items-center justify-center aspect-video sm:aspect-square p-4">
        <img src={props.src} class="object-cover rounded-xl" />
      </span>
      <span class="flex flex-col gap-2 p-4 pt-0 text-left">
        <span class="text-xl">{props.title}</span>
        <span class="text-gray-500">{props.desc}</span>
      </span>
    </button>
  );
}

function CourseTemplate({ img, title, onClick }: { img?: string; title: string; onClick(): void }) {
  const src = useImageUrl(img);

  return (
    <Button
      class="w-28 md:w-36 overflow-hidden inline-flex flex-col hover:ring-2 hover:ring-indigo-600 rounded-xl"
      type="button"
      onClick={onClick}
    >
      <span class="flex items-center justify-center h-48 w-full rounded-xl overflow-hidden bg-gray-50">
        {src && <img src={`${src}?width=600`} class="object-cover h-full" />}
      </span>
      <strong class="text-sm line-clamp-2 whitespace-normal w-full block text-left p-2">
        {title}
      </strong>
    </Button>
  );
}

function InstantCoursesCard() {
  const configuration = useConfiguration();
  const { isLoading, data: shouldDisplay } = useAsyncData(async () => {
    // We are always displaying the "instant courses" bundle
    // on the core tenant in the preview mode so no need to fire a request here.
    if (configuration.tenant.isCore) {
      return Promise.resolve(true);
    }
    const { isUnlimited, credits } = await store.getInstantCourseCredits();
    return isUnlimited || credits > 0;
  }, []);

  // Do not display this is until loaded
  // or when there are not available bundles for the user
  if (isLoading || !shouldDisplay) {
    return null;
  }

  return (
    <a
      href="/instant-courses"
      class="col-span-2 flex flex-col sm:grid grid-cols-2 border rounded-2xl overflow-hidden hover:border-indigo-400 hover:ring-2 hover:ring-indigo-400 text-inherit items-center"
    >
      <span class="p-4">
        <span class="flex items-center justify-center aspect-video sm:aspect-square">
          <img
            src={`${RUZUKU_ASSETS_BASE_URL}/instant-courses.svg`}
            class="object-cover rounded-xl object-top"
          />
        </span>
      </span>
      <span class="flex flex-col gap-2 p-4 text-left">
        <span class="text-xl">Browse Instant Courses...</span>
        <span class="text-gray-500">
          Explore 30+ professionally-designed courses that you can customize and make your own.
        </span>
      </span>
    </a>
  );
}
