import { IcoCheckCircle } from '@components/icons';

function Step({ title, isComplete }: { title: string; isComplete?: boolean }) {
  return (
    <div class="relative px-2 py-4 gap-2 items-center flex border rounded-lg  xl:rounded-lg cursor-pointer group hover:bg-violet-50 bg-white shadow-sm">
      <div>
        <IcoCheckCircle class={`size-7 ${isComplete ? 'text-green-500' : 'text-gray-300'}`} />
      </div>
      <p
        class={`font-medium text-xs ${
          isComplete ? 'text-gray-500 line-through' : 'text-black font-medium'
        }`}
      >
        {title}
      </p>
    </div>
  );
}

export function GuideChecklist() {
  return (
    <div class="bg-gradient-to-b from-gray-100 to-indigo-50 p-4 sm:p-6 rounded-2xl">
      <div class="flex justify-between mb-4 sm:mb-6 items-center">
        <p class="text-black text-lg xl:text-2xl font-bold">Getting started</p>
        <div class="text-sm font-medium text-indigo-600">1 of 4 complete!</div>
      </div>
      <div class="grid grid-cols-1 xl:grid-cols-2 gap-4">
        <Step title="1. Create your account" isComplete />
        <Step title="2. Update your photo and bio" />
        <Step title="3. Watch getting started video" />
        <Step title="4. Create your first course" />
      </div>
    </div>
  );
}
