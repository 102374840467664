import { RouteProps, router } from '@components/router';
import { useCurrentUser } from 'client/lib/auth';
import { IcoArrowLeft } from '@components/icons';
import { RuzcalMgmtPage } from './mgmt-page';
import { BtnPrimary, Button } from '@components/buttons';
import { Calendar } from './calendar';
import { useState } from 'preact/hooks';
import { TimeCombobox } from './time-combobox';
import dayjs from 'dayjs';
import { Toggle } from '@components/toggle';
import { datesEq, mkdate } from '@components/date-picker';
import { PageContent, PageHeading, PageSection } from './common';
import { AsyncForm } from '@components/async-form';
import { rpx } from 'client/lib/rpx-client';
import { timeOfDay } from 'shared/scheduling';
import { AppRoute } from 'client/lib/app-route/types';

type State = {
  id: string;
  isEditingStart: boolean;
  startDate?: Date;
  allDay: boolean;
  startTime: string;
  endDate?: Date;
  endTime: string;
};

async function load(opts: AppRoute): Promise<State> {
  const { id } = opts.params;
  const isNew = id === 'new';
  if (isNew) {
    return {
      id,
      isEditingStart: true,
      allDay: false,
      startTime: '12:00am',
      endTime: '11:59pm',
    };
  }
  const override = await rpx.ruzcal.getOverride({ id });
  return {
    id,
    isEditingStart: true,
    startDate: mkdate((dt) => dt.setHours(0, 0, 0, 0), override.start),
    allDay: false,
    startTime: dayjs(override.start).format('h:mma'),
    endDate: mkdate((dt) => dt.setHours(0, 0, 0, 0), override.end),
    endTime: dayjs(override.end).format('h:mma'),
  };
}

function getToday() {
  return mkdate((dt) => dt.setHours(0, 0, 0, 0));
}

function BtnDate(props: {
  date?: Date;
  time: string;
  isSelected: boolean;
  isStart?: boolean;
  onClick(): void;
  allDay: boolean;
}) {
  return (
    <button
      type="button"
      class={`flex flex-col text-left p-2 rounded-xl text-xs border transition-all min-w-40 ${
        props.isSelected ? 'bg-white shadow' : 'border-transparent hover:bg-gray-200'
      }`}
      onClick={props.onClick}
    >
      <span class="font-semibold">{props.isStart ? 'Start' : 'End'}</span>
      <span>
        {props.date ? dayjs(props.date).format('MMMM D, YY') : 'Add dates'}
        {!props.allDay && ' ' + props.time}
      </span>
    </button>
  );
}

function DateRangePicker(props: { start?: Date; end?: Date; onChange(val: Date): void }) {
  const [focusedDate, setFocusedDate] = useState(() => props.start || getToday());
  const start = dayjs(props.start);
  const end = dayjs(props.end);
  return (
    <div class="grid md:grid-cols-2 gap-20">
      <Calendar
        navClass="md:hidden"
        onSelect={props.onChange}
        focusedDate={focusedDate}
        setFocusedDate={setFocusedDate}
        renderDate={(opts) => {
          if (opts.date < opts.today) {
            return null;
          }

          const isStart = datesEq(opts.date, props.start);
          const isEnd = datesEq(opts.date, props.end);
          const isBetween = start.isBefore(opts.date) && end.isAfter(opts.date);
          const isInRange = isStart || isEnd || isBetween;

          return (
            <span
              class={`aspect-square group hover:z-10 p-1 relative flex font-medium text-gray-900 ${
                isBetween ? 'bg-gray-100' : ''
              }`}
            >
              {isInRange && (
                <span
                  class={`absolute inset-0 ${isBetween ? '-inset-x-2' : ''} bg-gray-100 ${
                    isStart ? `rounded-l-full ${!isEnd ? '-right-2' : ''}` : ''
                  } ${isEnd ? `rounded-r-full ${!isStart ? '-left-2' : ''}` : ''}`}
                ></span>
              )}
              <span
                class={`relative flex items-center justify-center rounded-full aspect-square group-hover:ring-2 ring-indigo-600 ${
                  isStart || isEnd ? 'bg-indigo-600 text-white' : ''
                }`}
              >
                {opts.date.getDate()}
              </span>
            </span>
          );
        }}
      />
      <div class="flex-col hidden md:flex">
        <Calendar
          onSelect={props.onChange}
          focusedDate={dayjs(focusedDate).add(1, 'month').toDate()}
          setFocusedDate={(dt) => setFocusedDate(dayjs(dt).add(-1, 'month').toDate())}
          renderDate={(opts) => {
            if (opts.date < opts.today) {
              return null;
            }

            const isStart = datesEq(opts.date, props.start);
            const isEnd = datesEq(opts.date, props.end);
            const isBetween = start.isBefore(opts.date) && end.isAfter(opts.date);
            const isInRange = isStart || isEnd || isBetween;

            return (
              <span
                class={`aspect-square group hover:z-10 p-1 relative flex font-medium text-gray-900 ${
                  isBetween ? 'bg-gray-100' : ''
                }`}
              >
                {isInRange && (
                  <span
                    class={`absolute inset-0 ${isBetween ? '-inset-x-2' : ''} bg-gray-100 ${
                      isStart ? `rounded-l-full ${!isEnd ? '-right-2' : ''}` : ''
                    } ${isEnd ? `rounded-r-full ${!isStart ? '-left-2' : ''}` : ''}`}
                  ></span>
                )}
                <span
                  class={`relative flex items-center justify-center rounded-full aspect-square group-hover:ring-2 ring-indigo-600 ${
                    isStart || isEnd ? 'bg-indigo-600 text-white' : ''
                  }`}
                >
                  {opts.date.getDate()}
                </span>
              </span>
            );
          }}
        />
      </div>
    </div>
  );
}

function Page({ state, setState }: RouteProps<State>) {
  const user = useCurrentUser()!;
  const isSingleDate = dayjs(state.startDate).isSame(state.endDate);
  return (
    <RuzcalMgmtPage title="Block out unavailable time" currentPage="availability">
      <PageContent>
        <PageSection>
          <header class="mb-4">
            <a href="/ruzcal-mgmt/availability" class="inline-flex items-center gap-2">
              <IcoArrowLeft />
              Back to availability
            </a>
            <PageHeading
              title="Remove dates from your availability"
              subtitle={
                <>
                  Specify dates that you'll be unavailable (in{' '}
                  <span class="text-gray-500">{user.timezone.replaceAll('_', ' ')} time).</span>
                </>
              }
            />
          </header>
          <AsyncForm
            class="flex flex-col gap-8"
            onSubmit={async () => {
              if (!state.startDate || !state.endDate) {
                throw new Error('Please select a date');
              }
              const start = timeOfDay(state.startDate, state.startTime, user.timezone).toDate();
              const end = timeOfDay(state.endDate, state.endTime, user.timezone).toDate();
              await rpx.ruzcal.saveBlockedTime({
                id: state.id === 'new' ? undefined : state.id,
                start,
                end,
              });
              router.goto('/ruzcal-mgmt/availability');
              await new Promise((r) => setTimeout(r, 1000));
            }}
          >
            <section class="flex flex-col gap-6">
              <header class="rounded-2xl bg-gray-100 border p-1 flex gap-4">
                <BtnDate
                  isStart
                  isSelected={state.isEditingStart}
                  onClick={() => setState((s) => ({ ...s, isEditingStart: true }))}
                  allDay={state.allDay}
                  time={state.startTime}
                  date={state.startDate}
                />
                <BtnDate
                  isSelected={!state.isEditingStart}
                  onClick={() => setState((s) => ({ ...s, isEditingStart: false }))}
                  allDay={state.allDay}
                  time={state.endTime}
                  date={state.endDate}
                />
              </header>

              <div class="border rounded-xl bg-white flex flex-col gap-6 p-8 max-w-lg md:max-w-5xl md:p-8 py-8 md:-mx-20">
                <DateRangePicker
                  start={state.startDate}
                  end={state.endDate}
                  onChange={(val) => {
                    if (state.isEditingStart) {
                      setState((s) => ({
                        ...s,
                        startDate: val,
                        endDate: dayjs(s.endDate).isBefore(val) ? val : s.endDate,
                        isEditingStart: false,
                      }));
                    } else {
                      setState((s) => ({
                        ...s,
                        endDate: val,
                        startDate: dayjs(s.startDate).isAfter(val) ? val : s.startDate,
                      }));
                    }
                  }}
                />
              </div>
            </section>
            <footer class="flex flex-col gap-4">
              <div>
                <label class="inline-flex items-center gap-3 cursor-pointer select-none">
                  <Toggle
                    checked={state.allDay}
                    onClick={() => setState((s) => ({ ...s, allDay: !s.allDay }))}
                  />
                  All day
                </label>
              </div>

              {!state.allDay && (
                <div class="flex items-center gap-2">
                  <span class="flex">
                    <span class="border border-r-0 border-gray-300 rounded-l inline-flex items-center justify-center px-4 bg-gray-50 text-gray-600">
                      {dayjs(state.startDate).format('MMM DD')}
                    </span>
                    <TimeCombobox
                      hour12
                      class="rounded-l-none text-sm"
                      value={state.startTime}
                      onChange={(startTime) => setState((s) => ({ ...s, startTime }))}
                      placeholder="From time"
                    />
                  </span>
                  {' - '}
                  <span class="flex">
                    <TimeCombobox
                      hour12
                      value={state.endTime}
                      class={`${isSingleDate ? '' : 'rounded-r-none'} text-sm`}
                      onChange={(endTime) => setState((s) => ({ ...s, endTime }))}
                      placeholder="To time"
                    />
                    {!isSingleDate && (
                      <span class="border border-l-0 border-gray-300 rounded-r inline-flex items-center justify-center px-4 bg-gray-50 text-gray-600">
                        {dayjs(state.endDate).format('MMM DD')}
                      </span>
                    )}
                  </span>
                </div>
              )}
            </footer>

            {(state.startDate || state.endDate) && (
              <section>
                Remove{' '}
                {state.startDate && (
                  <span class="font-semibold">{dayjs(state.startDate).format('MMM DD, YYYY')}</span>
                )}
                {!state.allDay && <span class="font-semibold ml-1">{state.startTime}</span>}
                {state.endDate && !isSingleDate && (
                  <>
                    {' - '}
                    {state.endDate && (
                      <span class="font-semibold">
                        {dayjs(state.endDate).format('MMM DD, YYYY')}
                      </span>
                    )}
                    {!state.allDay && <span class="font-semibold ml-1">{state.endTime}</span>}
                  </>
                )}
                {' from your availability.'}
              </section>
            )}
            <footer class="flex gap-4">
              <BtnPrimary class="px-4 rounded">Save unavailability</BtnPrimary>
              <Button
                href="/ruzcal-mgmt/availability"
                class="text-inherit inline-flex items-center justify-center rounded-md hover:bg-gray-100 px-4 transition-all"
              >
                Cancel
              </Button>
            </footer>
          </AsyncForm>
        </PageSection>
      </PageContent>
    </RuzcalMgmtPage>
  );
}

router.add({
  url: 'ruzcal-mgmt/overrides/:id',
  load,
  authLevel: 'superadmin',
  render: Page,
});
